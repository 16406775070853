<template>
  <div class="grid justify-center">
    <button
      type="button"
      class="grid justify-center mx-auto w-1/2 border-2 border-gray-400 border-dashed rounded-lg p-6 text-center"
    >
      <span
        class="flex justify-self-center mt-2 mb-5 block text-sm font-medium text-gray-500"
      >
        <div class="self-center">Choose the type of Condition</div>
      </span>
      <div class="flex w-full px-48">
        <button
          class="border-2 border-dashed rounded border-gray-400 p-2 mx-10"
        >
          <FilterIcon class="mx-auto h-12 w-12 text-gray-500"></FilterIcon>
          <div class="text-gray-500">Gateway</div>
        </button>
        <button
          class="border-2 border-dashed rounded border-gray-400 p-2 mx-10"
        >
          <FilterIcon class="mx-auto h-12 w-12 text-gray-500"></FilterIcon>
          <div class="text-gray-500">Device</div>
        </button>
        <button
          class="border-2 border-dashed rounded border-gray-400 p-2 mx-10"
        >
          <FilterIcon class="mx-auto h-12 w-12 text-gray-500"></FilterIcon>
          <div class="text-gray-500">Duration</div>
        </button>
      </div>
    </button>
    <DotsVerticalIcon class="mx-auto h-4 w-4 text-gray-400"></DotsVerticalIcon>
    <PlusCircleIcon
      class="mx-auto h-8 w-8 text-gray-300 hover:text-gray-400"
    ></PlusCircleIcon>
    <DotsVerticalIcon class="mx-auto h-4 w-4 text-gray-400"></DotsVerticalIcon>
  </div>
</template>

<script>
import {
  FilterIcon,
  DotsVerticalIcon,
  PlusCircleIcon,
} from '@heroicons/vue/outline';
export default {
  props: {},
  components: {
    FilterIcon,
    DotsVerticalIcon,
    PlusCircleIcon,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
