<template>
  <div class="justify-center w-full">
    <span class="grid w-full">
      <button
        type="button"
        class="grid justify-items-stretch w-full border-2 border-gray-400 border-dashed rounded-lg pl-2 pr-2 pb-6 bg-white"
        v-bind:class="{
          'bg-indigo-50': is_edit_mode,
        }"
      >
        <div class="justify-self-end">
          <XIcon
            class="h-6 w-6 text-gray-300 hover:text-gray-500 m-3"
            v-on:click="deleteCondition()"
          ></XIcon>
        </div>
        <div class="grid md:flex">
          <div class="grid">
            <div>
              <WifiIcon class="flex mx-auto h-12 w-12 text-gray-500"></WifiIcon>
            </div>
            <div class="text-gray-500 text-sm font-medium">Gateway</div>
          </div>

          <div
            class="grid sm:flex mx-auto text-sm font-sm pl-5 font-mono my-auto"
          >
            <div class="mx-3 my-5 my-auto">if</div>

            <!-- ##### Start of attribute section ##### -->
            <div v-if="is_edit_mode" class="my-auto">
              <select
                v-model="data_attribute"
                v-on:change="driveDropdownTypes"
                class="my-auto rounded-full bg-indigo-200 text-indigo-600 text-sm border-0 focus:outline-none focus:ring-0 py-1 text-center px-5"
              >
                <option
                  v-for="attributeName in attributeNames"
                  :key="attributeName"
                  :value="attributeName"
                >
                  {{ attributeName }}
                </option>
              </select>
            </div>
            <div
              v-else
              class="my-auto rounded-full bg-indigo-200 text-indigo-600 text-sm border-0 focus:outline-none focus:ring-0 py-1 text-center px-5"
            >
              {{ attribute }}
            </div>

            <!-- ##### End of attribute section ##### -->
            <!-- ##### Start of operand section ##### -->

            <div v-if="is_edit_mode && is_number_attribute" class="my-auto">
              <select
                v-model="data_operand"
                class="my-5 mx-10 rounded-full bg-gray-200 text-gray-600 text-sm border-0 focus:outline-none focus:ring-0 py-1 text-center"
              >
                <option>=</option>
                <option>&lt;</option>
                <option>&gt;</option>
              </select>
            </div>

            <div
              v-else
              class="my-5 px-5 mx-10 rounded-full bg-gray-200 text-gray-600 text-sm border-0 focus:outline-none focus:ring-0 py-1 text-center"
            >
              {{ data_operand }}
            </div>

            <!-- ##### End of operand section ##### -->

            <!-- ##### Start of value section ##### -->

            <div v-if="is_edit_mode && is_number_attribute" class="my-auto">
              <input
                v-model="data_value"
                class="my-auto px-5 rounded-full bg-green-200 text-green-600 text-sm border-0 focus:outline-none focus:ring-0 py-1 text-center"
                autocomplete="off"
              />
            </div>
            <div
              v-else-if="is_edit_mode && is_core_gateway_id_attribute"
              class="my-auto"
            >
              <select
                v-model="data_value"
                class="my-auto px-5 rounded-full bg-green-200 text-green-600 text-sm border-0 focus:outline-none focus:ring-0 py-1 text-center"
              >
                <option
                  v-for="gateway in gateways"
                  :key="gateway.core_gateway_id"
                  :value="gateway.core_gateway_id"
                >
                  {{ gateway.core_gateway_id }}
                </option>
              </select>
            </div>
            <div
              v-else-if="is_edit_mode && is_gateway_eui_attribute"
              class="my-auto"
            >
              <select
                v-model="data_value"
                class="my-auto px-5 rounded-full bg-green-200 text-green-600 text-sm border-0 focus:outline-none focus:ring-0 py-1 text-center"
              >
                <option
                  v-for="gateway in gateways"
                  :key="gateway.gateway_eui"
                  :value="gateway.gateway_eui"
                >
                  {{ gateway.gateway_eui }}
                </option>
              </select>
            </div>
            <div
              v-else
              class="my-auto px-5 rounded-full bg-green-200 text-green-600 text-sm border-0 focus:outline-none focus:ring-0 py-1 text-center"
            >
              {{ value }}
            </div>
          </div>

          <!-- ##### End of value section ##### -->

          <div class="p-2 w-24 mx-auto">
            <div grid v-if="is_edit_mode">
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 mb-3 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="submit"
              >
                Submit
              </button>
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="cancel"
              >
                Cancel
              </button>
            </div>
            <div v-else>
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 mb-3 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="changeToEditMode"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </button>
    </span>
    <DotsVerticalIcon class="mx-auto h-4 w-4 text-gray-400"></DotsVerticalIcon>
  </div>
</template>

<script>
import axios from 'axios';

import {
  FilterIcon,
  DotsVerticalIcon,
  PlusCircleIcon,
  WifiIcon,
  XIcon,
} from '@heroicons/vue/outline';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';

export default {
  props: {
    attribute: { required: true, type: String },
    operand: { required: true, type: String },
    value: { required: true, type: String },
    condition_id: { required: false, type: String },
  },
  components: {
    CheckIcon,
    SelectorIcon,
    WifiIcon,
    XIcon,
    DotsVerticalIcon,
    PlusCircleIcon,
    FilterIcon,
  },
  setup() {
    return {};
  },

  data() {
    return {
      is_edit_mode: false,
      is_number_attribute: false,
      is_product_name_attribute: false,
      is_core_gateway_id_attribute: false,
      is_gateway_eui_attribute: false,
      data_value: '',
      data_operand: '=',
      data_attribute: '',
      gateways: [],
      attributeNames: ['core_gateway_id', 'gateway_eui', 'last_seen'],
    };
  },

  methods: {
    submit() {
      console.log('submit called');
      this.is_edit_mode = false;
      let condition = {
        gateway: {
          attribute: this.data_attribute,
          operand: this.data_operand,
          value: this.data_value,
          condition_id: this.condition_id,
        },
      };
      this.$emit('gateway_condition_updated', condition);
    },
    cancel() {
      this.is_edit_mode = false;
    },

    deleteCondition() {
      this.$emit('gateway_condition_deleted', this.condition_id);
      console.log('deleting ID= ' + this.condition_id);
    },

    driveDropdownTypes() {
      console.log('Yahoo!');
      console.log(this.data_attribute);

      this.is_number_attribute = false;
      this.is_core_gateway_id_attribute = false;
      this.is_gateway_eui_attribute = false;

      if (this.data_attribute == 'core_gateway_id') {
        console.log('this.data_attribute: ' + this.data_attribute);
        this.is_core_gateway_id_attribute = true;
        this.data_value = this.gateways[0].core_gateway_id;
        this.data_operand = '=';
      } else if (this.data_attribute == 'gateway_eui') {
        this.is_gateway_eui_attribute = true;
        this.data_value = this.gateways[0].gateway_eui;
        this.data_operand = '=';
      } else if (this.data_attribute == 'last_seen') {
        console.log('number attribute selected');
        this.is_number_attribute = true;
        this.data_value = '';
      }
    },
    changeToEditMode() {
      console.log('changeToEditMode called');
      this.is_edit_mode = true;
      this.data_attribute = this.attribute;
      console.log(
        'In Edit Mode - changing this.data_attribute: ' + this.data_attribute
      );
      this.data_operand = this.operand;
      this.data_value = this.value;
      console.log(
        'In Edit Mode - changing this.data_value: ' + this.data_value
      );

      this.driveDropdownTypes();
    },
  },
  created() {
    const currentAccountSession = this.$store.state.account.name;
    const currentProjectSession = this.$store.state.project.name;
    let url =
      '/gateways?core_account_name=' +
      currentAccountSession +
      '&core_project_name=' +
      currentProjectSession;
    axios
      .get(url)
      .then((res) => {
        this.gateways = res.data.data;
        console.log('ConditionGateway created() called');
        console.log(this.gateways);
      })
      .catch();

    if (this.attribute == '' && this.value == '') {
      this.is_edit_mode = true;

      this.data_attribute = 'last_seen';
      this.data_operand = '>';
      this.data_value = 60;
      this.is_number_attribute = true;
      console.log('assigning this.data_attribute: ' + this.data_attribute);
      console.log('assigning this.data_value: ' + this.data_value);
    } else {
      this.data_attribute = this.attribute;
      this.data_operand = this.operand;
      this.data_value = this.value;
    }
  },
};
</script>
