<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="open = false"
    >
      <div
        class="flex items-end justify-center pt-4 px-4 pb-20 text-center block lg:block sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <ViewGridAddIcon
                  class="h-6 w-6 text-gray-600"
                  aria-hidden="true"
                />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-3/4">
                <DialogTitle
                  as="h3"
                  class="text-md leading-6 font-medium text-gray-900 mb-5"
                >
                  {{ title_text }}
                </DialogTitle>

                <Combobox as="div" v-model="selectedDevice" class=" w-full">
                  <ComboboxLabel
                    class="block text-sm font-medium text-gray-700 mb-2"
                    >Choose a device</ComboboxLabel
                  >
                  <div class="relative mt-1">
                    <ComboboxInput
                      class="w-full rounded-md   bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                      @change="query = $event.target.value"
                      :display-value="(device) => device.core_device_id"
                    />
                    <ComboboxButton
                      class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                    >
                      <SelectorIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </ComboboxButton>

                    <ComboboxOptions
                      v-if="filteredDevices.length > 0"
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ComboboxOption
                        v-for="device in filteredDevices"
                        :key="device.core_device_id"
                        :value="device"
                        as="template"
                        v-slot="{ active, selected }"
                      >
                        <li
                          :class="[
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                          ]"
                        >
                          <div class="flex">
                            <span
                              :class="['truncate', selected && 'font-semibold']"
                            >
                              {{ device.core_device_id }}
                            </span>
                          </div>

                          <span
                            v-if="selected"
                            :class="[
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-gray-600',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ComboboxOption>
                    </ComboboxOptions>
                  </div>
                </Combobox>

                <Combobox
                  as="div"
                  v-model="selectedCapability"
                  class="w-full mb-2 mt-5"
                  v-if="capabilities.length > 0"
                >
                  <ComboboxLabel
                    class="block text-sm font-medium text-gray-700 mb-2"
                    >Choose a capability</ComboboxLabel
                  >
                  <div class="relative mt-1">
                    <ComboboxInput
                      class="w-full rounded-md bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                      :display-value="(capability) => capability"
                    />
                    <ComboboxButton
                      class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                    >
                      <SelectorIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </ComboboxButton>

                    <ComboboxOptions
                      v-if="capabilities.length > 0"
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ComboboxOption
                        v-for="item in capabilities"
                        :key="item"
                        :value="item"
                        as="template"
                        v-slot="{ active, selected }"
                      >
                        <li
                          :class="[
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                          ]"
                        >
                          <div class="flex">
                            <span
                              :class="['truncate', selected && 'font-semibold']"
                            >
                              {{ item }}
                            </span>
                          </div>
                        </li>
                      </ComboboxOption>
                    </ComboboxOptions>
                  </div>
                </Combobox>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-0   focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                @click="
                  (this.result.is_confirming = true),
                    (this.result.new_panel = {
                      core_device_id: selectedDevice.core_device_id,
                      capability: selectedCapability,
                    }),
                    userAction(this.result),
                    (open = false)
                "
              >
                {{ confirm_button_text }}
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                @click="
                  (this.result.is_confirming = false),
                    userAction(this.result),
                    (open = false)
                "
                ref="cancelButtonRef"
              >
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { computed, ref } from 'vue';
import axios from 'axios';

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';
import {
  ExclamationIcon,
  CogIcon,
  SwitchHorizontalIcon,
  BriefcaseIcon,
  ChevronDownIcon,
  ViewGridAddIcon,
} from '@heroicons/vue/outline';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';

export default {
  props: {
    title_text: { required: true, type: String },
    message_text: { required: true, type: String },
    core_account_name: { required: true, type: String },
    core_project_name: { required: true, type: String },
    confirm_button_text: { required: false, type: String },
    action_url: { required: false, type: String },
    devices: { required: true, type: Object, default: () => {} },
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    SwitchHorizontalIcon,
    ViewGridAddIcon,
    CogIcon,
    BriefcaseIcon,
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  data() {
    return {
      capabilities: [],
      result: {
        is_confirming: false,
        new_panel: null,
      },
    };
  },
  methods: {
    userAction(result) {
      console.log('userAction called with: ');
      console.log(result);
      this.$emit('user_action', this.result);
    },
  },
  created() {},

  computed: {
    filteredDevices() {
      if (this.query === '') {
        return this.devices;
      } else {
        const result = this.devices.filter((device) => {
          return device.core_device_id
            .toLowerCase()
            .includes(this.query.toLowerCase());
        });
        return result;
      }
    },
  },
  watch: {
    selectedDevice() {
      console.log(
        'selectedDevice changed: ' + this.selectedDevice.capabilities
      );
      this.selectedCapability = ref('');
      this.capabilities = this.selectedDevice.capabilities;
    },
  },

  setup() {
    const open = ref(true);
    const query = ref('');
    const selectedDevice = ref();
    const selectedCapability = ref();

    return {
      open,
      query,
      selectedDevice,
      selectedCapability,
    };
  },
};
</script>
