<template>
  <!-- <div v-if="core_things_user == 'checking'">
</div> -->

  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        static
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
        :open="sidebarOpen"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <a href="/">
              <div class="flex-shrink-0 flex items-center px-4">
                <img
                  class="h-14 w-auto"
                  src="./assets/corethings_dark_theme_1200_thick_tailwind.png"
                  alt="coreThings"
                /></div
            ></a>
            <!-- <div>Dropdown</div> -->
            <!-- <Menu
              as="div"
              class="z-40 relative inline-block text-left h-16 w-auto bg-gray-800"
            >
              <div>
                <MenuButton
                  class="inline-flex py-4 w-full rounded-md border border-gray-800 shadow-sm px-4 py-2 bg-gray-800 text-medium font-sm text-gray-300 hover:bg-gray-800 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-800 focus:offset-gray-800"
                >
                  <OfficeBuildingIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  <p class="px-4">{{ $store.state.account.name }}</p>
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>

              <transition>
                <MenuItems
                  class="origin-bottom-left relative w-56 -y-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                >
                  <MenuItem
                    v-for="account in accountList"
                    :key="account.name"
                    v-on:click="
                      changeSessionAccount(account);
                      closeSideBar();
                    "
                    v-slot="{ active }"
                  >
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      ]"
                    >
                      <OfficeBuildingIcon
                        class="z-40 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      {{ account.name }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu> -->
            <!-- start of navigation - mobile -->
            <div class="flex-1 flex flex-col overflow-y-auto">
              <nav class="flex-1 px-2 py-0 bg-gray-800 space-y-1">
                <Menu
                  as="div"
                  class="z-40 relative inline-block text-left h-16 w-auto bg-gray-800"
                >
                  <div>
                    <MenuButton
                      class="inline-flex py-4 w-full rounded-md border border-gray-800 shadow-sm px-4 py-2 bg-gray-800 text-medium font-sm text-gray-300 hover:bg-gray-800 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-800 focus:offset-gray-800"
                    >
                      <OfficeBuildingIcon
                        class="-mr-1 ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      <p class="px-4">{{ $store.state.account.name }}</p>
                      <ChevronDownIcon
                        class="-mr-1 ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    </MenuButton>
                  </div>

                  <transition>
                    <MenuItems
                      class="origin-bottom-left relative w-56 -y-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                    >
                      <!-- <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                > -->
                      <MenuItem
                        v-for="account in accountList"
                        :key="account.name"
                        v-on:click="
                          changeSessionAccount(account);
                          closeSideBar();
                        "
                        v-slot="{ active }"
                      >
                        <a
                          href="#"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm',
                          ]"
                        >
                          <OfficeBuildingIcon
                            class="z-40 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          {{ account.name }}
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  v-on:click="
                    makeItemCurrent(item);
                    closeSideBar();
                  "
                  :class="[
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}</router-link
                >

                <a
                  href="https://docs.corethings.io"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    />
                  </svg>
                  <span class="ml-3">Docs</span></a
                >
              </nav>
            </div>
            <div class="bg-gray-700 p-4">
              <div class="mb-10">
                <router-link
                  to="/account-settings"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  v-on:click="closeSideBar()"
                  v-if="is_admin_of_current_account"
                >
                  <cogIcon class="h-6 w-6" />
                  <span class="ml-3">Account Settings</span></router-link
                >
              </div>
              <div class="z-0 ml-3 relative" v-if="$auth.isAuthenticated.value">
                <div class="flex items-center">
                  <div>
                    <img
                      class="inline-block h-10 w-10 rounded-full"
                      :src="$auth.user.value.picture"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-white">
                      {{ $auth.user.value.name }}
                    </p>
                    <router-link v-on:click="logout" :to="logout"
                      ><p
                        class="z-0 text-xs font-medium text-gray-300 group-hover:text-gray-200"
                      >
                        Log
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col h-0 flex-1">
          <!-- <div class="h-14 bg-gray-700"></div> -->
          <div class="flex-shrink-0 flex items-center px-4 bg-gray-700 h-14">
            <img
              class="h-12 w-auto"
              src="./assets/corethings_dark_theme_1200_thick_tailwind.png"
              alt="coreThings"
            />
          </div>

          <!-- start of left nav desktop   -->
          <div
            class="filter drop-shadow-lg flex-1 flex flex-col overflow-y-auto"
          >
            <div class="flex-1 flex flex-col overflow-y-auto">
              <nav class="flex-1 px-2 py-0 bg-white shadow space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  v-on:click="makeItemCurrent(item)"
                  :class="[
                    item.current
                      ? 'bg-gray-600 text-white'
                      : 'text-gray-700 hover:bg-gray-200 hover:text-white',
                    'group flex items-center px-2 py-2 ml-0 mt-1 mb-1 mr-2 text-sm font-sm rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}</router-link
                >
                <a
                  href="https://docs.corethings.io"
                  class="text-gray-500 hover:bg-gray-200 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    />
                  </svg>
                  <span class="ml-3">Docs</span></a
                >
              </nav>
            </div>
            <div class="bg-gray-200 p-4">
              <Menu
                as="div"
                class="relative inline-block text-left h-16 w-auto bg-gray-200"
              >
                <div>
                  <MenuButton
                    class="inline-flex py-4 w-full rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-gray-200 text-medium font-sm text-gray-400 hover:bg-gray-200 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-200 focus:offset-gray-200"
                  >
                    <OfficeBuildingIcon
                      class="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <p class="px-4">{{ $store.state.account.name }}</p>
                    <ChevronDownIcon
                      class="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </MenuButton>
                </div>

                <transition>
                  <MenuItems
                    class="w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  >
                    <MenuItem
                      v-for="account in accountList"
                      :key="account.name"
                      v-on:click="changeSessionAccount(account)"
                      v-slot="{ active }"
                    >
                      <a
                        href="#"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm',
                        ]"
                      >
                        <OfficeBuildingIcon
                          class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        {{ account.name }}
                      </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
              <router-link
                to="/account-settings"
                class="text-gray-700 hover:bg-gray-200 hover:text-white group flex px-4 py-2 text-sm font-sm rounded-md"
                v-on:click="makeItemCurrent()"
                v-if="is_admin_of_current_account"
              >
                <cogIcon class="h-6 w-6" />
                <span class="ml-3">Account Settings</span></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full overflow-x-auto">
      <div class="flex h-14 bg-gray-700">
        <div
          as="button"
          class="flex-none relative h-10 px-4 mt-4 border-gray-200 text-gray-500 focus:outline-none sm:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </div>
        <Menu
          as="div"
          class="z-40 grow justify-self-start relative inline-block text-left h-14 ml-5 w-full bg-gray-700 shadow"
        >
          <div>
            <MenuButton
              class="z-40 inline-flex py-0 mt-2 w-fill rounded-md border border-gray-400 px-4 py-1 bg-gray-700 text-medium font-sm text-gray-300 hover:bg-gray-500 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-700 focus:offset-gray-700"
            >
              <BriefcaseIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              <p class="px-4">{{ $store.state.project.name }}</p>
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="z-40 origin-top-right absolute left-0 mt-2 w-56 rounded-md border border-gray-200 shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            >
              <MenuItem
                v-for="project in projectList"
                :key="project.core_project_name"
                v-on:click="changeSessionProject(project)"
                v-slot="{ active }"
              >
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  ]"
                >
                  <BriefcaseIcon
                    class="z-40 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {{ project.core_project_name }}
                </a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>

        <div
          class="justify-self-end w-0 sm:w-max sm:shrink-0 mt-2 mb-2 mr-2 invisible sm:visible"
          v-if="$auth.isAuthenticated.value"
        >
          <div class="flex items-center">
            <div>
              <img
                class="inline-block h-10 w-10 rounded-full"
                :src="$auth.user.value.picture"
                alt=""
              />
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-gray-200">
                {{ $auth.user.value.name }}
              </p>
              <router-link v-on:click="logout" :to="logout"
                ><p
                  class="text-xs font-medium text-gray-400 group-hover:text-gray-200"
                >
                  Logout
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div v-if="!loading" class="py-0">
          <div
            v-if="$store.state.coreThingsUser.is_user"
            class="max-w-7xl mx-auto px-0 sm:px-0 md:px-0 ml-1 mt-1"
          >
            <!-- Replace with your content -->
            <router-view></router-view>

            <!-- Replace with your content -->
          </div>
          <div v-else class="max-w-7xl mx-auto px-0 sm:px-0 md:px-0">
            <div class="bg-white py-16 sm:py-24">
              <div class="relative sm:py-16">
                <div aria-hidden="true" class="hidden sm:block">
                  <div
                    class="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"
                  />
                  <svg
                    class="absolute top-8 left-1/2 -ml-3"
                    width="404"
                    height="392"
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          class="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="392"
                      fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
                    />
                  </svg>
                </div>
                <div
                  class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8"
                >
                  <div
                    class="relative rounded-2xl px-6 py-10 bg-gray-900 overflow-hidden shadow-xl sm:px-12 sm:py-20"
                  >
                    <div
                      aria-hidden="true"
                      class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
                    >
                      <svg
                        class="absolute inset-0 h-full w-full"
                        preserveAspectRatio="xMidYMid slice"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 1463 360"
                      >
                        <path
                          class="text-gray-500 text-opacity-40"
                          fill="currentColor"
                          d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                        />
                        <path
                          class="text-gray-700 text-opacity-40"
                          fill="currentColor"
                          d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                        />
                      </svg>
                    </div>
                    <div class="relative">
                      <div class="sm:text-center">
                        <h2
                          class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl"
                        >
                          Currently in Closed Beta Stage :
                          {{ $store.state.coreThingsUser.is_user }}
                        </h2>
                        <p class="mt-6 mx-auto max-w-2xl text-lg text-gray-200">
                          Thank you for registering. We are currently at invite
                          only stage and will contact you when we are ready to
                          start expanding.
                        </p>
                      </div>
                      <div class="mt-20 sm:mx-auto sm:max-w-max">
                        <div class="mt-4 sm:mt-0 sm:ml-0">
                          <button
                            type="submit"
                            @click="logout"
                            class="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-500 text-base font-medium text-white shadow hover:bg-gray-00 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                          >
                            Sign Out
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex overflow-hidden bg-gray-100 justify-center">
            <div><loading-widget></loading-widget></div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import InlineSvg from 'vue-inline-svg';
import concentricCircles from '@/assets/concentric_circles.svg';
import LoadingWidget from './components/LoadingWidget.vue';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  HomeIcon,
  MenuAlt2Icon,
  XIcon,
  WifiIcon,
  LightBulbIcon,
  CameraIcon,
  CogIcon,
  BriefcaseIcon,
  OfficeBuildingIcon,
  ChevronDownIcon,
  ChartBarIcon,
  PhoneOutgoingIcon,
  BookOpenIcon,
  AdjustmentsIcon,
  ViewGridIcon,
  VariableIcon,
} from '@heroicons/vue/outline';
import { SearchIcon } from '@heroicons/vue/solid';
import AlertBanner from './components/AlertBanner';

const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    CogIcon,
    AlertBanner,
    BriefcaseIcon,
    OfficeBuildingIcon,
    ChevronDownIcon,
    InlineSvg,
    concentricCircles,
    ChartBarIcon,
    CameraIcon,
    LoadingWidget,
    PhoneOutgoingIcon,
    BookOpenIcon,
    AdjustmentsIcon,
    ViewGridIcon,
    VariableIcon,
  },
  setup() {
    const sidebarOpen = ref(false);

    return {
      userNavigation,
      sidebarOpen,
    };
  },
  data() {
    return {
      alert: false,
      accountList: [],
      projectList: [],
      navigation: [],
      user: null,
      loading: false,
      home_report: '/reports/mapReport',
      current_navigation: '',
      is_admin_of_current_account: true,
    };
  },
  methods: {
    makeItemCurrent(item) {
      for (let i = 0; i < this.navigation.length; i++) {
        if (this.navigation[i] == item) {
          this.navigation[i].current = true;
        } else this.navigation[i].current = false;
      }
      this.current_navigation = item.name;
    },
    closeSideBar() {
      this.sidebarOpen = false;
    },
    login() {
      console.log('Forcing Login Redirect flow!');
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$store.commit({
        type: 'updateUserAccount',
        account_name: null,
        account_role: null,
      });
      this.$store.commit({
        type: 'updateUserProject',
        project_name: null,
        project_role: null,
      });
      //this.$store.state.core_things_user = false;
      this.$store.commit({
        type: 'updateCoreThingsUser',
        is_user: false,
      });
      this.$store.commit({
        type: 'updateUserAlert',
        visible: false,
        alert_type: 'error',
        message: null,
      });

      console.log('this.$store.state.userAlert : ');
      console.log(this.$store.state.userAlert);

      this.$auth.logout({
        // returnTo: window.location.origin,
        returnTo: process.env.VUE_APP_AUTH0_LOGOUT,
      });
    },
    changeSessionProject(project) {
      let project_name = null;
      let project_role = null;
      if (project != null) {
        project_name = project.core_project_name;
        project_role = project.core_project_role;
      }

      this.$store.commit({
        type: 'updateUserProject',
        project_name: project_name,
        project_role: project_role,
      });

      console.log('Updated Project Session: ' + this.$store.state.project.name);
      //Let's update the menu accordingly
      this.setMenuForRole(this.$store.state.project.role);
    },

    changeSessionAccount(account) {
      this.$store.commit({
        type: 'updateUserAccount',
        account_name: account.name,
        account_role: account.role,
      });

      console.log('Updated Account Session: ' + this.$store.state.account.name);
      console.log('Setting Menu for account.name: ' + account.name);
      console.log('Setting Menu for account.role: ' + account.role);

      for (let i = 0; i < this.user.core_accounts.length; i++) {
        //Update the project list for this new account selsected
        if (
          this.user.core_accounts[i].account_name ==
          this.$store.state.account.name
        ) {
          if (this.user.core_accounts[i].core_projects) {
            this.projectList = this.user.core_accounts[i].core_projects;
            console.log('setting projectList: ');
            console.log(this.projectList);
            this.changeSessionProject(this.projectList[0]);
          } else {
            this.changeSessionProject(null);
          }
        }
      }
    },
    setProjectListForAccount(core_account_name) {
      for (let i = 0; i < this.user.core_accounts.length; i++) {
        //Update the project list for this new account selected
        if (this.user.core_accounts[i].account_name == core_account_name) {
          this.projectList = this.user.core_accounts[i].core_projects;
          console.log('updating projectList: ');
          console.log(this.projectList);
        }

        //Let's set the project session at this point - to the first project in the list
      }
    },
    setMenuForRole(role) {
      //First, let's make sure that the "Account Settings" is showing if the Account role is admin
      if (
        this.$store.state.account.role != null &&
        this.$store.state.account.role == 'admin'
      ) {
        this.is_admin_of_current_account = true;
      } else {
        this.is_admin_of_current_account = false;
      }

      //If the role is null, then we display everything. The user doesn't have any projects & roles yet set up
      if (role == 'project_user' || role == null) {
        this.navigation = [
          {
            name: 'Dashboard',
            href: '/dashboard',
            icon: ViewGridIcon,
            current: true,
          },
          { name: 'Scan', href: '/scan', icon: CameraIcon, current: false },
          {
            name: 'Devices',
            href: '/devices',
            icon: LightBulbIcon,
            current: false,
          },
          {
            name: 'Gateways',
            href: '/gateways',
            icon: WifiIcon,
            current: false,
          },
          // {
          //   name: 'Incidents',
          //   href: '/alerts',
          //   icon: PhoneOutgoingIcon,
          //   current: false,
          // },
          {
            name: 'Control Center',
            href: '/control-center',
            icon: VariableIcon,
            current: false,
          },

          {
            name: 'Reports',
            href: '/reports',
            icon: ChartBarIcon,
            current: false,
          },
        ];
      } else if (role == 'project_installer') {
        this.navigation = [
          { name: 'Scan', href: '/scan', icon: CameraIcon, current: true },
        ];
      }
      //Let's remove the alerts section until we are ready to launch that section
      else if (role == 'project_monitor') {
        this.navigation = [
          {
            name: 'Dashboard',
            href: '/dashboard',
            icon: ViewGridIcon,
            current: true,
          },
          { name: 'Scan', href: '/scan', icon: CameraIcon, current: false },
          { name: 'Alerts', href: '/alerts', icon: BellIcon, current: false },
          {
            name: 'Reports',
            href: '/reports',
            icon: ChartBarIcon,
            current: false,
          },
        ];
      }
      // else if (role == ('admin' || 'core_admin')) {
      //   this.navigation = [
      //     {
      //       name: 'Dashboard',
      //       href: '/dashboard',
      //       icon: ViewGridIcon,
      //       current: true,
      //     },
      //     { name: 'Scan', href: '/scan', icon: CameraIcon, current: false },
      //     {
      //       name: 'Devices',
      //       href: '/devices',
      //       icon: LightBulbIcon,
      //       current: false,
      //     },
      //     {
      //       name: 'Gateways',
      //       href: '/gateways',
      //       icon: WifiIcon,
      //       current: false,
      //     },
      //     {
      //       name: 'Incidents',
      //       href: '/alerts',
      //       icon: PhoneOutgoingIcon,
      //       current: false,
      //     },
      //     {
      //       name: 'Reports',
      //       href: '/reports',
      //       icon: ChartBarIcon,
      //       current: false,
      //     },
      //     {
      //       name: 'Project Settings',
      //       href: '/project-settings',
      //       icon: AdjustmentsIcon,
      //       current: false,
      //     },
      //   ];
      // }

      for (let i = 0; i < this.navigation.length; i++) {
        if (this.navigation[i].name == this.current_navigation) {
          this.navigation[i].current = true;
        } else {
          this.navigation[i].current = false;
        }
      }
    },
  },
  created() {
    this.loading = true;
    console.log('App.vue created() called');
    console.log(
      'this.$auth.isAuthenticated.value: ' + this.$auth.isAuthenticated.value
    );

    if (this.$auth.isAuthenticated.value) {
      //User has successfully authenticated with Auth0. We know
      //this user is who they say they are. However, they still
      //may have no authorizations in Corethings. Let's check
      //to see if they are a User within the CoreThings network.

      //If so, let's display the accounts with relevant account_role
      //privileges.

      //If not, then let's display a holding page for the user explaining
      //CoreThings is still in private beta and we'll be in touch

      //Let's escape the "+" in the url to allow testing of specific users
      // don+frespet+install@corethings.io for example

      //Do not make an API call if we already know the User is a core_things_user
      //and this info is stored in the $store.

      //Changing my mind on this one. We should check any time the app itself is reloaded
      //Otherwise, the user maybe added to new accounts and it will not reload this information
      // if (!this.$store.state.coreThingsUser.is_user || this.user == null) {
      axios
        .get(
          '/users?email=' + this.$auth.user.value.email.replaceAll('+', '%2b')
        )
        .then((res) => {
          console.log('res.data.count: ' + res.data.count);

          //If the user is already in CoreThings, then let's display their accounts
          //with associated privileges
          if (res.data.count > 0) {
            //this.$store.state.core_things_user = true;
            this.$store.commit({
              type: 'updateCoreThingsUser',
              is_user: true,
            });

            console.log(
              'this.$store.state.coreThingsUser.is_user: ' +
                this.$store.state.coreThingsUser.is_user
            );
            this.user = res.data.data[0];
            console.log(
              'this.user.core_accounts.length: ' +
                this.user.core_accounts.length
            );

            //Let's update the session with the user email
            this.$store.commit({
              type: 'updateUser',
              email: this.user.email,
            });

            //Let's set the first account in the list as the session account
            //But only if it is not already set
            //This prevents us from losing the current session account if the
            //user refreshes the page
            console.log(
              'this.$store.state.account.name: ' +
                this.$store.state.account.name
            );
            console.log(
              'this.$store.state.project.name: ' +
                this.$store.state.project.name
            );
            if (this.$store.state.account.name == null) {
              if (this.user.core_accounts.length > 0) {
                this.$store.commit({
                  type: 'updateUserAccount',
                  account_name: this.user.core_accounts[0].account_name,
                  account_role: this.user.core_accounts[0].account_role,
                });
                console.log(
                  'setting global account: ' + this.$store.state.account.name
                );
              }

              console.log('About to set global project states');
              console.log(this.user.core_accounts);
              if (this.user.core_accounts[0].core_projects.length > 0) {
                console.log(
                  'core_project_name: ' +
                    this.user.core_accounts[0].core_projects[0]
                      .core_project_name
                );
                console.log(
                  'core_project_role: ' +
                    this.user.core_accounts[0].core_projects[0]
                      .core_project_role
                );

                //Let's set the project to be the first one in the list
                this.$store.commit({
                  type: 'updateUserProject',
                  project_name:
                    this.user.core_accounts[0].core_projects[0]
                      .core_project_name,
                  project_role:
                    this.user.core_accounts[0].core_projects[0]
                      .core_project_role,
                });

                console.log(
                  'setting global project name/role: ' +
                    this.$store.state.project.name +
                    '/' +
                    this.$store.state.project.role
                );
              }
            } else {
              console.log('Not recommitting store - already set for account');
            }

            for (let i = 0; i < this.user.core_accounts.length; i++) {
              this.accountList.push({
                name: this.user.core_accounts[i].account_name,
                role: this.user.core_accounts[i].account_role,
              });

              //account_homepage is now set at the account level, not the user level
              // if (
              //   this.user.core_accounts[i].account_name ==
              //   this.$store.state.account.name
              // ) {
              //   if (this.user.core_accounts[i].account_homepage != null) {
              //     this.home_report =
              //       '/reports/' + this.user.core_accounts[i].account_homepage;

              //     console.log('setting home_report to ' + this.home_report);
              //   }
              // }
            }

            console.log(
              'setting MenuForRole: ' + this.$store.state.project.role
            );
            this.setMenuForRole(this.$store.state.project.role);
            this.setProjectListForAccount(this.$store.state.account.name);
          }
          //User is not yet part of the CoreThings network. Let's display a holding page
          //for them explaining we are still in Private Beta
          else {
            //this.$store.state.core_things_user = false;
            this.$store.commit({
              type: 'updateCoreThingsUser',
              is_user: false,
            });
          }

          this.loading = false;
        })
        .catch((err) => {});
      // }

      //User is not authenticated - let's push them back through the Login/SignUp flow
    } else {
      this.$store.commit({
        type: 'updateUserAccount',
        account_name: null,
        account_role: null,
      });
      //this.$store.state.core_things_user = false;
      this.$store.commit({
        type: 'updateCoreThingsUser',
        is_user: false,
      });
      this.login();
    }
  },
  // provide() {
  //   return {};
  // },
};
</script>
