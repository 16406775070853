<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="py-6 px-0">
    <div>
      <nav class="sm:hidden" aria-label="Back">
        <a
          href="#"
          class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon
            class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Back
        </a>
      </nav>
      <nav class="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex">
              <router-link
                :to="'/control-center'"
                class="text-sm font-medium text-gray-500 hover:text-gray-700"
                >Control Center</router-link
              >
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                href="#"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >Events</a
              >
            </div>
          </li>
        </ol>
      </nav>
    </div>

    <!-- Start  -->
    <div class="px-3">
      <div class="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <div class="flex pr-3 text-lg leading-6 font-medium text-gray-900">
            <BellIcon class="h-5 w-5 text-red-300" />
            <div class="px-5 my-auto">Open Events</div>
          </div>
          <p class="mt-1 mb-5 max-w-2xl text-sm text-gray-500">
            Events that are either in a triggered, acknowledged or normal state.
            Events that match these conditions will not trigger again until
            these are closed.
          </p>

          <!-- Start table -->
          <div
            class="min-w-full flex justify-end border border-transparent py-2"
          >
            <button
              type="button"
              class="inline-flex items-center px-3 py-2 border border-gray text-xs leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
              v-on:click="closeAllNormalEvents"
            >
              Bulk Close Normal Status Events
            </button>
          </div>

          <div class="my-0 overflow-x-auto sm:-mx-0 lg:-mx-0">
            <div
              class="py-0 align-middle inline-block min-w-full sm:px-2 px-0 lg:px-0"
            >
              <table class="w-full">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Event
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Rule Name
                    </th>

                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Assigned
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Notes
                    </th>

                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr
                    v-for="event in open_events"
                    :key="event.description"
                    class="align-top"
                  >
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                    >
                      <div class="grid" v-if="event.dev_id">
                        <router-link :to="'/devices/' + event.dev_id">
                          <div>{{ event.core_device_id }}</div>
                          <div>{{ event.device_description }}</div>
                        </router-link>
                      </div>
                      <div class="grid" v-else-if="event.gateway_id">
                        <router-link :to="'/gateways/' + event.gateway_id">
                          <div>{{ event.core_gateway_id }}</div>
                          <div>{{ event.gateway_description }}</div>
                        </router-link>
                      </div>
                      <div class="grid" v-else>
                        <div>{{ event.rule_id }}</div>
                        <div>{{ event.description }}</div>
                      </div>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                    >
                      {{ event.rule_name }}
                    </td>

                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-sm text-gray-600"
                    >
                      {{
                        getTimeDiff(
                          dateMath.diff(
                            new Date(event.created_at),
                            new Date(),
                            'minutes',
                            false
                          )
                        )
                      }}
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                    >
                      {{ event.assigned_user }}
                    </td>

                    <td class="px-2 py-4 text-xs font-sm text-grey-500">
                      <div v-if="add_note_to_event_id == event._id">
                        <input
                          type="text"
                          v-model="event.notes"
                          v-on:keyup.enter="saveNote(event)"
                          class="whitespace-normal font-mono text-xs font-sm text-grey-300"
                        />
                      </div>
                      <div
                        v-else
                        class="font-mono text-xs font-sm text-grey-300"
                      >
                        {{ event.notes }}
                      </div>
                    </td>
                    <td
                      class="flex px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div
                        class="mt-1 mr-1 px-3 py-0 max-w-min border border-transparent text-xs leading-4 rounded-md"
                        v-bind:class="{
                          'text-red-600 bg-red-200': event.status == 'alerting',
                          'text-amber-600 bg-amber-100 ':
                            event.status == 'pending',
                          'text-green-600 bg-green-200': event.status == 'ack',
                          'text-indigo-600 bg-indigo-200':
                            event.status == 'normal',
                        }"
                      >
                        {{ event.status }}
                      </div>
                    </td>
                    <td>
                      <Menu as="div" class="">
                        <div>
                          <MenuButton class="pt-4 pl-4">
                            <DotsHorizontalIcon
                              class="h-5 w-5 text-gray-500 hover:text-gray-200"
                              aria-hidden="true"
                            />
                          </MenuButton>
                        </div>

                        <transition
                          enter-active-class="transition duration-100 ease-out"
                          enter-from-class="transform scale-95 opacity-0"
                          enter-to-class="transform scale-100 opacity-100"
                          leave-active-class="transition duration-75 ease-in"
                          leave-from-class="transform scale-100 opacity-100"
                          leave-to-class="transform scale-95 opacity-0"
                        >
                          <MenuItems
                            class="absolute z-40 w-32 right-20 md:right-40 mt-0 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div class="px-1 py-1">
                              <MenuItem
                                v-slot="{ active }"
                                v-if="event.status == 'alerting'"
                              >
                                <button
                                  v-on:click="changeStatus(event._id, 'ack')"
                                  :class="[
                                    active
                                      ? 'bg-gray-200 text-white'
                                      : 'text-gray-500',
                                    'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                  ]"
                                >
                                  Acknowledge
                                </button>
                              </MenuItem>
                              <MenuItem
                                v-slot="{ active }"
                                v-if="
                                  event.status == 'ack' ||
                                  event.status == 'normal'
                                "
                              >
                                <button
                                  v-on:click="
                                    (assign_event_modal = true),
                                      (selected_event_id = event._id),
                                      (selected_user = event.assigned_user)
                                  "
                                  :class="[
                                    active
                                      ? 'bg-gray-200 text-white'
                                      : 'text-gray-500',
                                    'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                  ]"
                                >
                                  Assign
                                </button>
                              </MenuItem>
                              <MenuItem v-slot="{ active }">
                                <button
                                  v-on:click="addNote(event._id)"
                                  :class="[
                                    active
                                      ? 'bg-gray-200 text-white'
                                      : 'text-gray-500',
                                    'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                  ]"
                                >
                                  Add Note
                                </button>
                              </MenuItem>
                              <MenuItem v-slot="{ active }">
                                <button
                                  v-on:click="
                                    changeStatus(event._id, 'whitelisted')
                                  "
                                  :class="[
                                    active
                                      ? 'bg-gray-200 text-white'
                                      : 'text-gray-500',
                                    'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                  ]"
                                >
                                  Whitelist
                                </button>
                              </MenuItem>

                              <MenuItem v-slot="{ active }">
                                <button
                                  v-on:click="changeStatus(event._id, 'closed')"
                                  :class="[
                                    active
                                      ? 'bg-gray-200 text-white'
                                      : 'text-gray-500',
                                    'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                  ]"
                                >
                                  Close
                                </button>
                              </MenuItem>
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- end table --->
        </div>
      </div>
    </div>
    <!-- Finish section --->

    <assign-event
      v-if="assign_event_modal"
      :core_account_name="$store.state.account.name"
      :already_selected_user="selected_user"
      v-on:user_action="assignUser($event)"
    ></assign-event>

    <!-- Start  -->
    <div class="px-3">
      <div class="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <div class="flex pr-3 text-lg leading-6 font-medium text-gray-900">
            <ClockIcon class="h-5 w-5 text-yellow-300" />
            <div class="px-5 my-auto">Pending Events</div>
          </div>
          <p class="mt-1 mb-5 max-w-2xl text-sm text-gray-500">
            Events that have matched a rule but are still within the trigger
            duration. The control center is essentially "keeping an eye" on
            these events and alert if they go beyond their trigger duration
          </p>

          <!-- Start table -->
          <div class="min-w-full h-64 overflow-scroll">
            <div class="my-0 overflow-x-auto sm:-mx-0 lg:-mx-0">
              <div
                class="py-0 align-middle inline-block min-w-full sm:px-2 px-0 lg:px-0"
              >
                <table class="w-full">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Event
                      </th>

                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Created
                      </th>
                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Assigned
                      </th>
                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Notes
                      </th>

                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr
                      v-for="event in pending_events"
                      :key="event.description"
                      class="align-top"
                    >
                      <td
                        class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                      >
                        {{ event.description }}
                      </td>

                      <td
                        class="px-2 py-4 whitespace-nowrap text-xs font-sm text-gray-600"
                      >
                        {{
                          getTimeDiff(
                            dateMath.diff(
                              new Date(event.created_at),
                              new Date(),
                              'minutes',
                              false
                            )
                          )
                        }}
                      </td>
                      <td
                        class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                      >
                        {{ event.assigned_user }}
                      </td>

                      <td
                        class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                      >
                        <div v-if="add_note_to_event_id == event._id">
                          <input
                            type="text"
                            v-model="event.notes"
                            v-on:keyup.enter="saveNote(event)"
                            class="font-mono text-xs font-sm text-grey-300"
                          />
                        </div>
                        <div
                          v-else
                          class="font-mono text-xs font-sm text-grey-300"
                        >
                          {{ event.notes }}
                        </div>
                      </td>
                      <td
                        class="flex px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                      >
                        <div
                          class="mt-1 mr-1 px-3 py-0 max-w-min border border-transparent text-xs leading-4 rounded-md"
                          v-bind:class="{
                            'text-red-600 bg-red-200':
                              event.status == 'alerting',
                            'text-amber-600 bg-amber-100 ':
                              event.status == 'pending',
                            'text-green-600 bg-green-200':
                              event.status == 'ack',
                            'text-indigo-600 bg-indigo-200':
                              event.status == 'normal',
                          }"
                        >
                          {{ event.status }}
                        </div>
                      </td>
                      <td>
                        <Menu as="div" class="">
                          <div>
                            <MenuButton class="pt-4 pl-4">
                              <DotsHorizontalIcon
                                class="h-5 w-5 text-gray-500 hover:text-gray-200"
                                aria-hidden="true"
                              />
                            </MenuButton>
                          </div>

                          <transition
                            enter-active-class="transition duration-100 ease-out"
                            enter-from-class="transform scale-95 opacity-0"
                            enter-to-class="transform scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-in"
                            leave-from-class="transform scale-100 opacity-100"
                            leave-to-class="transform scale-95 opacity-0"
                          >
                            <MenuItems
                              class="absolute z-40 w-32 right-20 md:right-40 mt-0 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div class="px-1 py-1">
                                <MenuItem
                                  v-slot="{ active }"
                                  v-if="event.status == 'alerting'"
                                >
                                  <button
                                    v-on:click="changeStatus(event._id, 'ack')"
                                    :class="[
                                      active
                                        ? 'bg-gray-200 text-white'
                                        : 'text-gray-500',
                                      'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                    ]"
                                  >
                                    Acknowledge
                                  </button>
                                </MenuItem>
                                <MenuItem
                                  v-slot="{ active }"
                                  v-if="
                                    event.status == 'ack' ||
                                    event.status == 'normal'
                                  "
                                >
                                  <button
                                    v-on:click="
                                      (assign_event_modal = true),
                                        (selected_event_id = event._id),
                                        (selected_user = event.assigned_user)
                                    "
                                    :class="[
                                      active
                                        ? 'bg-gray-200 text-white'
                                        : 'text-gray-500',
                                      'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                    ]"
                                  >
                                    Assign
                                  </button>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                  <button
                                    v-on:click="addNote(event._id)"
                                    :class="[
                                      active
                                        ? 'bg-gray-200 text-white'
                                        : 'text-gray-500',
                                      'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                    ]"
                                  >
                                    Add Note
                                  </button>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                  <button
                                    v-on:click="
                                      changeStatus(event._id, 'whitelisted')
                                    "
                                    :class="[
                                      active
                                        ? 'bg-gray-200 text-white'
                                        : 'text-gray-500',
                                      'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                    ]"
                                  >
                                    Whitelist
                                  </button>
                                </MenuItem>

                                <MenuItem v-slot="{ active }">
                                  <button
                                    v-on:click="
                                      changeStatus(event._id, 'closed')
                                    "
                                    :class="[
                                      active
                                        ? 'bg-gray-200 text-white'
                                        : 'text-gray-500',
                                      'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                    ]"
                                  >
                                    Close
                                  </button>
                                </MenuItem>
                              </div>
                            </MenuItems>
                          </transition>
                        </Menu>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- end table --->
        </div>
      </div>
    </div>
    <!-- Finish section --->

    <!-- Start  -->
    <div class="px-3">
      <div class="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <div class="flex pr-3 text-lg leading-6 font-medium text-gray-900">
            <BanIcon class="h-5 w-5 text-indigo-300" />
            <div class="px-5 my-auto">Whitelisted Devices and Gateways</div>
          </div>
          <p class="mt-1 mb-5 max-w-2xl text-sm text-gray-500">
            Devices and Gateways that you want to remove from certain Rules
          </p>

          <!-- Start table -->

          <div class="my-0 overflow-x-auto sm:-mx-0 lg:-mx-0">
            <div
              class="py-0 align-middle inline-block min-w-full sm:px-2 px-0 lg:px-0"
            >
              <table class="w-full">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Event
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Rule Name
                    </th>

                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Assigned
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Notes
                    </th>

                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr
                    v-for="event in whitelisted_events"
                    :key="event.description"
                    class="align-top"
                  >
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                    >
                      <div class="grid" v-if="event.dev_id">
                        <router-link :to="'/devices/' + event.dev_id">
                          <div>{{ event.core_device_id }}</div>
                          <div>{{ event.device_description }}</div>
                        </router-link>
                      </div>
                      <div class="grid" v-else-if="event.gateway_id">
                        <router-link :to="'/gateways/' + event.gateway_id">
                          <div>{{ event.core_gateway_id }}</div>
                          <div>{{ event.gateway_description }}</div>
                        </router-link>
                      </div>
                      <div class="grid" v-else>
                        <div>{{ event.rule_id }}</div>
                        <div>{{ event.description }}</div>
                      </div>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                    >
                      {{ event.rule_name }}
                    </td>

                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-sm text-gray-600"
                    >
                      {{
                        getTimeDiff(
                          dateMath.diff(
                            new Date(event.created_at),
                            new Date(),
                            'minutes',
                            false
                          )
                        )
                      }}
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
                    >
                      {{ event.assigned_user }}
                    </td>

                    <td class="px-2 py-4 text-xs font-sm text-grey-500">
                      <div v-if="add_note_to_event_id == event._id">
                        <input
                          type="text"
                          v-model="event.notes"
                          v-on:keyup.enter="saveNote(event)"
                          class="whitespace-normal font-mono text-xs font-sm text-grey-300"
                        />
                      </div>
                      <div
                        v-else
                        class="font-mono text-xs font-sm text-grey-300"
                      >
                        {{ event.notes }}
                      </div>
                    </td>
                    <td
                      class="flex px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div
                        class="mt-1 mr-1 px-3 py-0 max-w-min border border-transparent text-xs leading-4 rounded-md"
                        v-bind:class="{
                          'text-red-600 bg-red-200': event.status == 'alerting',
                          'text-amber-600 bg-amber-100 ':
                            event.status == 'pending',
                          'text-green-600 bg-green-200': event.status == 'ack',
                          'text-indigo-600 bg-indigo-200':
                            event.status == 'normal',
                        }"
                      >
                        {{ event.status }}
                      </div>
                    </td>
                    <td>
                      <Menu as="div" class="">
                        <div>
                          <MenuButton class="pt-4 pl-4">
                            <DotsHorizontalIcon
                              class="h-5 w-5 text-gray-500 hover:text-gray-200"
                              aria-hidden="true"
                            />
                          </MenuButton>
                        </div>

                        <transition
                          enter-active-class="transition duration-100 ease-out"
                          enter-from-class="transform scale-95 opacity-0"
                          enter-to-class="transform scale-100 opacity-100"
                          leave-active-class="transition duration-75 ease-in"
                          leave-from-class="transform scale-100 opacity-100"
                          leave-to-class="transform scale-95 opacity-0"
                        >
                          <MenuItems
                            class="absolute z-40 w-32 right-20 md:right-40 mt-0 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div class="px-1 py-1">
                              <MenuItem v-slot="{ active }">
                                <button
                                  v-on:click="changeStatus(event._id, 'closed')"
                                  :class="[
                                    active
                                      ? 'bg-gray-200 text-white'
                                      : 'text-gray-500',
                                    'group flex w-full items-center rounded-md px-2 py-2 text-xs font-sm text-gray-600',
                                  ]"
                                >
                                  Remove from Whitelist
                                </button>
                              </MenuItem>
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- end table --->
        </div>
      </div>
    </div>
    <!-- Finish section --->

    <!-- Start  -->
    <div class="px-3">
      <div class="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <div class="flex pr-3 text-lg leading-6 font-medium text-gray-900">
            <LockClosedIcon class="h-5 w-5 text-green-300" />
            <div class="px-5 my-auto">Closed Events</div>
          </div>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            As messages are received from devices on the coreThings platform,
            they're fed through the Rules Engine. If a message is flagged by any
            rule, an event is created on the platform.
          </p>

          <div
            class="mt-10 min-w-full bg-gray-900 text-gray-300 text-xs font-mono h-96 p-3 overflow-auto"
          >
            <table>
              <tr v-for="event in events" :key="event">
                <td>
                  <div class="flex">
                    <div class="text-gray-400 mr-3">
                      {{ formatDate(event.created_at) }}
                    </div>
                    <div class="mr-3">{{ event.description }}</div>
                    <div class="text-gray-400">{{ event.status }}</div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Finish section --->
  </div>
</template>

<script>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  LockClosedIcon,
  DotsHorizontalIcon,
  BellIcon,
  BanIcon,
} from '@heroicons/vue/solid';
import axios from 'axios';
import dateformat from 'dateformat';
import * as dateMath from 'date-arithmetic';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import AssignEvent from '../../components/AssignEvent.vue';

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    LockClosedIcon,
    DotsHorizontalIcon,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    AssignEvent,
    BellIcon,
    BanIcon,
  },

  setup() {
    return {};
  },
  data() {
    return {
      events: [],
      open_events: [],
      pending_events: [],
      whitelisted_events: [],
      timer: '',
      dateMath,
      assign_event_modal: false,
      selected_event_id: null,
      selected_user: null,
      add_note_to_event_id: '',
    };
  },
  methods: {
    saveNote(event) {
      console.log('saving note!');
      axios
        .put('/events/' + event._id, event)
        .then((res) => {
          this.add_note_to_event_id = '';
          this.loadOpenEvents();
        })
        .catch();
    },
    addNote(event_id) {
      this.add_note_to_event_id = event_id;
    },
    assignUser(event) {
      this.assign_event_modal = false;
      console.log('YEY!!!!!!');
      console.log('this.selected_event_id: ' + this.selected_event_id);
      console.log(event);

      let current_event = null;

      for (let open_event of this.open_events) {
        if (open_event._id == this.selected_event_id) {
          console.log('found event!');
          current_event = open_event;
          current_event.assigned_user = event.assigned_user;
          console.log(current_event);
        }
      }

      if (current_event != null) {
        axios
          .put('/events/' + this.selected_event_id, current_event)
          .then((res) => {})
          .catch();
      }
    },

    closeAllNormalEvents() {
      console.log('closeAllNormalEvents');
      for (let event of this.open_events) {
        if (event.status == 'normal') {
          this.changeStatus(event._id, 'closed');
        }
      }
    },

    changeStatus(event_id, status) {
      console.log(`event_id: ${event_id}`);
      let current_event = null;

      for (let event of this.open_events) {
        if (event._id == event_id) {
          current_event = event;
          current_event.status = status;
        }
      }

      //It may also be in whitelisted events
      for (let event of this.whitelisted_events) {
        if (event._id == event_id) {
          current_event = event;
          current_event.status = status;
        }
      }
      console.log('current_event: ');
      console.log(current_event);

      if (current_event != null) {
        console.log('changing event status now!');
        axios
          .put('/events/' + event_id, current_event)
          .then((res) => {
            this.loadOpenEvents();
            this.loadWhitelistedEvents();
          })
          .catch();
      }
    },
    changeStatusOfWhitelistedEvent(event_id, status) {
      console.log(`event_id: ${event_id}`);
      let current_event = null;

      for (let event of this.whitelisted_events) {
        if (event._id == event_id) {
          current_event = event;
          current_event.status = status;
        }
      }

      if (current_event != null) {
        axios
          .put('/events/' + event_id, current_event)
          .then((res) => {
            this.loadOpenEvents();
          })
          .catch();
      }
    },
    formatDate(date_string) {
      if (date_string) {
        let date = new Date(date_string);
        return dateformat(date, 'dddd, mmmm dS, yyyy, h:MM:ss TT');
      }
    },
    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnCreated = '';
      console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnCreated = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnCreated = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnCreated = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnCreated;
    },
    loadPage() {
      this.loadEvents();
      this.loadOpenEvents();
      this.loadPendingEvents();
      this.loadWhitelistedEvents();
    },

    loadEvents() {
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;
      console.log('loadEvents is run');
      axios
        .get(
          `/events?core_account_name=${currentAccountSession}&core_project_name=${currentProjectSession}&limit=100&status=closed`
        )
        .then((res) => {
          //console.log(res);
          this.events = res.data.data;
        })
        .catch((err) => {});

      //Let's poll this every 5 seconds
      // setTimeout(() => {
      //   this.loadEvents();
      // }, 5000);
    },
    loadOpenEvents() {
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;
      console.log('loadOpenEvents is run');
      axios
        .get(
          `/events?core_account_name=${currentAccountSession}&core_project_name=${currentProjectSession}&status=alerting&status=ack&status=normal`
        )
        .then((res) => {
          this.open_events = res.data.data;
          console.log(res);
        })
        .catch((err) => {});

      //Let's poll this every 5 seconds
      // setTimeout(() => {
      //   this.loadEvents();
      // }, 5000);
    },
    loadPendingEvents() {
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;
      console.log('loadPendingEvents is run');
      axios
        .get(
          `/events?core_account_name=${currentAccountSession}&core_project_name=${currentProjectSession}&status=pending`
        )
        .then((res) => {
          this.pending_events = res.data.data;
          console.log(res);
        })
        .catch((err) => {});
    },
    loadWhitelistedEvents() {
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;
      console.log('loadWhitelistedEvents is run');
      axios
        .get(
          `/events?core_account_name=${currentAccountSession}&core_project_name=${currentProjectSession}&status=whitelisted`
        )
        .then((res) => {
          this.whitelisted_events = res.data.data;
          console.log(res);
        })
        .catch((err) => {});
    },

    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },

  beforeUnmount() {
    this.cancelAutoUpdate();
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    console.log('Devices created called!!');
    this.loadPage();
  },
};
</script>
