<template>
  <div class="flex-shrink-0 flex items-center px-4 pt-10">
    <img
      class="h-14 w-auto"
      src="../../assets/corethings_light_theme_1200_text_thicker.png"
      alt="coreThings"
    />
  </div>

  <div class="col-span-3 ml-5 mt-5 mb-2 text-lg font-medium text-gray-700">
    {{ $store.state.account.name }} Account - Weekly Report - Beta
  </div>

  <div class="flex justify-start mr-5 mb-2 max-h-1">
    <Menu as="div" class="relative inline-block self-start ml-2 text-left w-72">
      <div>
        <MenuButton
          class="inline-flex justify-left w-full rounded-md border border-gray-200 shadow-sm ml-2 px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          >{{ getDateFormat(selected_week.last_day) }} - Week #{{
            selected_week.week_number
          }}
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="z-40 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="py-1">
            <MenuItem
              v-for="week in timeframe_dropdown"
              :key="week"
              v-on:click="selectNewWeek(week)"
            >
              <div class="bg-white text-gray-900 block px-4 py-2 text-sm">
                {{ getDateFormat(week.last_day) }} - Week #{{
                  week.week_number
                }}
              </div>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>

  <div
    class="bg-white px-4 mt-16 ml-5 pb-0 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden max-w-fit"
  >
    <dt>
      <div class="absolute bg-red-600 rounded-md p-0 mb-5">
        <svg
          baseProfile="full"
          width="32px"
          height="32px"
          viewBox="0 0 32 32"
          preserveAspectRatio="xMidYMid meet"
          id="svg_document"
          style="zoom: 1"
          visibility="visible"
        >
          <title id="svg_document_title" visibility="visible">
            corethings.svg
          </title>
          <defs id="svg_document_defs" visibility="visible"></defs>
          <g id="main_group" visibility="visible"></g>
          <circle
            id="core"
            cx="16px"
            cy="16px"
            r="8px"
            fill="white"
            visibility="visible"
          ></circle>
          <circle
            id="trajectory"
            cx="16px"
            cy="16px"
            r="12px"
            fill="none"
            stroke="white"
            stroke-width="1px"
            visibility="visible"
          ></circle>
          <circle
            id="thing_loading"
            cx="16px"
            cy="4px"
            r="2px"
            fill="lightgrey"
            stroke="none"
            visibility="visible"
          ></circle>
          <circle
            id="thing_constant"
            cx="16px"
            cy="4px"
            r="2px"
            fill="white"
            stroke="none"
            visibility="visible"
          ></circle>
        </svg>
      </div>
      <p class="ml-16 text-medium font-medium text-gray-500">
        CO<sub>2</sub> emitted in W{{ selected_week.week_number }}
      </p>
    </dt>
    <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
      <p class="text-2xl font-semibold text-gray-900">
        {{ (total_co2 / 1000).toFixed(1) }} Tonnes
      </p>
    </dd>
  </div>

  <div class="px-2 sm:px-2 lg:px-4">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto pt-10">
        <div class="text-medium font-medium text-gray-700">
          W{{ selected_week.week_number }} Meter Readings
        </div>
      </div>
    </div>
    <div class="mt-2 mx-0 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
                    v-on:click="sortMeterMap"
                  >
                    Meter
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                  >
                    Meter Type
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                  >
                    Reading
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                  >
                    Usage
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                  >
                    Cost (€)
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                  >
                    CO<sub>2</sub> (Kg)
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="[core_device_id, meter] in meter_reading_map"
                  :key="core_device_id"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6"
                  >
                    {{ meter.core_device_id }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    {{ meter.type }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    {{ meter.absolute_reading_end.toLocaleString() }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <div class="flex">
                      <div>{{ meter.usage.toLocaleString() }}</div>
                      <div v-if="meter.type == 'gas'" class="ml-1">
                        m<sup>3</sup>
                      </div>
                      <div v-else class="ml-1">kWh</div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    {{ meter.cost.toLocaleString() }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    {{ meter.co2.toLocaleString() }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-64"
                  ></th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-48"
                  ></th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-48"
                  ></th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-48"
                  ></th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-48"
                  ></th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-48"
                  ></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-64"
                  >
                    TOTAL
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-48"
                  ></td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-48"
                  ></td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-48"
                  ></td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-48 pl-20 font-medium"
                  >
                    €{{ total_cost.toLocaleString() }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-48 font-medium"
                  >
                    {{ (total_co2 / 1000).toFixed(1) }} Tonnes of CO<sub>2</sub>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <apexchart
    class="col-span-2"
    width="100%"
    height="300"
    :options="chart.options"
    :series="chart.series"
  ></apexchart> -->

  <div class="px-2 sm:px-2 lg:px-4">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto pt-20">
        <div class="text-medium font-medium text-gray-700">
          W{{ selected_week.week_number }} Weekly Energy Graphs
        </div>
      </div>
    </div>
  </div>

  <div class="mx-5 grid grid-cols-1">
    <div v-for="[core_device_id, chart] in charts" :key="core_device_id">
      <div
        v-if="meter_reading_map.get(chart.core_device_id)"
        class="col-span-1 flex flex-col mt-2 mb-2 border bg-white rounded-xl mx-0 divide-y divide-gray-200"
      >
        <odometer-display
          :number="
            meter_reading_map
              .get(chart.core_device_id)
              .absolute_reading_end.toString()
          "
          :size="'small'"
          :theme="'light'"
        />
        <div class="grid grid-cols-4 divide-x divide-gray-200">
          <div class="grid col-span-3 justify-items-stretch">
            <apexchart
              class="col-span-2"
              width="100%"
              height="200"
              :options="chart.options"
              :series="chart.series"
            ></apexchart>
          </div>
          <div class="grid grid-rows-3 flex col-span-1 justify-items-stretch">
            <div class="flex justify-center w-full mt-5">
              <div class="text-2xl text-gray-500">
                {{ meter_reading_map.get(chart.core_device_id).usage }}
              </div>

              <div
                v-if="
                  meter_reading_map.get(chart.core_device_id).type ==
                  'electricity'
                "
                class="text-medium font-medium text-gray-500"
              >
                kWh
              </div>
              <div v-else class="text-medium font-medium text-gray-500">
                m<sup>3</sup>
              </div>
            </div>
            <div class="flex justify-center w-full mt-0">
              <div class="text-2xl text-gray-400">
                {{ meter_reading_map.get(chart.core_device_id).cost }}
              </div>
              <div class="text-small font-small text-gray-400">€</div>
            </div>
            <div class="flex justify-center w-full mt-0">
              <div class="text-2xl text-gray-400">
                {{ meter_reading_map.get(chart.core_device_id).co2 }}
              </div>
              <div class="text-small font-small text-gray-400">
                Kg CO<sub>2</sub>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Events panel   -->
  <div class="px-2 sm:px-2 lg:px-4 py-2">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto pt-20">
        <div class="text-medium font-medium text-gray-700">
          W{{ selected_week.week_number }} Events
        </div>
      </div>
    </div>
  </div>
  <div class="mx-5 bg-white rounded-xl">
    <div class="py-0 align-middle inline-block min-w-full sm:px-2 px-0 lg:px-0">
      <table class="w-full">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Event
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Rule Name
            </th>

            <th
              scope="col"
              class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Created
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Assigned
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Notes
            </th>

            <th
              scope="col"
              class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr
            v-for="event in filtered_events"
            :key="event.description"
            class="align-top"
          >
            <td
              class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
            >
              <div class="grid" v-if="event.dev_id">
                <router-link :to="'/devices/' + event.dev_id">
                  <div>{{ event.core_device_id }}</div>
                  <div>{{ event.device_description }}</div>
                </router-link>
              </div>
              <div class="grid" v-else-if="event.gateway_id">
                <router-link :to="'/gateways/' + event.gateway_id">
                  <div>{{ event.core_gateway_id }}</div>
                  <div>{{ event.gateway_description }}</div>
                </router-link>
              </div>
              <div class="grid" v-else>
                <div>{{ event.rule_id }}</div>
                <div>{{ event.description }}</div>
              </div>
            </td>
            <td
              class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
            >
              {{ event.rule_name }}
            </td>

            <td
              class="px-2 py-4 whitespace-nowrap text-xs font-sm text-gray-600"
            >
              {{ formatDate(event.created_at) }}
            </td>
            <td
              class="px-2 py-4 whitespace-nowrap text-xs font-sm text-grey-500"
            >
              {{ event.assigned_user }}
            </td>

            <td class="px-2 py-4 text-xs font-sm text-grey-500">
              <div class="font-mono text-xs font-sm text-grey-300">
                {{ event.notes }}
              </div>
            </td>
            <td class="flex px-2 py-4 whitespace-nowrap text-xs text-gray-500">
              <div
                class="mt-1 mr-1 px-3 py-0 max-w-min border border-transparent text-xs leading-4 rounded-md"
                v-bind:class="{
                  'text-red-600 bg-red-200': event.status == 'alerting',
                  'text-amber-600 bg-amber-100 ': event.status == 'pending',
                  'text-green-600 bg-green-200': event.status == 'ack',
                  'text-indigo-600 bg-indigo-200': event.status == 'normal',
                }"
              >
                {{ event.status }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="px-2 sm:px-2 lg:px-4 py-2">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto pt-5">
        <div class="text-medium font-medium text-gray-700">
          W{{ selected_week.week_number }} Weekly Sensor Graphs
        </div>
      </div>
    </div>
  </div>
  <div class="mx-5 bg-white rounded-xl">
    <div v-for="mini_chart in mini_charts" :key="mini_chart">
      <div class="grid grid-cols-4 divide-x divide-gray-200">
        <div class="grid col-span-3 justify-items-stretch">
          <apexchart
            class="col-span-2"
            width="100%"
            height="200"
            :options="mini_chart.options"
            :series="mini_chart.series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-gray-100 mt-2 mx-2">
    <div v-if="loading" class="flex overflow-hidden bg-gray-100 justify-center">
      <div><loading-widget /></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingWidget from '../../components/LoadingWidget.vue';
import { PlusIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import {
  SearchIcon,
  DotsHorizontalIcon,
  ArrowSmUpIcon,
} from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';
const WeeklyDropdown = ['wk 39', 'wk 38'];
import getWeek from 'date-fns/getWeek';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import lightFormat from 'date-fns/lightFormat';
import dateFormat from 'dateformat';
import OdometerDisplay from '../../components/OdometerDisplay.vue';

const people = [
  {
    name: 'Lindsay Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
  },
  // More people...
];

export default {
  components: {
    LoadingWidget,
    PlusIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    SearchIcon,
    XIcon,
    WeeklyDropdown,
    dateFormat,
    OdometerDisplay,
    DotsHorizontalIcon,
    ArrowSmUpIcon,
  },
  data() {
    return {
      core_device_ids: [],
      loading: false,
      date: new Date(),
      timeframe_dropdown: [],
      selected_week: {},
      charts: new Map(),
      device: {},
      devices: [],
      meter_ratio_map: new Map(),
      meter_reading_map: new Map(),
      total_cost: 0,
      total_co2: 0,
      mini_charts: [],
      filtered_events: [],
    };
  },
  methods: {
    formatDate(date_string) {
      return lightFormat(new Date(date_string), 'yyyy-MM-dd');
    },
    sortMeterMap() {
      console.log('sorting Meter Map');
      console.log(this.meter_reading_map);
      let tempMap = new Map([...this.meter_reading_map.entries()].sort());
      this.meter_reading_map = tempMap;

      let tempChartMap = new Map([...this.charts.entries()].sort());
      this.charts = tempChartMap;

      console.log('after....');
      console.log(this.meter_reading_map);
    },
    loadEvents() {
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;
      console.log('loadEvents is run');

      let start_timestamp = lightFormat(
        new Date(this.selected_week.first_day),
        'yyyy-MM-dd HH:mm:ss'
      );
      // console.log('start_timestamp: ' + start_timestamp);
      let end_timestamp = lightFormat(
        new Date(this.selected_week.last_day),
        'yyyy-MM-dd HH:mm:ss'
      );

      let url =
        `/events?core_account_name=${currentAccountSession}&core_project_name=${currentProjectSession}&limit=100` +
        `&status=alerting&status=ack&status=normal` +
        `&start_timestamp=${start_timestamp}` +
        `&end_timestamp=${end_timestamp}`;

      if (!this.$store.state.account.name == 'freshpet-eu') {
        //Let's not include freshpet closed events for now as there are too many
        url += '&status=closed';
      }
      axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.filtered_events = res.data.data;
        })
        .catch((err) => {});
    },
    loadPage() {
      //Let's reset all the values before continuing
      this.resetValues();

      //Let's get a list of all the devices first
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;
      this.loadEvents();
      axios
        .get(
          '/devices?core_account_name=' +
            currentAccountSession +
            '&core_project_name=' +
            currentProjectSession
        )
        .then((res) => {
          this.devices = res.data.data;
          console.log('this.devices:');
          console.log(this.devices);
          for (let device of this.devices) {
            if (
              device.capabilities.includes('electricity') ||
              device.capabilities.includes('gas')
            ) {
              console.log('electricity/gas device');

              // if (device.capabilities.includes('electricity')) {
              //   this.meter_ratio_map.set(
              //     device.core_device_id,
              //     1000 / device.electricity_imp_per_unit
              //   );
              // } else if (device.capabilities.includes('gas')) {
              //   this.meter_ratio_map.set(
              //     device.core_device_id,
              //     1 / device.gas_imp_per_unit
              //   );
              // }
              this.meter_ratio_map.set(
                device.core_device_id,
                device.imp_per_unit
              );
              this.loadGraph(device, '7d');

              //Let's populate the meter readings for each meter
              let start_timestamp = lightFormat(
                new Date(this.selected_week.first_day),
                'yyyy-MM-dd HH:mm:ss.SSS'
              );
              //('start_timestamp: ' + start_timestamp);
              let end_timestamp = lightFormat(
                new Date(this.selected_week.last_day),
                'yyyy-MM-dd HH:mm:ss.SSS'
              );
              //console.log('end_timestamp: ' + end_timestamp);

              let measure_name = null;
              if (device.capabilities.includes('electricity')) {
                measure_name = 'electricity_value';
              } else if (device.capabilities.includes('gas')) {
                measure_name = 'gas';
              }

              axios
                .get(
                  '/reports/meter-reading?measure_name=' +
                    measure_name +
                    `&core_device_id=` +
                    device.core_device_id +
                    `&start_timestamp=${start_timestamp}` +
                    `&end_timestamp=${end_timestamp}`
                )
                .then((res) => {
                  //TODO
                  //console.log(res);

                  //Let's work through the metered data and pull out the following
                  //first reading
                  //last reading
                  //difference between both
                  //meter ratio
                  //cost
                  let meter_array = res.data.series[0].data;
                  let array_length = meter_array.length;
                  // console.log('array_length: ' + array_length);
                  //let meter_ratio = 1;
                  let meter = {
                    start: 0,
                    end: 0,
                    usage: 0,
                    cost: 0,
                    co2: 0,
                    absolute_reading_end: 0,
                  };
                  meter.core_device_id = device.core_device_id;
                  if (res.data.series[0].name == 'electricity_value') {
                    meter.type = 'electricity';
                  } else if (res.data.series[0].name == 'gas') {
                    meter.type = 'gas';
                  }

                  if (meter_array.length > 0) {
                    //The meter could reset during the period
                    //So, we check for this across all the datapoints
                    //If we come across a reset, then we add the usages to
                    //an array and at the end add them all together
                    //It's somewhat inefficient but very robust (one could reset a sensor as many times as they like)

                    let usage_buckets = [];
                    let running_total = 0;
                    let starting_counter = meter_array[0].y;
                    let reset = false;

                    for (let i = 1; i < array_length; i++) {
                      if (meter_array[i].y >= meter_array[i - 1].y) {
                        running_total = meter_array[i].y - starting_counter;
                      } else {
                        //We've hit a reset in the counter. Let's update the usage_buckets
                        //with the previous value i-1
                        reset = true;
                        console.log('hit a reset in counter');
                        console.log('previous value: ' + meter_array[i - 1].y);
                        console.log('new value: ' + meter_array[i].y);
                        console.log('pushing_running_total: ' + running_total);
                        usage_buckets.push(running_total);
                        //Let's reset the counter since we've replaced the batteries at this point
                        //It essentially becomes the counter value itself
                        starting_counter = 0;
                      }
                    }

                    //At this stage, if there were any resets, then the counter values would have been added to the buckets
                    //The remaining value of the counter will also hold the usage for the period after the last reset
                    //If there were no resets, then the counter value is not added to
                    console.log(
                      'number of usage buckets = ' + usage_buckets.length
                    );
                    console.log(usage_buckets);

                    for (let bucket of usage_buckets) {
                      running_total = running_total + bucket;

                      console.log('running_total increased by: ' + bucket);
                    }

                    console.log('running_total: ' + running_total);

                    //meter.type = 'gas';
                    //meter_ratio = 1 / device.gas_imp_per_unit;

                    meter.start = Math.round(
                      meter_array[0].y / device.imp_per_unit
                    );
                    meter.start_date = new Date(meter_array[0].x);

                    meter.end = Math.round(
                      meter_array[array_length - 1].y / device.imp_per_unit
                    );
                    meter.end_date = new Date(meter_array[array_length - 1].x);

                    //The most accurate way to record usage is by taking the meter.end from meter.start
                    //however, in some cases we may have had a sensor/counter restart in which case the
                    //only way is to count the individual usages which maybe missing some counts
                    if (reset == false) {
                      meter.usage = Math.round(meter.end - meter.start);
                    } else {
                      meter.usage = Math.round(
                        running_total / device.imp_per_unit,
                        0
                      );
                    }

                    meter.cost = Math.round(
                      meter.usage * device.price_per_unit
                    );
                    meter.co2 = Math.round(meter.usage * device.co2_per_unit);

                    //Let's get the relevant base meter reading for this particular week
                    //There may have been subsequent base meter reading resets which will not
                    //make sense for this particular week
                    let base_array = device.base_meter_reading_array;
                    let previous_bases = [];
                    if (base_array && base_array[0]) {
                      //we want to get the reading that was most recent to the current date

                      for (let i = 0; i < base_array.length; i++) {
                        if (
                          new Date(base_array[i].date) <
                          this.selected_week.last_day
                        ) {
                          previous_bases.push(base_array[i]);
                        }
                      }
                      //Now that we have all of the previous dates, let's order them by date
                      //and get the most recent one
                      previous_bases.sort(function (a, b) {
                        return new Date(b.date) - new Date(a.date);
                      });

                      //Now we can take the most recent one at index 0
                      meter.end_reading = meter_array[array_length - 1].y;
                      meter.base = parseInt(previous_bases[0].base_reading);
                      meter.absolute_reading_end =
                        Math.round(meter.end) +
                        parseInt(previous_bases[0].base_reading);
                    }
                    console.log(meter);
                  }

                  this.meter_reading_map.set(device.core_device_id, meter);
                  this.total_cost += meter.cost;
                  this.total_co2 += meter.co2;
                })
                .catch();
            } else {
              console.log('NOT an electricity/gas device');
              //We have other devices that are not meters and we call the smaller panels
              //Let's not do this for freshpet
              //TODO decice on what's best here for large users
              console.log(
                'this.$store.state.account.name: ' +
                  this.$store.state.account.name
              );
              if (this.$store.state.account.name != 'freshpet-eu') {
                this.loadMiniChart(device, '7d');
              }
            }
          }
        })
        .catch();
    },
    getDateFormat(date) {
      //console.log(date);
      return dateFormat(date, 'ddd, mmm dS, yyyy');
    },

    resetValues() {
      this.meter_ratio_map = new Map();
      this.meter_reading_map = new Map();
      this.total_cost = 0;
      this.total_co2 = 0;
      this.charts = new Map();
      this.devices = [];
      this.mini_charts = [];
      this.filtered_events = [];
    },

    selectNewWeek(week) {
      this.selected_week = week;
      this.resetValues();
      this.loadPage();
    },
    orderByName() {},
    loadMiniChart(device) {
      let start_timestamp = lightFormat(
        new Date(this.selected_week.first_day),
        'yyyy-MM-dd HH:mm:ss'
      );
      //console.log('start_timestamp: ' + start_timestamp);
      let end_timestamp = lightFormat(
        new Date(this.selected_week.last_day),
        'yyyy-MM-dd HH:mm:ss'
      );

      let series_type = null;
      if (device.product_name == 'core-01-868-traffic-sensor') {
        series_type = 'aisle_traffic';
      } else if (device.product_name == 'core-01-868-temp-sensor') {
        series_type = 'temperature_internal';
      } else if (device.product_name == 'core-01-868-door-sensor') {
        series_type = 'door';
      }

      axios
        .get(
          '/reports/device?' +
            'measure_name=' +
            series_type +
            '&measure_name=' +
            series_type +
            '&core_device_id=' +
            device.core_device_id +
            `&start_timestamp=${start_timestamp}` +
            `&end_timestamp=${end_timestamp}` +
            '&timeframe_comparison=true'
        )
        .then((res) => {
          //This will now contain 2 sets of series_data
          //one for this week and one for last week

          let multiple_series_data = [];
          let local_core_device_id = res.data.core_device_id;

          for (let i = 0; i < res.data.series.length; i++) {
            let single_series = {
              name: 'timeframe -' + i,
              data: [],
            };

            if (i == 0) {
              single_series.name = 'wk ' + this.selected_week.week_number;
            } else if (i == 1) {
              single_series.name = 'wk ' + (this.selected_week.week_number - 1);
            }
            single_series.data = res.data.series[i].data;
            // console.log('********************');
            // console.log(single_series.data);
            multiple_series_data.push(single_series);
          }
          let new_chart = {
            core_device_id: device.core_device_id,
            options: {
              theme: {
                palette: 'palette2',
              },

              chart: {
                stacked: false,
                type: 'area',
                height: 350,
                zoom: {
                  type: 'x',
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: 'zoom',
                },
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 1,
                dashArray: 0,
              },
              title: {
                text: local_core_device_id,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: undefined,
                  color: '#263238',
                },
              },
              subtitle: {
                text: this.device.description,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 20,
                floating: false,
                style: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                  fontFamily: undefined,
                  color: '#9699a2',
                },
              },
              dataLabels: {
                enabled: false,
              },

              tooltip: {
                enabled: true,
                x: {
                  show: true,
                  format: 'dd MMM HH:mm',
                },
              },
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset:
                    true | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: [],
                },
                autoSelected: 'zoom',
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  datetimeUTC: false,
                  format: 'ddd',
                },
              },
            },
            //series: res.data.series,
            series: multiple_series_data,
          };
          // console.log('New chart added: ');
          // console.log(new_chart);
          this.mini_charts.push(new_chart);
        })
        .catch();
    },
    loadGraph(device, timeframe) {
      // console.log('loadGraph called!');
      // console.log('timeframe: [' + timeframe + ']');

      let series_type = null;
      if (device.capabilities.includes('electricity')) {
        series_type = 'electricity_series';
      } else if (device.capabilities.includes('gas')) {
        series_type = 'gas_series';
      }

      // console.log(
      //   'url : ' +
      //     '/reports/device?measure_name=' +
      //     series_type +
      //     '&core_device_id=' +
      //     device.core_device_id +
      //     '&timeframe=' +
      //     timeframe +
      //     '&timeframe_comparison=true'
      // );

      let start_timestamp = lightFormat(
        new Date(this.selected_week.first_day),
        'yyyy-MM-dd HH:mm:ss'
      );
      // console.log('start_timestamp: ' + start_timestamp);
      let end_timestamp = lightFormat(
        new Date(this.selected_week.last_day),
        'yyyy-MM-dd HH:mm:ss'
      );
      // console.log('end_timestamp: ' + end_timestamp);

      axios
        .get(
          '/reports/device?' +
            'measure_name=' +
            series_type +
            '&measure_name=' +
            series_type +
            '&core_device_id=' +
            device.core_device_id +
            `&start_timestamp=${start_timestamp}` +
            `&end_timestamp=${end_timestamp}` +
            '&timeframe_comparison=true'
        )
        .then((res) => {
          // console.log('*****************');
          // console.log(res);
          // console.log('*********************');
          let fine_grain_graph = false;
          if (timeframe == '1h') {
            fine_grain_graph = true;
          }

          //This will now contain 2 sets of series_data
          //one for this week and one for last week

          let multiple_series_data = [];
          //console.log('core_device_id FROM JSON:' + res.data.core_device_id);
          // console.log('meter_ratio: ' + this.meter_ratio_map.get());

          //console.log('res.data.core_device_id: ' + res.data.core_device_id);
          let local_core_device_id = res.data.core_device_id;
          let local_meter_ratio =
            this.meter_ratio_map.get(local_core_device_id);
          // console.log('local_meter_ratio: ' + local_meter_ratio);

          // console.log('number of series in the dataset: ');
          // console.log(res.data.series.length);

          for (let i = 0; i < res.data.series.length; i++) {
            let single_series = {
              name: 'timeframe -' + i,
              data: [],
            };

            if (i == 0) {
              single_series.name = 'wk ' + this.selected_week.week_number;
            } else if (i == 1) {
              single_series.name = 'wk ' + (this.selected_week.week_number - 1);
            }

            let new_series_data = [];

            for (let data of res.data.series[i].data) {
              // let max_value_in_timeframe = 0;
              let running_total_in_timeframe = 1;
              let last_timestamp = null;
              let i = 0;
              // let data_point = {};

              for (let expanded_row of data.y) {
                i++;

                if (fine_grain_graph) {
                  if (expanded_row.value != 'NaN') {
                    new_series_data.push({
                      x: expanded_row.timestamp,
                      y: expanded_row.value / local_meter_ratio,
                    });
                  }
                } else {
                  if (expanded_row.value != 'NaN') {
                    running_total_in_timeframe =
                      running_total_in_timeframe + expanded_row.value;
                    last_timestamp = expanded_row.timestamp;
                  }
                }
              }
              // console.log('running_total_in_timeframe: ');
              // console.log(running_total_in_timeframe);

              if (!fine_grain_graph) {
                //For electricity, we take the average of all readings
                //For Gas, we take the addition of the readings

                let final_reading = null;

                if (device.capabilities.includes('electricity')) {
                  final_reading = Math.round(running_total_in_timeframe / i);
                  //console.log('final_reading - electricity: ' + final_reading);
                } else if (device.capabilities.includes('gas')) {
                  final_reading = running_total_in_timeframe.toFixed(2);
                  //console.log('final_reading - gas: ' + final_reading);
                }

                if (final_reading) {
                  if (device.capabilities.includes('electricity')) {
                    new_series_data.push({
                      x: last_timestamp,
                      y: (final_reading / local_meter_ratio).toFixed(2),
                    });
                  } else if (device.capabilities.includes('gas')) {
                    new_series_data.push({
                      x: last_timestamp,
                      y: (final_reading / local_meter_ratio).toFixed(2),
                    });
                  }
                }
              }
            }
            single_series.data = new_series_data;
            multiple_series_data.push(single_series);
          }
          // console.log(multiple_series_data);
          // console.log('=====finished - ready to draw graph======');

          let new_chart = {
            core_device_id: device.core_device_id,
            options: {
              theme: {
                palette: 'palette2',
              },

              chart: {
                stacked: false,
                type: 'area',
                height: 350,
                zoom: {
                  type: 'x',
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: 'zoom',
                },
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 1,
                dashArray: 0,
              },
              title: {
                text: local_core_device_id,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: undefined,
                  color: '#263238',
                },
              },
              subtitle: {
                text: this.device.description,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 20,
                floating: false,
                style: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                  fontFamily: undefined,
                  color: '#9699a2',
                },
              },
              dataLabels: {
                enabled: false,
              },

              tooltip: {
                enabled: true,
                x: {
                  show: true,
                  format: 'dd MMM HH:mm',
                },
              },
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset:
                    true | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: [],
                },
                autoSelected: 'zoom',
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  datetimeUTC: false,
                  format: 'ddd',
                },
              },
            },
            //series: res.data.series,
            series: multiple_series_data,
          };
          // console.log('New chart added: ');
          // console.log(new_chart);
          this.charts.set(local_core_device_id, new_chart);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeCreate() {
    let _this = this;
    // this.$store.watch(
    //   () => this.$store.state.account.name,
    //   function () {
    //     _this.loadPage();
    //   }
    // );
    this.$store.watch(
      () => this.$store.state.project.name,
      function () {
        _this.loadPage();
      }
    );
  },
  setup() {
    return {
      people,
    };
  },

  created() {
    //Since we are creating weekly reports, we need for this current week to finish first
    //before generating a report. So, we choose a date that is 7 days before the current date
    //and then use the start and end date of that week as the latest report. Then, do the same
    //all previous weeks

    //Let's go back 10 weeks - should be enough
    for (let i = 1; i <= 10; i++) {
      let today = new Date();
      let new_date = addDays(today, i * -7);
      // console.log('new_date:');
      // console.log(new_date);

      let date = {};
      date.first_day = startOfWeek(new_date, { weekStartsOn: 1 });
      date.last_day = endOfWeek(new_date, { weekStartsOn: 1 });
      date.week_number = getWeek(new_date, { weekStartsOn: 1 }) - 1;
      this.timeframe_dropdown.push(date);
      console.log(date);

      //If this is the first time coming into the loop, let's set the selected week as the first one
      if (i == 1) {
        this.selected_week = date;
      }
    }
    this.loadPage();
  },
};
</script>
