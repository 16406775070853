<template>
  <div class="justify-center w-full">
    <span class="grid w-full">
      <button
        type="button"
        class="grid justify-items-stretch w-full border-2 border-gray-400 border-dashed rounded-lg pl-2 pr-2 pb-6 bg-white"
        v-bind:class="{
          'bg-indigo-50': is_edit_mode,
        }"
      >
        <div class="justify-self-end">
          <XIcon
            class="h-6 w-6 text-gray-300 hover:text-gray-500 m-3"
            v-on:click="deleteDuration()"
          ></XIcon>
        </div>

        <div class="grid md:flex">
          <div class="grid">
            <div>
              <ClockIcon
                class="flex mx-auto h-12 w-12 text-gray-500"
              ></ClockIcon>
            </div>
            <div class="text-gray-500 text-sm font-medium">Duration</div>
          </div>
          <div class="flex md:flex-1 px-20 my-auto py-10">
            <div class="text-sm font-sm font-mono">Trigger rule after</div>
            <div>
              <input
                v-if="is_edit_mode"
                v-model="data_duration"
                class="w-16 mx-5 px-5 rounded-full bg-green-200 text-green-600 text-sm border-0 focus:outline-none focus:ring-0"
                autocomplete="off"
              />
              <div
                v-else
                class="w-16 mx-5 px-5 rounded-full bg-green-200 text-green-600 text-sm border-0 focus:outline-none focus:ring-0 py-1"
              >
                {{ data_duration }}
              </div>
            </div>
            <div class="text-sm font-sm font-mono">minutes</div>
          </div>
          <div class="flex p-2 w-24 md:flex mx-auto">
            <div grid v-if="is_edit_mode">
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 mb-3 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="submit"
              >
                Submit
              </button>
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="cancel"
              >
                Cancel
              </button>
            </div>
            <div v-else>
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 mb-3 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="changeToEditMode"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </button>
    </span>
    <DotsVerticalIcon class="mx-auto h-4 w-4 text-gray-400"></DotsVerticalIcon>
  </div>
</template>

<script>
import axios from 'axios';

import {
  DotsVerticalIcon,
  PlusCircleIcon,
  ClockIcon,
  XIcon,
} from '@heroicons/vue/outline';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';

export default {
  props: {
    duration: { required: true, type: Number },
  },
  components: {
    CheckIcon,
    SelectorIcon,
    XIcon,
    DotsVerticalIcon,
    PlusCircleIcon,
    ClockIcon,
  },
  setup() {
    return {};
  },

  data() {
    return {
      is_edit_mode: false,
      data_duration: 0,
    };
  },

  methods: {
    submit() {
      console.log('submit called');
      this.is_edit_mode = false;
      let duration = parseInt(this.data_duration);

      console.log('returning: ');
      console.log(duration);
      this.$emit('duration_element_updated', duration);
    },
    cancel() {
      this.is_edit_mode = false;
    },

    deleteDuration() {
      this.$emit('duration_element_deleted');
    },

    changeToEditMode() {
      this.is_edit_mode = true;
    },
  },
  created() {
    console.log('received the following data:');
    console.log(this.duration);

    this.data_duration = this.duration;
  },
};
</script>
