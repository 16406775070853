<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed pt-20 z-10 inset-0" @close="open = false">
      <div
        class="flex items-end justify-center pt-4 px-4 pb-20 text-center block lg:block sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="p-20 inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <svg
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="text-md leading-6 font-medium text-gray-900 mb-5"
                >
                  Assign User
                </DialogTitle>

                <select
                  v-model="selected_user"
                  class="my-auto px-5 rounded-full bg-green-200 text-green-600 text-sm border-0 focus:outline-none focus:ring-0 py-1 text-center"
                >
                  <option v-for="user in users" :key="user.email">
                    {{ user.email }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                @click="
                  (this.result.is_confirming = true),
                    (this.result.assigned_user = this.selected_user),
                    userAction(this.result),
                    (open = false)
                "
              >
                Assign User
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                @click="
                  (this.result.is_confirming = false),
                    userAction(this.result),
                    (open = false)
                "
                ref="cancelButtonRef"
              >
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import {
  ExclamationIcon,
  CogIcon,
  SwitchHorizontalIcon,
  UsersIcon,
  UserIcon,
  ChevronDownIcon,
} from '@heroicons/vue/outline';

export default {
  props: {
    core_account_name: { required: true, type: String },
    already_selected_user: { required: false, type: String },
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    SwitchHorizontalIcon,
    CogIcon,
    UsersIcon,
    UserIcon,
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  data() {
    return {
      users: [],
      selected_user: null,
      result: {
        is_confirming: false,
        assigned_user: null,
      },
    };
  },
  methods: {
    userAction(result) {
      this.$emit('user_action', this.result);
    },
    assignUser(user) {
      this.selected_user = user.email;
    },
  },
  created() {
    this.selected_user = this.already_selected_user;
    axios
      .get('/users?core_account_name=' + this.core_account_name)
      .then((res) => {
        this.users = res.data.data;
      })
      .catch((error) => {});
  },

  setup() {
    const open = ref(true);

    return {
      open,
    };
  },
};
</script>
