<template>
  <div
    class="flex min-w-max max-w-min rounded-lg"
    :class="[
      size == 'small' ? 'border-1 p-1 bg-white' : 'border-2 p-2 bg-gray-400',
    ]"
  >
    <div
      class="font-serif mt-1 oldstyle-nums text-gray-800 sm:mt-0 border-2 drop-shadow-xl shadow-inner rounded p-1 m-1 bg-gradient-to-b from-gray-300 via-white to-gray-300"
      :class="[size == 'small' ? 'text-sm' : 'text-2xl']"
      v-for="digit in digit_array"
      :key="digit"
    >
      {{ digit }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: { required: true, type: String },
    size: { required: false, type: String },
    theme: { required: false, type: String },
  },

  components: {},
  data() {
    return {
      digit_array: ['0'],
    };
  },
  created() {
    let digit_string = this.number.toString();
    for (let i = 0; i < digit_string.length; i++) {
      this.digit_array.push(digit_string[i]);
    }
  },
};
</script>
