<template>
  <div class="ml-5 mt-5 text-lg font-medium text-gray-700">
    Temperature Report
  </div>
  <div class="ml-5 mb-10 text-sm font-sm text-gray-500">
    Report for all devices of type [Temperature]
  </div>
  <div class="bg-gray-100 mt-2 mx-2">
    <div
      class="filter flex shadow rounded bg-white py-2 border border-transparent"
    >
      <div class="flex justify-start mr-5 mb-2 max-h-1">
        <Menu
          as="div"
          class="relative inline-block self-start ml-2 text-left w-36"
        >
          <div>
            <MenuButton
              class="inline-flex justify-center w-full rounded-md border border-gray-200 shadow-sm ml-2 px-1 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            >
              Timeframe ({{ timeframe }})
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="z-40 origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem
                  v-for="time in timeframe_dropdown"
                  :key="time"
                  v-on:click="
                    loadGraphs(time, this.latest_search_string),
                      (this.timeframe = time)
                  "
                >
                  <div class="bg-white text-gray-900 block px-4 py-2 text-sm">
                    {{ time }}
                  </div>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <div class="flex h-10 w-full bg-white border border-transparent ml-0">
        <div
          class="relative w-full text-gray-400 border border-transparent focus-within:text-gray-600 ml-0"
        >
          <div
            class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
          >
            <SearchIcon class="h-5 w-5" aria-hidden="true" />
          </div>
          <input
            class="block w-full h-full pl-8 pr-3 py-1 border border-transparent text-gray-700 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
            placeholder="Search"
            type="search"
            v-on:input="inputSearch"
          />
        </div>
      </div>

      <Menu
        as="div"
        class="z-20 justify-self-end text-left border border-transparent mr-0 min-w-max"
      >
        <MenuButton
          class="flex w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-sm font-sm text-gray-500 hover:bg-gray-50 focus:outline-none focus:placeholder-none focus:ring-0 focus:border-transparent"
        >
          Filter by Tag
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="group origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
          >
            <div v-for="tag_group in account_tag_groups" :key="tag_group">
              <div class="px-4 py-3 text-indigo-700 bg-indigo-100">
                <p class="text-sm font-sm truncate">
                  {{ tag_group.name }}
                </p>
              </div>
              <div class="py-1">
                <MenuItem v-for="tag in tag_group.tags" :key="tag">
                  <div
                    class="bg-white text-gray-700 block px-4 py-2 text-sm hover:bg-gray-400 hover:text-white"
                    v-on:click="(loading = true), addTagAndLoadGraphs(tag)"
                  >
                    {{ tag }}
                  </div>
                </MenuItem>
              </div>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>

    <div class="grid grid-cols-2 mb-10">
      <!-- first col for timeframe -->
      <div></div>

      <!-- second col for search tags -->
      <div class="grid justify-items-stretch">
        <div
          class="flex flex-wrap border border-transparent justify-self-end mr-2 mt-1"
        >
          <div
            v-for="tag in search_tags"
            :key="tag"
            v-on:click="(loading = true), removeTagAndLoadGraphs(tag)"
            class="flex mt-1 mr-1 px-1 max-w-min border border-transparent text-sm leading-4 rounded-md text-indigo-700 bg-indigo-100"
          >
            <div>
              {{ tag }}
            </div>
            <div><XIcon class="h-3 w-3 ml-1" aria-hidden="true" /></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="flex overflow-hidden bg-gray-100 justify-center">
      <div><loading-widget /></div>
    </div>
    <div class="text-center mt-20" v-if="no_results">
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        No Temperature Devices matched your query
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Modify your query or create a new device
      </p>
      <div class="mt-6">
        <button
          type="button"
          v-on:click="openScan"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Device
        </button>
      </div>
    </div>

    <div v-for="chart in dataset" :key="chart.core_device_id">
      <div class="filter drop-shadow mt-2 mb-2 border bg-white rounded-md">
        <apexchart
          width="100%"
          height="300"
          :options="chart.options"
          :series="chart.series"
        ></apexchart>
      </div>
    </div>
    <server-response :response="serverResponse"></server-response>
  </div>
</template>

<script>
import axios from 'axios';
import ServerResponse from '../../components/ServerResponse';
import LoadingWidget from '../../components/LoadingWidget.vue';
import { PlusIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { SearchIcon } from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    ServerResponse,
    LoadingWidget,
    PlusIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    SearchIcon,
    XIcon,
  },
  data() {
    return {
      core_device_ids: [],
      dataset: [],
      loading: false,
      date: new Date(),
      no_results: false,
      timeframe: '3d',
      latest_search_string: '',
      account_tag_groups: {},
      search_string: '',
      search_tags: [],
      timeframe_dropdown: ['1h', '6h', '1d', '3d', '7d', '30d'],
      limit: 20, //let's just hard code this in for now - need TODO pagination
      product_name: 'core-01-868-temp-sensor',
    };
  },
  methods: {
    openScan() {
      this.$router.push('/scan');
    },
    // makeHTTPRequest(url) {
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       console.log(res.data);
    //       this.devices = res.data.data;
    //       this.serverResponse = JSON.stringify(res, null, 2);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.serverResponse = JSON.stringify(error, null, 2);
    //       this.raiseErrorAlert(error);
    //     });
    // },
    addTagAndLoadGraphs(tag) {
      this.search_tags.push(tag);
      this.loadGraphs(
        this.timeframe,
        this.latest_search_string,
        this.search_tags
      );
    },
    removeTagAndLoadGraphs(tag) {
      const index = this.search_tags.indexOf(tag);
      if (index > -1) {
        this.search_tags.splice(index, 1);
      }
      this.loadGraphs(
        this.timeframe,
        this.latest_search_string,
        this.search_tags
      );
    },
    inputSearch(event) {
      this.latest_search_string = event.target.value;

      setTimeout(
        function (currentSearchString) {
          //still the same after 2 seconds, then we know the user
          //has stopped typing
          if (currentSearchString == this.latest_search_string) {
            this.loadGraphs(
              this.timeframe,
              currentSearchString,
              this.search_tags
            );
          }
        }.bind(this),
        1000,
        event.target.value
      );
    },
    loadGraphs(timeframe, latest_search_string, search_tags) {
      console.log('latest_seatch_string: ' + latest_search_string);
      console.log('search_tags:' + search_tags);
      console.log('timeframe: ' + timeframe);
      this.loading = true;

      //Let's clear the dataset first
      this.dataset = [];

      //Let's get the data for each node for this customer
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;
      let url =
        '/devices?core_account_name=' +
        currentAccountSession +
        '&core_project_name=' +
        currentProjectSession +
        '&search_string=' +
        latest_search_string +
        '&product_name=' +
        this.product_name +
        '&limit=' +
        this.limit;

      for (let tag of this.search_tags) {
        url = url + '&search_tags=' + tag;
      }
      console.log('url: ' + url);
      axios
        .get(url)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          console.log('res.data.data:');
          console.log(res.data.data);
          let results = res.data.data;
          let counter = 0;
          let number_of_temp_sensors = 0;

          console.log('results.length: ' + results.length);

          if (results.length == 0) {
            this.loading = false;
            this.no_results = true;
          } else {
            this.no_results = false;
          }

          for (let i = 0; i < results.length; i++) {
            //TODO -figure out a better way to do this. Currently, this will only
            //Get a report of all the temperature devices only, even though the door
            //and traffic sensors also give temperature readings

            if (results[i].product_name == 'core-01-868-temp-sensor') {
              number_of_temp_sensors++;

              axios
                .get(
                  '/reports/device?measure_name=temperature_internal&measure_name=temperature_probe&core_device_id=' +
                    results[i].core_device_id +
                    '&timeframe=' +
                    timeframe
                )
                .then((res) => {
                  let set = {
                    core_device_id: results[i].core_device_id,
                    options: {
                      theme: {
                        palette: 'palette2',
                      },

                      chart: {
                        stacked: false,
                        type: 'area',
                        height: 350,
                        zoom: {
                          type: 'x',
                          enabled: true,
                          autoScaleYaxis: true,
                        },
                        toolbar: {
                          autoSelected: 'zoom',
                        },
                      },
                      stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 1,
                        dashArray: 0,
                      },
                      title: {
                        text: results[i].core_device_id,
                        align: 'left',
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: false,
                        style: {
                          fontSize: '14px',
                          fontWeight: 'bold',
                          fontFamily: undefined,
                          color: '#263238',
                        },
                      },
                      subtitle: {
                        text: results[i].description,
                        align: 'left',
                        margin: 10,
                        offsetX: 0,
                        offsetY: 20,
                        floating: false,
                        style: {
                          fontSize: '12px',
                          fontWeight: 'normal',
                          fontFamily: undefined,
                          color: '#9699a2',
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },

                      tooltip: {
                        enabled: true,
                        x: {
                          show: true,
                          format: 'dd MMM HH:mm',
                        },
                      },
                      toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                          download: true,
                          selection: true,
                          zoom: true,
                          zoomin: true,
                          zoomout: true,
                          pan: true,
                          reset:
                            true |
                            '<img src="/static/icons/reset.png" width="20">',
                          customIcons: [],
                        },
                        autoSelected: 'zoom',
                      },
                      xaxis: {
                        type: 'datetime',
                        labels: {
                          datetimeUTC: false,
                        },
                      },
                    },

                    series: res.data.series,
                  };
                  this.dataset.push(set);
                  console.log(res.data.series);

                  //Once the final dataset is finished loading, then let's
                  // turn off the loading image
                  counter++;
                  if (counter == results.length) {
                    this.loading = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              counter++;
            }
          } // End if product_name == 'core-01-868-temp-sensor'
          //this.loading = false;
          if (number_of_temp_sensors == 0) {
            this.no_results = true;
            this.loading = false;
          }
        })
        .finally(() => {
          // this.loading = false;
        })
        .catch((err) => {
          //this.raiseErrorAlert(err);
          console.log('error');
          console.log(err);
        });
    },
    loadPage() {
      console.log('this.$route.query.search: ' + this.$route.query.search);
      if (this.$route.query.search != null) {
        this.latest_search_string = this.$route.query.search;
      }
      this.loadGraphs('3d', this.latest_search_string);

      // Let's get all offical tags for this account
      const currentAccountSession = this.$store.state.account.name;

      axios
        .get('/accounts?account_name=' + currentAccountSession)
        .then((res) => {
          let account = res.data.data[0];
          this.account_tag_groups = account.account_tag_groups;
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    this.loadPage();
  },
};
</script>
