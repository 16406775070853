<template>
  <qr-stream @decode="localDecode">
    <div class="overlay-element"></div>
    <div class="laser"></div>
  </qr-stream>
</template>

<script>
import { QrStream } from 'vue3-qr-reader';

export default {
  components: {
    QrStream,
  },
  methods: {
    localDecode(decode) {
      console.log('localDecode called');

      //We need to force everything to uppercase as some manufacturers stupidly put lowercase in the dev-eui QR codes
      //This screws up the lorawan servers and AWS IOT as everything is in uppercase
      this.$emit('decode', decode.toUpperCase());
    },
  },
  data() {
    return {};
  },
  emits: ['decode'],
};
</script>

<style scoped>
.scanner-container {
  position: relative;
}
.overlay-element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 99%;
  background: rgba(30, 30, 30, 0.5);
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    20% 100%,
    20% 20%,
    80% 20%,
    80% 80%,
    20% 80%,
    20% 100%,
    100% 100%,
    100% 0%
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    20% 100%,
    20% 20%,
    80% 20%,
    80% 80%,
    20% 80%,
    20% 100%,
    100% 100%,
    100% 0%
  );
}
.laser {
  width: 60%;
  margin-left: 20%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 30%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  -webkit-animation: scanning 2s infinite;
  animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
  50% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
  }
}
@keyframes scanning {
  50% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
  }
}
</style>
