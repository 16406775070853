<template>
  <div class="grid justify-center">
    <button
      type="button"
      class="grid justify-center mx-auto w-full border-2 border-gray-400 border-dashed rounded-lg p-6 text-center"
    >
      <div class="justify-self-end">
        <XIcon
          class="h-6 w-6 text-gray-300 hover:text-gray-500 m-3"
          v-on:click="deleteAction()"
        ></XIcon>
      </div>
      <div class="mx-auto grid grid-cols-4 gap-4">
        <div class="grid justify-items-center" v-on:click="addSlackAction()">
          <slack :size="48" fillColor="#6b7280" />
          <div class="text-gray-500">Slack</div>
        </div>
        <div class="grid justify-items-center" v-on:click="addWebhookAction()">
          <webhook :size="48" fillColor="#6b7280" />
          <div class="text-gray-500">Webhook</div>
        </div>
        <div class="grid justify-items-center" v-on:click="addEmailAction()">
          <at :size="48" fillColor="#6b7280" />
          <div class="text-gray-500">Email</div>
        </div>
        <div class="grid justify-items-center" v-on:click="addMqttAction()">
          <apache-kafka :size="48" fillColor="#6b7280" />
          <div class="text-gray-500">MQTT</div>
        </div>
        <div class="grid justify-items-center" v-on:click="addSmsAction()">
          <message-text :size="48" fillColor="#6b7280" />
          <div class="text-gray-500">SMS</div>
        </div>
        <div class="grid justify-items-center" v-on:click="addIncidentAction()">
          <phone-forward :size="48" fillColor="#6b7280" />
          <div class="text-gray-500">Incident</div>
        </div>
        <div class="grid justify-items-center" v-on:click="addCoreAction()">
          <img v-bind:src="'/black_on_transparent.png'" width="48" />
          <div class="text-gray-500">Core Action</div>
        </div>
        <div class="grid justify-items-center" v-on:click="addCoreAction()">
          <img v-bind:src="'/brand_zapier_icon_light.png'" width="48" />
          <div class="text-gray-500">Zapier</div>
        </div>
      </div>

      <span class="flex mt-2 block w-full text-sm font-sm text-gray-400">
        coreThings supports a number of different actions including Webhooks,
        MQTT, email etc. It also includes the ability to send actions to other
        devices on the coreThings network!
      </span>
    </button>
    <DotsVerticalIcon class="mx-auto h-4 w-4 text-gray-400"></DotsVerticalIcon>
  </div>
</template>

<script>
import {
  LightningBoltIcon,
  DotsVerticalIcon,
  PlusCircleIcon,
  XIcon,
} from '@heroicons/vue/outline';
import Webhook from 'vue-material-design-icons/Webhook';
import At from 'vue-material-design-icons/At';
import PhoneForward from 'vue-material-design-icons/PhoneForward';
import ApacheKafka from 'vue-material-design-icons/ApacheKafka';
import MessageText from 'vue-material-design-icons/MessageText';
import Slack from 'vue-material-design-icons/Slack';

export default {
  props: {},
  components: {
    LightningBoltIcon,
    DotsVerticalIcon,
    PlusCircleIcon,
    XIcon,
    Webhook,
    At,
    ApacheKafka,
    MessageText,
    PhoneForward,
    Slack,
  },
  data() {
    return {};
  },
  methods: {
    deleteAction() {
      this.$emit('clicked', 'delete_action');
    },
    addSlackkAction() {
      this.$emit('slack_clicked', 'slack_action');
    },
    addWebhookAction() {
      this.$emit('webhook_clicked', 'webhook_action');
    },
    addCoreAction() {
      this.$emit('core_clicked', 'core_action');
    },
    addEmailAction() {
      this.$emit('email_clicked', 'email_action');
    },
    addIncidentAction() {
      this.$emit('incident_clicked', 'incident_action');
    },
    addMqttAction() {
      this.$emit('mqtt_clicked', 'mqtt_action');
    },
    addSmsAction() {
      this.$emit('sms_clicked', 'sms_action');
    },
  },
};
</script>
