<template>
  <div>
    <div class="max-w-7xl mx-auto px-0 sm:px-0 md:px-0">
      <!-- Replace with your content -->
      <div class="py-0" height="800">
        <iframe
          width="100%"
          height="2000"
          src="https://freshpet.corethings.io/d/ju4rtSsMz/corethings-console?orgId=1&kiosk=tv"
        ></iframe>
      </div>
      <!-- /End replace -->
    </div>
  </div>
  <server-response :response="serverResponse"></server-response>
</template>

<script></script>
