<template>
  <div class="py-6 px-6 mb-36">
    <div>
      <nav class="sm:hidden" aria-label="Back">
        <a
          href="#"
          class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon
            class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Back
        </a>
      </nav>
      <nav class="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex">
              <router-link
                :to="'/control-center'"
                class="text-sm font-medium text-gray-500 hover:text-gray-700"
                >Control Center</router-link
              >
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <div class="flex">
                <router-link
                  :to="'/control-center/rules'"
                  class="text-sm font-medium text-gray-500 hover:text-gray-700"
                  >Rules</router-link
                >
              </div>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <div
                v-if="creating_new_rule"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                New Rule
              </div>
              <div
                v-else
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {{ rule.rule_name }}
              </div>
            </div>
          </li>
        </ol>
      </nav>
    </div>

    <div class="w-full flex justify-items-stretch">
      <input
        class="filter w-1/2 px-10 py-2 my-5 text-sm font-small rounded-md border-transparent drop-shadow text-gray-500 placeholder-gray-400 focus:outline-none focus:placeholder-gray-300 focus:ring-0 sm:text-md"
        placeholder="Rule Name"
        v-model="new_rule_name"
        v-if="creating_new_rule"
      />
      <input
        v-else
        class="filter w-1/2 px-10 py-2 my-5 text-sm font-small rounded-md border-transparent drop-shadow text-gray-500 placeholder-gray-400 focus:outline-none focus:placeholder-gray-300 focus:ring-0 sm:text-md"
        placeholder="Rule Name"
        v-model="rule.rule_name"
      />
      <div v-if="creating_new_rule">
        <select
          id="new_rule_type"
          name="new_rule_type"
          v-model="new_rule_type"
          class="mt-5 mx-2 mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          <option selected>device</option>
          <option>gateway</option>
        </select>
      </div>
      <div class="justify-self-end mr-10 my-5 mx-5">
        <button
          v-if="creating_new_rule"
          type="button"
          class="inline-flex items-center px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
          v-on:click="start"
        >
          Start
        </button>
        <div v-else>
          <button
            type="button"
            class="mr-5 inline-flex items-center px-3 py-2 border border-gray text-xs leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
            v-on:click="editRuleName"
          >
            Edit Name
          </button>
          <button
            type="button"
            class="inline-flex items-center px-3 py-2 border border-gray text-xs leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
            v-on:click="deleteRule"
          >
            Delete Rule
          </button>
        </div>
      </div>
    </div>
    <div v-if="!creating_new_rule">
      <div class="bg-white mt-5 mx-0 border rounded-lg p-5">
        <div class="text-lg font-medium text-gray-500">Conditions</div>
        <div class="text-sm font-sm text-gray-400">
          If these conditions on the platform are met when a datapoint flows
          through
        </div>
        <div class="grid justify-center mt-10">
          <plus-extension v-on:click="addCondition()"></plus-extension>

          <div v-for="condition in rule.conditions" :key="condition">
            <condition-gateway
              v-if="condition.gateway"
              :attribute="condition.gateway.attribute"
              :operand="condition.gateway.operand"
              :value="condition.gateway.value"
              :condition_id="condition._id"
              @gateway_condition_updated="gatewayConditionUpdated($event)"
              @gateway_condition_deleted="conditionDeleted($event)"
            >
            </condition-gateway>
            <condition-device
              v-else-if="condition.device"
              :attribute="condition.device.attribute"
              :operand="condition.device.operand"
              :value="condition.device.value"
              :condition_id="condition._id"
              @device_condition_updated="deviceConditionUpdated($event)"
              @device_condition_deleted="conditionDeleted($event)"
            ></condition-device>

            <condition-empty
              v-else-if="isEmpty(condition)"
              :rule_type="rule.rule_type"
              @clicked="removeCondition(condition)"
              @device_clicked="addDeviceCondition(condition)"
              @gateway_clicked="addGatewayCondition(condition)"
            ></condition-empty>
            <plus-extension v-on:click="addCondition()"></plus-extension>
          </div>
        </div>
      </div>

      <div class="bg-white mt-5 mx-0 border rounded-lg p-5">
        <div class="text-lg font-medium text-gray-500">Actions</div>
        <div class="text-sm font-sm text-gray-400">
          What actions to take if the above conditions are met.
        </div>
        <div class="grid justify-center mt-10">
          <plus-extension v-on:click="addAction()"></plus-extension>
          <div v-for="action in rule.actions" :key="action">
            <action-webhook
              v-if="action.webhook"
              :url="action.webhook.url"
              :value="action.webhook.value"
              :action_id="action._id"
              @action_updated="webhookActionUpdated($event)"
              @action_deleted="webhookActionDeleted($event)"
            >
            </action-webhook>
            <action-incident
              v-else-if="action.incident"
              :provider="action.incident.provider"
              :name="action.incident.name"
              :api_key="action.incident.api_key"
              :routing_key="action.incident.routing_key"
            ></action-incident>
            <action-email
              v-else-if="action.email"
              :email="action.email.email"
              :message="action.email.message"
            ></action-email>
            <action-sms
              v-else-if="action.sms"
              :number="action.sms.number"
              :message="action.sms.message"
            ></action-sms>
            <action-mqtt
              v-else-if="action.mqtt"
              :topic="action.mqtt.topic"
              :message="action.mqtt.message"
            ></action-mqtt>
            <action-core-action
              v-else-if="action.core_action"
              :core_device_id="action.core_action.core_device_id"
              :value="action.core_action.value"
            ></action-core-action>
            <action-empty
              v-else-if="isEmpty(action)"
              @clicked="removeAction(action)"
              @webhook_clicked="addWebhookAction(action)"
              @core_clicked="addCoreAction()"
              @email_clicked="addEmailAction()"
              @mqtt_clicked="addMqttAction()"
              @sms_clicked="addSmsAction()"
              @incident_clicked="addIncidentAction()"
            ></action-empty>
            <plus-extension v-on:click="addAction()"></plus-extension>
          </div>
        </div>
      </div>

      <div class="bg-white mt-5 mx-0 border rounded-lg p-5">
        <div class="text-lg font-medium text-gray-500">Duration</div>
        <div class="text-sm font-sm text-gray-400">
          Do you want to action this rule directly after the condition is met or
          wait a period of time before actioning
        </div>

        <div class="grid justify-center mt-10">
          <plus-extension
            v-if="!rule.duration"
            v-on:click="addDurationElement()"
          ></plus-extension>
          <duration-element
            v-if="rule.duration"
            :duration="rule.duration"
            @duration_element_updated="durationUpdated($event)"
            @duration_element_deleted="durationDeleted()"
          ></duration-element>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ServerResponse from '../../components/ServerResponse';
import { SearchIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon, ChartBarIcon } from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import ConditionEmpty from '../../components/rules/ConditionEmpty.vue';
import ActionEmpty from '../../components/rules/ActionEmpty.vue';
import ConditionChooseType from '../../components/rules/ConditionChooseType.vue';
import ConditionDevice from '../../components/rules/ConditionDevice.vue';
import ConditionGateway from '../../components/rules/ConditionGateway.vue';
import DurationElement from '../../components/rules/DurationElement.vue';
import PlusExtension from '../../components/rules/PlusExtension.vue';
import PlusAction from '../../components/rules/PlusAction.vue';
import ActionCoreAction from '../../components/rules/ActionCoreAction.vue';
import ActionEmail from '../../components/rules/ActionEmail.vue';
import ActionIncident from '../../components/rules/ActionIncident.vue';
import ActionMqtt from '../../components/rules/ActionMqtt.vue';
import ActionSms from '../../components/rules/ActionSms.vue';
import ActionWebhook from '../../components/rules/ActionWebhook.vue';

export default {
  components: {
    ServerResponse,
    SearchIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChartBarIcon,
    XIcon,
    ConditionEmpty,
    ActionEmpty,
    ConditionChooseType,
    ConditionDevice,
    ConditionGateway,
    DurationElement,
    PlusExtension,
    ActionCoreAction,
    ActionIncident,
    ActionEmail,
    ActionMqtt,
    ActionSms,
    ActionWebhook,
  },

  setup() {
    return {};
  },

  data() {
    return {
      serverResponse: '',
      rule: { conditions: [], actions: [], duration: 0 },
      creating_new_rule: true,
      new_rule_type: 'device',
      new_rule_name: '',
    };
  },
  methods: {
    start() {
      //Let's create an empty rule
      axios
        .post('/rules', {
          rule_name: this.new_rule_name,
          live: true,
          core_account_name: this.$store.state.account.name,
          core_project_name: this.$store.state.project.name,
          conditions: [],
          actions: [],
          duration: 0,
          rule_type: this.new_rule_type,
        })
        .then((res) => {
          this.rule = res.data.data;
          console.log('new rule created: ');
          console.log(this.rule);
          this.creating_new_rule = false;
        })
        .catch();
    },
    deleteRule() {
      axios
        .delete('/rules/' + this.rule._id)
        .then((res) => {
          console.log(res);
          this.$router.push('/control-center/rules');
        })
        .catch();
    },
    addCondition() {
      console.log('addCondition called!');
      this.rule.conditions.push({});
      console.log(this.rule);
    },

    removeCondition(conditionToRemove) {
      for (let i = 0; i < this.rule.conditions.length; i++) {
        if (conditionToRemove == this.rule.conditions[i]) {
          this.rule.conditions.splice(i, 1);
        }
      }
    },
    addAction() {
      console.log('addAction called!');
      this.rule.actions.push({});
      console.log(this.rule);
    },
    editRuleName() {
      axios
        .put('/rules/' + this.rule._id, this.rule)
        .then()
        .catch();
    },

    addEmailAction() {
      console.log('Add Email Action');
    },
    addCoreAction() {
      console.log('Add Core Action');
    },
    addIncidentAction() {
      console.log('Add Incident Action');
    },
    addMqttAction() {
      console.log('Add Mqtt Action');
    },
    addSmsAction() {
      console.log('Add Sms Action');
    },

    addGatewayCondition(condition) {
      console.log('add Gateway Condition');
      condition.gateway = { attribute: '', operand: '=', value: '' };
    },
    addDurationElement() {
      console.log('add Duration element');
      this.rule.duration = 5;
      //Let's go ahead and just add this completely
      axios
        .put('/rules/' + this.rule._id, this.rule)
        .then((res) => {
          this.rule = res.data.data;
          console.log(this.rule);
        })
        .catch();
    },
    addWebhookAction(action) {
      console.log('Add Webhook Action');
      console.log(action);
      action.webhook = { url: '', value: '' };
    },

    addDeviceCondition(condition) {
      console.log('add Device Condition');
      condition.device = { attribute: '', operand: '=', value: '' };
    },

    webhookActionUpdated(action) {
      console.log('action to be updated');
      console.log(action);

      for (let i = 0; i < this.rule.actions.length; i++) {
        if (this.rule.actions[i].webhook) {
          console.log('found existing webhook: ');
          console.log(this.rule.actions[i]);
          if (
            this.rule.actions[i].webhook.url == '' &&
            this.rule.actions[i].webhook.value == ''
          ) {
            this.rule.actions.splice(i, 1);
          }

          if (
            action.webhook.action_id &&
            this.rule.actions[i]._id == action.webhook.action_id
          ) {
            //We are updating an existing rule
            this.rule.actions.splice(i, 1);
          }
        }
      }

      this.rule.actions.push(action);

      console.log(this.rule);

      axios
        .put('/rules/' + this.rule._id, this.rule)
        .then()
        .catch();
    },
    deviceConditionUpdated(condition) {
      console.log('condition to be updated');
      console.log(condition);

      for (let i = 0; i < this.rule.conditions.length; i++) {
        if (this.rule.conditions[i].device) {
          console.log('found existing device condition: ');
          console.log(this.rule.conditions[i]);
          if (
            this.rule.conditions[i].device.attribute == '' ||
            this.rule.conditions[i].device.value == ''
          ) {
            this.rule.conditions.splice(i, 1);
          } else {
            console.log(
              `Checking ${this.rule.conditions[i]._id} versus ${condition.device.condition_id}`
            );
            if (
              condition.device.condition_id &&
              this.rule.conditions[i]._id == condition.device.condition_id
            ) {
              //We are updating an existing rule
              console.log('Looks like we are updating an existing rule!!!');
              this.rule.conditions.splice(i, 1);
            }
          }
        }
      }

      this.rule.conditions.push(condition);

      console.log(this.rule);

      axios
        .put('/rules/' + this.rule._id, this.rule)
        .then()
        .catch();
    },
    gatewayConditionUpdated(condition) {
      console.log('condition to be updated');
      console.log(condition);

      for (let i = 0; i < this.rule.conditions.length; i++) {
        if (this.rule.conditions[i].device) {
          console.log('found existing device condition: ');
          console.log(this.rule.conditions[i]);
          if (
            this.rule.conditions[i].gateway.attribute == '' ||
            this.rule.conditions[i].gateway.value == ''
          ) {
            this.rule.conditions.splice(i, 1);
          } else {
            console.log(
              `Checking ${this.rule.conditions[i]._id} versus ${condition.gateway.condition_id}`
            );
            if (
              condition.gateway.condition_id &&
              this.rule.conditions[i]._id == condition.gateway.condition_id
            ) {
              //We are updating an existing rule
              console.log('Looks like we are updating an existing rule!!!');
              this.rule.conditions.splice(i, 1);
            }
          }
        }
      }

      this.rule.conditions.push(condition);

      console.log(this.rule);

      axios
        .put('/rules/' + this.rule._id, this.rule)
        .then()
        .catch();
    },
    durationUpdated(duration) {
      console.log('duration to be updated');
      console.log(duration);

      this.rule.duration = duration;
      console.log(this.rule);

      axios
        .put('/rules/' + this.rule._id, this.rule)
        .then()
        .catch();
    },

    durationDeleted() {
      console.log('deleting duration element');
      console.log('before deleting');
      console.log(this.rule);

      if (this.rule.duration) {
        console.log('found this.rule.duration');
        this.rule.duration = 0;
      }
      console.log('after deleting');
      console.log(this.rule);

      axios
        .put('/rules/' + this.rule._id, this.rule)
        .then()
        .catch();
    },

    conditionDeleted(condition_id) {
      console.log('condition deleted called');
      console.log(condition_id);

      //Let's remove the old empty condition
      console.log('this.rule.conditions.length');
      console.log(this.rule.conditions.length);

      for (let i = 0; i < this.rule.conditions.length; i++) {
        if (this.rule.conditions[i]._id == condition_id) {
          this.rule.conditions.splice(i, 1);
        }
      }

      console.log(this.rule);

      axios
        .put('/rules/' + this.rule._id, this.rule)
        .then()
        .catch();
    },

    webhookActionDeleted(action_id) {
      console.log('action deleted');
      console.log(action_id);

      //Let's remove the old empty action
      console.log('this.rule.actions.length');
      console.log(this.rule.actions.length);

      for (let i = 0; i < this.rule.actions.length; i++) {
        if (this.rule.actions[i]._id == action_id) {
          this.rule.actions.splice(i, 1);
        }
      }

      console.log(this.rule);

      axios
        .put('/rules/' + this.rule._id, this.rule)
        .then()
        .catch();
    },

    removeAction(actionToRemove) {
      for (let i = 0; i < this.rule.actions.length; i++) {
        if (actionToRemove == this.rule.actions[i]) {
          this.rule.actions.splice(i, 1);
        }
      }
    },

    isEmpty(obj) {
      console.log('obj:');
      console.log(obj);
      if (!obj) {
        return true;
      }
      return Object.keys(obj).length === 0;
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },

    loadPage() {
      if (this.$route.path === '/control-center/rules/new') {
        // this.rule = { conditions: [], actions: [] };
        console.log('new rule: ');
        console.log(this.rule);
        this.creating_new_rule = true;
        //  this.addCondition();
        // this.addAction();
      } else {
        this.creating_new_rule = false;
        axios
          .get('/rules/' + this.$route.params.id)
          .then((res) => {
            this.serverResponse = JSON.stringify(res, null, 2);
            this.rule = res.data.data;
            console.log(this.rule);
          })
          .catch((err) => {
            this.raiseErrorAlert(err);
            this.serverResponse = JSON.stringify(err.response, null, 2);
          });
      }
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    console.log('RuleDetail created called!!');
    this.loadPage();
  },
};
</script>
