<template>
  <div class="justify-center w-full">
    <span class="grid w-full">
      <button
        type="button"
        class="flex mx-auto w-3/4 border-2 border-gray-400 border-dashed rounded-lg p-6 bg-white"
      >
        <div>
          <at :size="48" fillColor="#475569" />
        </div>
        <div class="flex flex-wrap w-full text-sm font-sm pl-5 font-mono">
          <div class="text-indigo-500">{{ value }}</div>
          <div class="px-10">=></div>
          <div class="text-red-500">{{ email }}</div>
        </div>
        <div class="flex justify-end p-2 w-48">
          <router-link :to="''">
            <button
              type="button"
              class="inline-flex items-center px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
            >
              Edit
            </button>
          </router-link>
        </div>
      </button>
    </span>
    <DotsVerticalIcon class="mx-auto h-4 w-4 text-gray-400"></DotsVerticalIcon>
  </div>
</template>

<script>
import {
  FilterIcon,
  DotsVerticalIcon,
  PlusCircleIcon,
} from '@heroicons/vue/outline';
import At from 'vue-material-design-icons/At';
export default {
  props: {
    email: { required: true, type: String },
    message: { required: true, type: String },
  },
  components: {
    FilterIcon,
    DotsVerticalIcon,
    PlusCircleIcon,
    At,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
