<template>
  <div class="grid justify-center">
    <button
      type="button"
      class="grid justify-center mx-auto w-1/2 border-2 border-gray-400 border-dashed rounded-lg p-0 text-center"
    >
      <div class="justify-self-end">
        <XIcon
          class="h-6 w-6 text-gray-300 hover:text-gray-500 m-3"
          v-on:click="deleteCondition()"
        ></XIcon>
      </div>
      <div class="text-medium font-sm text-gray-500 pb-10">
        Click on the type of condition
      </div>
      <div class="mx-auto flex gap-10">
        <div
          class="grid justify-items-center"
          v-if="rule_type == 'device'"
          v-on:click="addDeviceCondition()"
        >
          <LightBulbIcon class="flex mx-auto h-12 w-12 text-gray-500" />
          <div class="text-gray-500">Device</div>
        </div>
        <div
          class="grid justify-items-center"
          v-if="rule_type == 'gateway'"
          v-on:click="addGatewayCondition()"
        >
          <WifiIcon class="flex mx-auto h-12 w-12 text-gray-500" />
          <div class="text-gray-500">Gateway</div>
        </div>
      </div>
    </button>
    <DotsVerticalIcon class="mx-auto h-4 w-4 text-gray-400"></DotsVerticalIcon>
  </div>
</template>

<script>
import {
  FilterIcon,
  DotsVerticalIcon,
  PlusCircleIcon,
  XIcon,
  LightBulbIcon,
  WifiIcon,
} from '@heroicons/vue/outline';

export default {
  props: {
    rule_type: { required: true, type: String },
  },
  components: {
    FilterIcon,
    DotsVerticalIcon,
    PlusCircleIcon,
    XIcon,
    LightBulbIcon,
    WifiIcon,
  },
  data() {
    return {};
  },
  methods: {
    deleteCondition() {
      this.$emit('clicked', 'delete_condition');
    },
    addDeviceCondition() {
      this.$emit('device_clicked', 'device_condition');
    },
    addGatewayCondition() {
      this.$emit('gateway_clicked', 'gateway_condition');
    },
  },
};
</script>
