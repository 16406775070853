<template>
  <div class="ml-5 mt-10 text-lg font-medium text-gray-500">
    Project Gateways
  </div>
  <div class="bg-white mt-5 mx-5 border rounded-lg">
    <div class="flex border border-transparent pt-5">
      <Menu
        as="div"
        class="filter z-10 w-48 min-w-max justify-self-start text-left drop-shadow rounded-md ml-2"
      >
        <MenuButton
          class="flex rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-sm font-sm text-gray-500 hover:bg-gray-50 focus:outline-none focus:placeholder-none focus:ring-0 focus:border-transparent"
        >
          Filter by Tag
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="origin-top-left absolute left-8 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
          >
            <div v-for="tag_group in account_tag_groups" :key="tag_group">
              <div class="px-4 py-3 text-indigo-700 bg-indigo-100">
                <p class="text-sm font-sm truncate">
                  {{ tag_group.name }}
                </p>
              </div>
              <div class="py-1">
                <MenuItem v-for="tag in tag_group.tags" :key="tag">
                  <div
                    class="bg-white text-gray-700 block px-4 py-2 text-sm"
                    v-on:click="addTagAndSearch(tag)"
                  >
                    {{ tag }}
                  </div>
                </MenuItem>
              </div>
            </div>
          </MenuItems>
        </transition>
      </Menu>

      <div
        class="relative w-full text-gray-400 focus-within:text-gray-600 ml-0 h-10"
      >
        <div
          class="absolute z-10 inset-y-3 left-3 flex items-center pointer-events-none"
        >
          <SearchIcon class="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          class="filter w-1/2 px-10 py-2 text-sm font-small rounded-md border-transparent drop-shadow text-gray-500 placeholder-gray-400 focus:outline-none focus:placeholder-gray-300 focus:ring-0 sm:text-md"
          placeholder="Search across all projects"
          type="search"
          v-on:input="inputSearch"
        />
      </div>
      <div class="flex justify-end p-2 mr-4 w-48">
        <router-link :to="'/scan?tab=installProduct'">
          <button
            type="button"
            class="inline-flex items-center px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
          >
            Install Gateway
          </button>
        </router-link>
      </div>
    </div>
    <div class="grid justify-items-stretch">
      <div class="flex border border-transparent justify-self-start ml-2 mt-1">
        <div
          v-for="tag in search_tags"
          :key="tag"
          v-on:click="removeTagAndSearch(tag)"
          class="flex mt-1 mr-1 px-1 w-auto border border-transparent text-xs leading-4 rounded-md text-indigo-700 bg-indigo-100"
        >
          <div>
            {{ tag }}
          </div>
          <div><XIcon class="h-3 w-3 ml-1" aria-hidden="true" /></div>
        </div>
      </div>
    </div>
    <div class="justify-self-start ml-6 mt-1">
      <button
        type="button"
        class="inline-flex items-center px-3 py-2 border border-gray text-xs leading-4 font-medium rounded-md bg-white"
        v-bind:class="{
          'text-indigo-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100':
            selectedGateways.length > 0,
          'text-indigo-200': selectedGateways.length == 0,
        }"
        v-on:click="requestMoveGateway = true"
      >
        Move Gateway
      </button>
    </div>
    <move-device
      v-if="requestMoveGateway"
      :title_text="'Move Gateway to new project'"
      :message_text="'Choose project you would like to move the gateway to'"
      :confirm_button_text="'Move Gateway'"
      :core_account_name="$store.state.account.name"
      v-on:user_action="moveGateway($event)"
    ></move-device>

    <div class="py-2 px-1 mx-5">
      <!-- <div class="flex justify-end p-2">
      <router-link :to="'/scan?tab=installProduct'">
        <button
          type="button"
          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add Gateway
        </button>
      </router-link>
    </div> -->

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input
                            type="checkbox"
                            class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-50 sm:left-6"
                            :checked="
                              indeterminate ||
                              selectedGateways.length === gateways.length
                            "
                            :indeterminate="indeterminate"
                            @change="
                              selectedGateways = $event.target.checked
                                ? gateways.map((p) => p._id)
                                : []
                            "
                          />
                        </div>
                      </div>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Gateway ID
                    </th>
                    <!-- <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      ICCID
                    </th> -->
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Project
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Tags
                    </th>
                    <!-- <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Gateway EUI
                  </th> -->

                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Live
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Seen
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(gateway, gatewayIdx) in gateways"
                    :key="gateway.gateway_eui"
                    :class="gatewayIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input
                            type="checkbox"
                            class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-white sm:left-6"
                            :value="gateway._id"
                            v-model="selectedGateways"
                          />
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs font-medium text-gray-900 flex"
                    >
                      <router-link :to="'/gateways/' + gateway._id">{{
                        gateway.core_gateway_id
                      }}</router-link>
                      <div v-if="gateway.hostname && account_role == 'admin'">
                        <a
                          :href="
                            'https://app.remote.it/#devices/' + gateway.hostname
                          "
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-3 w-3 stroke-current text-purple-600 ml-1"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0"
                            />
                          </svg>
                        </a>
                      </div>
                    </td>
                    <!-- <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      {{ gateway.sim_iccid }}
                    </td> -->
                    <td
                      class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      {{ gateway.core_project_name }}
                    </td>

                    <td
                      class="px-6 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      {{ gateway.description }}
                    </td>
                    <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ gateway.gateway_eui }}
                  </td> -->
                    <td
                      class="flex px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      <div
                        v-for="tag in gateway.tags"
                        :key="tag"
                        class="mt-1 mr-1 px-1 max-w-min border border-transparent text-xs leading-4 rounded-md text-indigo-700 bg-indigo-100"
                      >
                        {{ tag }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      {{ gateway.live }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-xs text-gray-500"
                    >
                      {{
                        getTimeDiff(
                          dateMath.diff(
                            new Date(gateway.last_seen),
                            new Date(),
                            'minutes',
                            false
                          )
                        )
                      }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <a href="#" class="text-indigo-600 hover:text-indigo-900"
                        >Edit</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <server-response :response="serverResponse"></server-response>
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';
import { SearchIcon, ChevronDownIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import MoveDevice from '../components/MoveDevice.vue';

export default {
  components: {
    ServerResponse,
    AlertBanner,
    SearchIcon,
    XIcon,
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MoveDevice,
  },

  setup() {
    const selectedGateways = ref([]);
    const checked = ref(false);
    const gateways = [];
    const indeterminate = computed(
      () =>
        selectedGateways.value.length > 0 &&
        selectedGateways.value.length < gateways.length
    );

    return {
      gateways,
      selectedGateways,
      checked,
      indeterminate,
    };
  },

  data() {
    return {
      dateMath,
      serverResponse: '',
      latestSearchString: '',
      account_tag_groups: {},
      search_string: '',
      search_tags: [],
      account_role: '',
      requestMoveGateway: false,
    };
  },
  methods: {
    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';
      console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
    makeHTTPRequest(url) {
      axios
        .get(url)
        .then((res) => {
          console.log(res.data);
          this.gateways = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((error) => {
          console.log(error);
          this.serverResponse = JSON.stringify(error, null, 2);
          this.raiseErrorAlert(error);
        });
    },
    addTagAndSearch(tag) {
      this.search_tags.push(tag);
      //console.log('adding search_tag: ' + tag);
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;

      let url =
        '/gateways?core_account_name=' +
        currentAccountSession +
        '&core_project_name=' +
        currentProjectSession +
        '&search_string=' +
        this.latestSearchString;

      for (let tag of this.search_tags) {
        url = url + '&search_tags=' + tag;
      }

      this.makeHTTPRequest(url);
    },
    removeTagAndSearch(tag) {
      const index = this.search_tags.indexOf(tag);
      if (index > -1) {
        this.search_tags.splice(index, 1);
      }
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;

      let url =
        '/gateways?core_account_name=' +
        currentAccountSession +
        '&core_project_name=' +
        currentProjectSession +
        '&search_string=' +
        this.latestSearchString;

      for (let tag of this.search_tags) {
        url = url + '&search_tags=' + tag;
      }

      this.makeHTTPRequest(url);
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },

    inputSearch(event) {
      this.latestSearchString = event.target.value;

      setTimeout(
        function (currentSearchString) {
          //still the same after 2 seconds, then we know the user
          //has stopped typing
          if (currentSearchString == this.latestSearchString) {
            //console.log('Searching: ' + currentSearchString);
            const currentAccountSession = this.$store.state.account.name;
            const currentProjectSession = this.$store.state.project.name;

            let url =
              '/gateways?core_account_name=' +
              currentAccountSession +
              '&core_project_name=' +
              currentProjectSession +
              '&search_string=' +
              currentSearchString;

            for (let tag of this.search_tags) {
              url = url + '&search_tags=' + tag;
            }

            console.log('url: ' + url);
            axios
              .get(url)
              .then((res) => {
                console.log(res.data);
                this.gateways = res.data.data;
                this.serverResponse = JSON.stringify(res, null, 2);
              })
              .catch((error) => {
                console.log(error);
                this.serverResponse = JSON.stringify(error, null, 2);
                this.raiseErrorAlert(error);
              });
          }
        }.bind(this),
        1000,
        event.target.value
      );
    },

    loadPage() {
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;

      this.account_role = this.$store.state.account.role;
      console.log('role: ' + this.account_role);

      this.selectedGateways = [];

      console.log(
        'Making request: ' +
          '/gateways?core_account_name=' +
          currentAccountSession +
          '&core_project_name=' +
          currentProjectSession
      );

      axios
        .get(
          '/gateways?core_account_name=' +
            currentAccountSession +
            '&core_project_name=' +
            currentProjectSession
        )
        .then((res) => {
          console.log(res.data);
          this.gateways = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((error) => {
          console.log(error);
          this.serverResponse = JSON.stringify(error, null, 2);
          this.raiseErrorAlert(error);
        });

      // Let's get all offical tags for this account
      axios
        .get('/accounts?account_name=' + currentAccountSession)
        .then((res) => {
          let account = res.data.data[0];
          this.account_tag_groups = account.account_tag_groups;
        })
        .catch((err) => {
          // this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    moveGateway(result) {
      this.requestMoveGateway = false;
      console.log('selectedGateways: ');
      console.log(this.selectedGateways);

      if (result.is_confirming) {
        for (const gateway_id of this.selectedGateways) {
          const updated_gateway = this.gateways.find(
            (gateway) => gateway._id == gateway_id
          );
          updated_gateway.core_project_name = result.new_project;

          axios
            .put('/gateways/' + gateway_id, updated_gateway)
            .then((res) => {
              this.serverResponse = JSON.stringify(res, null, 2);
              this.loadPage();
            })
            .catch((err) => {
              this.raiseErrorAlert(err);
              this.serverResponse = JSON.stringify(err.response, null, 2);
            });
        }
      }
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    this.loadPage();
  },

  unmounted() {
    this.$store.state.userAlert.visible = false;
  },
};
</script>
