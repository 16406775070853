<template>
  <div class="px-5 py-0">
    <!-- <div v-if="loading" class="flex overflow-hidden bg-gray-100 justify-left">
      <div><loading-widget /></div>
    </div> -->

    <h3 class="text-lg leading-6 font-medium text-gray-900 py-5">
      Platform Summary
    </h3>

    <h4 class="text-small leading-6 font-medium text-gray-700 py-3">
      Upcoming Stripe Invoice
    </h4>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Due Date
                  </th>
                  <!-- <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Number
                  </th> -->

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Invoice</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.total / 100 }}
                  </td>

                  <td
                    class="mt-3 ml-5 bt-5 px-3 inline-flex text-xs leading-5 rounded-full bg-gray-400 text-gray-100"
                    v-bind:class="{
                      'mt-3 ml-5 bt-5 px-3 text-xs rounded-full bg-green-100 text-gray-800':
                        stripeUpcomingInvoice.status == 'paid',
                      'mt-3 ml-5 px-3 inline-flex text-xs leading-5 rounded-full bg-red-400 text-gray-100':
                        stripeUpcomingInvoice.status == 'open',
                    }"
                  >
                    {{ stripeUpcomingInvoice.status }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ convertToDate(stripeUpcomingInvoice.due_date) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <h4 class="text-small leading-6 font-medium text-gray-700 py-3">
      Past Stripe Invoices
    </h4>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Due Date
                  </th>
                  <!-- <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Number
                  </th> -->

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Invoice</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(invoice, invoiceIdx) in stripePaidInvoices"
                  :key="invoice.number"
                  :class="invoiceIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ getCurrencySymbol(invoice.currency) }}
                    {{ invoice.total / 100 }}
                  </td>

                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="mt-3 ml-5 bt-5 px-3 inline-flex text-xs leading-5 rounded-full bg-gray-400 text-gray-100"
                    v-bind:class="{
                      'mt-3 ml-5 bt-5 px-3 text-xs rounded-full bg-green-100 text-gray-800':
                        invoice.status == 'paid',
                      'mt-3 ml-5 px-3 inline-flex text-xs leading-5 rounded-full bg-red-400 text-gray-100':
                        invoice.status == 'open',
                    }"
                  >
                    {{ convertStatus(invoice.status) }}
                  </td>
                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ convertToDate(invoice.due_date) }}
                  </td>
                  <!-- <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ invoice.number }}
                  </td> -->
                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <a :href="invoice.hosted_invoice_url" target="new">
                      Invoice
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- <h3 class="text-lg leading-6 font-medium text-gray-900 py-5">
      30 Day runrate
    </h3> -->

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-if="revenue_loading"
        class="flex overflow-hidden bg-gray-100 justify-left"
      >
        <inline-loading-widget />
      </div>
      <div
        v-for="item in stats_revenue"
        :key="item.id"
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute  bg-green-50 rounded-md p-3">
            <component
              :is="item.icon"
              class="h-6 w-6 text-green-700"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>

          <p
            :class="[
              item.changeType === 'increase'
                ? 'text-green-600'
                : 'text-red-600',
              'ml-2 flex items-baseline text-sm font-semibold',
            ]"
          >
            <ArrowSmUpIcon
              v-if="item.changeType === 'increase'"
              class="self-center flex-shrink-0 h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <ArrowSmDownIcon
              v-else
              class="self-center flex-shrink-0 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <span class="sr-only">
              {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }}
              by
            </span>
            {{ item.change }}
          </p>

          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all<span class="sr-only"> {{ item.name }} stats</span></a
              >
            </div>
          </div>
        </dd>
      </div>
    </dl>

    <h3 class="text-lg leading-6 font-medium text-gray-900 pt-20">
      Device Stats
    </h3>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="item in stats_devices"
        :key="item.id"
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-purple-50 rounded-md p-3">
            <component
              :is="item.icon"
              class="h-6 w-6 text-purple-700"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>

          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all<span class="sr-only"> {{ item.name }} stats</span></a
              >
            </div>
          </div>
        </dd>
      </div>
    </dl>

    <h3 class="text-lg leading-6 font-medium text-gray-900 pt-20">
      Gateway Stats
    </h3>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="item in stats_gateways"
        :key="item.id"
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-purple-50 rounded-md p-3">
            <component
              :is="item.icon"
              class="h-6 w-6 text-purple-700"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>

          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all<span class="sr-only"> {{ item.name }} stats</span></a
              >
            </div>
          </div>
        </dd>
      </div>
    </dl>

    <h3 class="text-lg leading-6 font-medium text-gray-900 pt-20">
      Data Points Recorded
    </h3>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-if="datapoints_loading"
        class="flex overflow-hidden bg-gray-100 justify-left"
      >
        <inline-loading-widget />
      </div>

      <div
        v-for="item in stats_full"
        :key="item.id"
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-blue-50 rounded-md p-3">
            <component
              :is="item.icon"
              class="h-6 w-6 text-blue-700"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>
          <p
            :class="[
              item.changeType === 'increase'
                ? 'text-green-600'
                : 'text-red-600',
              'ml-2 flex items-baseline text-sm font-semibold',
            ]"
          >
            <ArrowSmUpIcon
              v-if="item.changeType === 'increase'"
              class="self-center flex-shrink-0 h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <ArrowSmDownIcon
              v-else-if="item.changeType === 'decrease'"
              class="self-center flex-shrink-0 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <span class="sr-only">
              {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }}
              by
            </span>
            {{ item.change }}
          </p>

          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all<span class="sr-only"> {{ item.name }} stats</span></a
              >
            </div>
          </div>
        </dd>
      </div>
    </dl>

    <h3 class="text-lg leading-6 font-medium text-gray-900 pt-20">
      Alerts
    </h3>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="item in stats_alerts"
        :key="item.id"
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-purple-50 rounded-md p-3">
            <component
              :is="item.icon"
              class="h-6 w-6 text-purple-700"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>

          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View all<span class="sr-only"> {{ item.name }} stats</span></a
              >
            </div>
          </div>
        </dd>
      </div>
    </dl>
  </div>

  <server-response :response="serverResponse"></server-response>
</template>

<script>
import HomeWidget from '../../components/HomeWidget';
import ServerResponse from '../../components/ServerResponse';
import axios from 'axios';
import moment from 'moment';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid';
import LoadingWidget from '../../components/LoadingWidget.vue';
import InlineLoadingWidget from '../../components/InlineLoadingWidget.vue';
import {
  CursorClickIcon,
  MailOpenIcon,
  UsersIcon,
  CurrencyEuroIcon,
  DatabaseIcon,
  WifiIcon,
  OfficeBuildingIcon,
  LightBulbIcon,
} from '@heroicons/vue/outline';

export default {
  setup() {
    return {};
  },
  components: {
    HomeWidget,
    ServerResponse,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    CurrencyEuroIcon,
    DatabaseIcon,
    WifiIcon,
    OfficeBuildingIcon,
    LightBulbIcon,
    LoadingWidget,
    InlineLoadingWidget,
  },
  data() {
    return {
      serverURL: process.env.VUE_APP_CORETHINGS_API,
      serverResponse: '',
      loading: false,
      revenue_loading: false,
      datapoints_loading: false,
      stats_devices: [],
      stats_gateways: [],
      stats_alerts: [],
      stats_full: [],
      stats_revenue: [],
      devices: [],
      gateways: [],
      last30DayDataPoints: 0,
      previous30DayDataPoints: 0,
      last30DayDistinctDevEuis: 0,
      last30DayDistinctGatewayEuis: 0,
      previous30DayDistinctDevEuis: 0,
      previous30DayDistinctGatewayEuis: 0,
      stripePaidInvoices: [],
      stripeUpcomingInvoice: '',
    };
  },
  methods: {
    getCurrencySymbol(currency) {
      if (currency == 'eur') {
        return '€';
      } else if (currency == 'usd') {
        return '$';
      } else if (currency == 'gbp') {
        return '£';
      }
    },
    convertToDate(timestamp) {
      var day = moment.unix(timestamp); //seconds
      return day.format('MMMM Do YYYY');
    },
    convertStatus(status) {
      if (status == 'open') {
        return 'overdue';
      } else {
        return status;
      }
    },
    getPaidInvoicesFromStripe(account) {
      this.stripePaidInvoices = [];
      console.log(
        'axios request: ' +
          '/proxy/get-paid-invoices-from-stripe?account_name=' +
          account
      );

      axios
        .get('/proxy/get-paid-invoices-from-stripe?account_name=' + account)
        .then((res) => {
          this.stripePaidInvoices = res.data.invoices.data;
          console.log(this.stripePaidInvoices);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
        });
    },
    getUpcomingInvoicesFromStripe(account) {
      this.stripeUpcomingInvoice = '';
      console.log(
        'axios request: ' +
          '/proxy/get-upcoming-invoices-from-stripe?account_name=' +
          account
      );

      axios
        .get('/proxy/get-upcoming-invoices-from-stripe?account_name=' + account)
        .then((res) => {
          this.stripeUpcomingInvoice = res.data.invoices;
          console.log('UPCOMING STRIPE INVOICE');
          console.log(this.stripeUpcomingInvoice);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
        });
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
    abbreviateNumber(number) {
      const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) return number;

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;

      // format number and add suffix
      return scaled.toFixed(1) + suffix;
    },
    loadReport(account) {
      this.stats_devices = [];
      this.stats_gateways = [];
      this.stats_alerts = [];
      this.stats_full = [];
      this.stats_revenue = [];

      const currentAccountSession = account;

      axios
        .get('/devices?core_account_name=' + currentAccountSession)
        .then((res) => {
          // this.stats_devices.push({
          //   name: 'Total Devices Provisioned',
          //   stat: res.data.count,
          //   icon: LightBulbIcon,
          // });

          this.devices = res.data.data;

          //Let's count the devices that are live
          let deviceCount = 0;
          let deviceNotLiveCount = 0;
          let deviceDeadCount = 0;

          for (let device of this.devices) {
            if (device.live == false) {
              deviceNotLiveCount++;
            } else if (device.live && device.battery && device.battery < 2.5) {
              deviceDeadCount++;
            } else {
              deviceCount++;
            }
          }
          this.stats_devices.push({
            name: 'Devices Sending Data',
            stat: deviceCount,
            icon: LightBulbIcon,
          });
          this.stats_devices.push({
            name: 'Devices with Dead Battery',
            stat: deviceDeadCount,
            icon: LightBulbIcon,
          });
          this.stats_devices.push({
            name: 'Devices Turned Off',
            stat: deviceNotLiveCount,
            icon: LightBulbIcon,
          });
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.loading = false;
          //this.serverResponse = JSON.stringify(err.response, null, 2);
        });

      axios
        .get('/gateways?core_account_name=' + currentAccountSession)
        .then((res) => {
          // this.serverResponse = JSON.stringify(res, null, 2);
          // this.stats_gateways.push({
          //   name: 'Total Gateways Provisioned',
          //   stat: res.data.count,
          //   icon: WifiIcon,
          // });

          this.gateways = res.data.data;

          //Let's count the devices that are live
          let gatewayLiveCount = 0;
          let gatewayNotLiveCount = 0;

          for (let gateway of this.gateways) {
            if (gateway.live == true) {
              gatewayLiveCount++;
            } else {
              gatewayNotLiveCount++;
            }
          }

          this.stats_gateways.push({
            name: 'Gateways Sending Data',
            stat: gatewayLiveCount,
            icon: WifiIcon,
          });

          this.stats_gateways.push({
            name: 'Gateways Turned Off',
            stat: gatewayNotLiveCount,
            icon: WifiIcon,
          });
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.loading = false;
          // this.serverResponse = JSON.stringify(err.response, null, 2);
        });

      //this.loading = true;
      this.datapoints_loading = true;

      let queryString =
        'SELECT count(*) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE ' +
        "(core_account_name = '" +
        currentAccountSession +
        "' AND " +
        "measure_name != 'application_id' AND " +
        "measure_name != 'gateway_eui' AND " +
        "measure_name != 'gateway_id' AND " +
        "measure_name != 'dev_eui' AND " +
        "measure_name != 'dev_id') AND " +
        'time between ago(30d) and now()';
      axios
        .post('/reports/query', { query_string: queryString })
        .then((res) => {
          this.last30DayDataPoints = Number(res.data.result[0].COUNT);

          queryString =
            'SELECT count(*) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE ' +
            "core_account_name = '" +
            currentAccountSession +
            "' AND " +
            "(measure_name != 'application_id' AND " +
            "measure_name != 'gateway_eui' AND " +
            "measure_name != 'gateway_id' AND " +
            "measure_name != 'dev_eui' AND " +
            "measure_name != 'dev_id') AND " +
            'time between ago(60d) and ago(30d)';
          axios
            .post('/reports/query', { query_string: queryString })
            .then((res) => {
              this.previous30DayDataPoints = Number(res.data.result[0].COUNT);

              console.log(
                'this.previous30DayDataPoints: ' + this.previous30DayDataPoints
              );
              console.log(
                'this.last30DayDataPoints: ' + this.last30DayDataPoints
              );

              let percentageIncrease =
                ((this.last30DayDataPoints - this.previous30DayDataPoints) /
                  this.previous30DayDataPoints) *
                100;

              let changeType = 'increase';
              if (percentageIncrease < 0) {
                changeType = 'decrease';
              }

              this.stats_full.push({
                name: 'Last 30 days',
                stat: this.abbreviateNumber(this.last30DayDataPoints),
                changeType: changeType,
                change: Math.round(percentageIncrease) + '%',
                icon: DatabaseIcon,
              });
              this.datapoints_loading = false;
            })
            .catch((err) => {
              this.raiseErrorAlert(err);
              this.serverResponse = JSON.stringify(err.response, null, 2);
              this.datapoints_loading = false;
            });
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.datapoints_loading = false;
        });

      queryString =
        'SELECT count(*) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE ' +
        "(core_account_name = '" +
        currentAccountSession +
        "' AND " +
        "measure_name != 'application_id' AND " +
        "measure_name != 'gateway_eui' AND " +
        "measure_name != 'gateway_id' AND " +
        "measure_name != 'dev_eui' AND " +
        "measure_name != 'dev_id') AND " +
        'time between ago(2d) and ago(1d)';
      axios
        .post('/reports/query', { query_string: queryString })
        .then((res) => {
          this.stats_full.push({
            name: 'Today',
            stat: this.abbreviateNumber(Number(res.data.result[0].COUNT)),
            icon: DatabaseIcon,
          });

          //Success in retrieving all datapoints
          this.datapoints_loading = false;
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.datapoints_loading = false;
        });

      //Let's get the revenue numbers
      //Assume for now that each device is 3 Euro per month and each gateway is 4 Euro

      queryString =
        'SELECT approx_distinct(dev_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name = \'' +
        currentAccountSession +
        "' and time between ago(30d) and now()";

      this.revenue_loading = true;

      axios
        .post('/reports/query', { query_string: queryString })
        .then((res) => {
          this.last30DayDistinctDevEuis = Number(res.data.result[0].COUNT);
          queryString =
            'SELECT approx_distinct(gateway_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name = \'' +
            currentAccountSession +
            "' and time between ago(30d) and now()";
          axios
            .post('/reports/query', { query_string: queryString })
            .then((res) => {
              this.last30DayDistinctGatewayEuis = Number(
                res.data.result[0].COUNT
              );

              queryString =
                'SELECT approx_distinct(dev_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name = \'' +
                currentAccountSession +
                "' and time between ago(60d) and ago(30d)";
              axios
                .post('/reports/query', { query_string: queryString })
                .then((res) => {
                  this.previous30DayDistinctDevEuis = Number(
                    res.data.result[0].COUNT
                  );

                  queryString =
                    'SELECT approx_distinct(gateway_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name = \'' +
                    currentAccountSession +
                    "' and time between ago(60d) and ago(30d)";
                  axios
                    .post('/reports/query', { query_string: queryString })
                    .then((res) => {
                      this.previous30DayDistinctGatewayEuis = Number(
                        res.data.result[0].COUNT
                      );

                      let last30DayRevenue =
                        this.last30DayDistinctDevEuis * 3 +
                        this.last30DayDistinctGatewayEuis * 4;

                      let previous30DayRevenue =
                        this.previous30DayDistinctDevEuis * 3 +
                        this.previous30DayDistinctGatewayEuis * 4;

                      console.log(
                        'previous30DayRevenue: ' + previous30DayRevenue
                      );

                      let increase = last30DayRevenue - previous30DayRevenue;
                      let changeType = 'increase';
                      if (increase < 0) {
                        changeType = 'decrease';
                      }

                      this.stats_revenue.push({
                        name: '30 Day Runrate',
                        stat: '€' + last30DayRevenue,
                        changeType: changeType,
                        change: '€' + increase,
                        icon: CurrencyEuroIcon,
                      });

                      this.revenue_loading = false;
                    })
                    .catch((err) => {
                      this.raiseErrorAlert(err);
                      this.serverResponse = JSON.stringify(
                        err.response,
                        null,
                        2
                      );
                      this.revenue_loading = false;
                    });
                })
                .catch((err) => {
                  this.raiseErrorAlert(err);
                  this.serverResponse = JSON.stringify(err.response, null, 2);
                  this.revenue_loading = false;
                });
            })
            .catch((err) => {
              this.raiseErrorAlert(err);
              this.serverResponse = JSON.stringify(err.response, null, 2);
              this.revenue_loading = false;
            });
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
          this.revenue_loading = false;
        });
    },
  },
  mounted() {
    this.serverURL = process.env.VUE_APP_CORETHINGS_API;
    console.log('serverURL : ' + this.serverURL);
  },
  created() {
    console.log('PlatformSummaryReport.created() called');
    let _this = this;
    this.$store.watch(
      () => this.$store.state.account.name,
      function() {
        console.log('value changes detected - reloading report');
        _this.loadReport(_this.$store.state.account.name);
        _this.getPaidInvoicesFromStripe(_this.$store.state.account.name);
        _this.getUpcomingInvoicesFromStripe(_this.$store.state.account.name);
      }
    );
    const currentAccountSession = this.$store.state.account.name;
    console.log(
      'this.$store.state.account.name: ' + this.$store.state.account.name
    );
    this.loadReport(currentAccountSession);
    this.getPaidInvoicesFromStripe(currentAccountSession);
    this.getUpcomingInvoicesFromStripe(currentAccountSession);
  },
};
</script>
