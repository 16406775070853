<template>
  <div v-if="!loading" class="bg-white shadow overflow-hidden sm:rounded-md">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div v-if="no_api_key" class="text-center mt-20">
      <svg
        class="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M16 8l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
        />
      </svg>

      <h3 class="mt-2 text-sm font-medium text-gray-700">
        No Incident Response providers configured for this account
      </h3>
      <p class="mt-5 ml-20 mr-20 text-sm text-gray-500">
        coreThings works with a number of leading incident response providers
        such as Pager Duty and Service Channel. Go to your Accounts section to
        configure these providers.
      </p>
      <div class="mt-6 mb-20">
        <router-link :to="'/settings?tab=General'">
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <!-- Heroicon name: solid/plus -->
            <svg
              class="-ml-1 mr-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clip-rule="evenodd"
              />
            </svg>
            Add Provider
          </button>
        </router-link>
      </div>
    </div>

    <div v-else>
      <ul class="divide-y divide-gray-200">
        <li
          v-for="(incident, incidentIdx) in incidents"
          :key="incident.title"
          :class="incidentIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
        >
          <a :href="incident.html_url" class="block hover:bg-gray-50">
            <div class="px-4 py-4 sm:px-6">
              <div class="flex items-center justify-between">
                <p class="text-sm font-medium text-indigo-600 truncate">
                  {{ incident.title }}
                </p>
                <div class="ml-2 flex-shrink-0 flex">
                  <p
                    class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full "
                    v-bind:class="{
                      'bg-red-100 text-red-800': incident.status == 'triggered',
                      'bg-yellow-100 text-yellow-800':
                        incident.status == 'acknowledged',
                    }"
                  >
                    {{ incident.status }}
                  </p>
                </div>
              </div>
              <div class="mt-2 sm:flex sm:justify-between">
                <div class="sm:flex">
                  <p
                    class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-0"
                  >
                    <!-- Heroicon name: solid/location-marker -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      v-bind:class="{
                        'text-red-400': incident.status == 'triggered',
                        'text-yellow-300': incident.status == 'acknowledged',
                      }"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                    {{ incident.incident_number }}
                  </p>
                  <p
                    class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
                  >
                    <!-- Heroicon name: solid/users -->
                    <svg
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
                      />
                    </svg>
                    {{ incident.assignments[0].assignee.summary }}
                  </p>
                </div>
                <div
                  class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
                >
                  <!-- Heroicon name: solid/calendar -->
                  <svg
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <p>
                    Created
                    {{
                      getTimeDiff(
                        dateMath.diff(
                          new Date(incident.created_at),
                          new Date(),
                          'minutes',
                          false
                        )
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div v-else>
    <div class="flex overflow-hidden bg-gray-100 justify-center">
      <div><loading-widget></loading-widget></div>
    </div>
  </div>

  <server-response :response="serverResponse"></server-response>
</template>

<script>
import { HeartIcon } from '@heroicons/vue/solid';
import axios from 'axios';
import ServerResponse from '../../components/ServerResponse';
import * as dateMath from 'date-arithmetic';
import LoadingWidget from '../../components/LoadingWidget.vue';

// const alerts = [
//   {
//     status: 'Alerting',
//     core_device_id: 'northern-ireland-temp-7',
//     name: 'Strabane Tesco Temperature Alert',
//     last_change: '4 hours',
//     action: 'Pause',
//     active: true,
//   },
// ];

export default {
  components: {
    HeartIcon,
    ServerResponse,
    LoadingWidget,
  },
  setup() {
    return {};
  },
  data() {
    return {
      serverResponse: '',
      incidents: [],
      dateMath,
      loading: false,
      no_api_key: false,
    };
  },
  created() {
    this.loading = true;

    const currentAccountSession = this.$store.state.account.name;
    axios
      .get('/incidents?account_name=' + currentAccountSession)
      .then((res) => {
        this.loading = false;
        this.serverResponse = JSON.stringify(res, null, 2);
        this.incidents = res.data.data.incidents;
      })
      .catch((err) => {
        this.serverResponse = JSON.stringify(err.response, null, 2);
        this.loading = false;
        if (err.response.status == 401) {
          this.no_api_key = true;
        }
      });
  },
  methods: {
    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnCreated = '';
      console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnCreated = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnCreated = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnCreated = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnCreated;
    },
  },
};
</script>
