<template>
  <div>
    <PlusCircleIcon
      class="mx-auto h-8 w-8 text-gray-300 hover:text-gray-400"
    ></PlusCircleIcon>
    <DotsVerticalIcon class="mx-auto h-4 w-4 text-gray-400"></DotsVerticalIcon>
  </div>
</template>
<script>
import {
  FilterIcon,
  DotsVerticalIcon,
  PlusCircleIcon,
} from '@heroicons/vue/outline';
export default {
  props: {},
  components: {
    FilterIcon,
    DotsVerticalIcon,
    PlusCircleIcon,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
