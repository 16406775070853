<template>
  <div class="py-6 px-6">
    <div>
      <nav class="sm:hidden" aria-label="Back">
        <a
          href="#"
          class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon
            class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Back
        </a>
      </nav>
      <nav class="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex">
              <router-link
                :to="'/control-center'"
                class="text-sm font-medium text-gray-500 hover:text-gray-700"
                >Control Center</router-link
              >
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                href="#"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >Rules</a
              >
            </div>
          </li>
        </ol>
      </nav>
    </div>

    <div class="bg-white mt-5 mx-0 border rounded-lg">
      <div class="grid w-full">
        <div class="p-5 mr-0 w-36 justify-self-end">
          <router-link :to="'/control-center/rules/new'">
            <button
              type="button"
              class="inline-flex items-center px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
            >
              New Rule
            </button>
          </router-link>
        </div>
      </div>

      <div class="py-0 px-0 mx-0">
        <p class="pr-0 text-lg leading-0 font-medium text-gray-900"></p>
        <div class="my-0 overflow-x-auto sm:-mx-0 lg:-mx-0">
          <div
            class="py-0 align-middle inline-block min-w-full sm:px-2 px-0 lg:px-0"
          >
            <table class="">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Live
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Rule Name
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Condition
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Duration
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr
                  v-for="rule in rules"
                  :key="rule.rule_name"
                  class="align-top"
                >
                  <td class="px-2 py-4 whitespace-nowrap text-xs text-gray-500">
                    <div class="relative flex items-start">
                      <div class="flex items-center h-5">
                        <input
                          type="checkbox"
                          class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-white sm:left-6"
                          :value="rule.live"
                          v-model="rule.live"
                          v-on:click="toggleLive(rule)"
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    class="px-2 py-4 whitespace-nowrap text-xs font-medium text-gray-900"
                  >
                    <router-link :to="'/control-center/rules/' + rule._id">{{
                      rule.rule_name
                    }}</router-link>
                  </td>
                  <td
                    class="px-2 py-4 whitespace-nowrap text-xs font-medium text-gray-900"
                  >
                    {{ rule.rule_type }}
                  </td>
                  <td class="px-2 py-4 whitespace-nowrap text-xs text-gray-500">
                    <div
                      v-for="(condition, number) in rule.conditions"
                      :key="condition"
                      class="flex font-mono"
                    >
                      <div v-if="condition.device">
                        <div v-if="number == 0">where&nbsp;</div>
                        <div v-else>and&nbsp;</div>
                        <div class="flex">
                          <div class="text-indigo-500">
                            {{ condition.device.attribute }}
                          </div>
                          &nbsp;{{ condition.device.operand }}&nbsp;
                          <div class="text-red-500">
                            {{ condition.device.value }}
                          </div>
                        </div>
                      </div>
                      <div v-else-if="condition.gateway">
                        <div v-if="number == 0">where&nbsp;</div>
                        <div v-else>and&nbsp;</div>
                        <div class="flex">
                          <div class="text-indigo-500">
                            {{ condition.gateway.attribute }}
                          </div>
                          &nbsp;{{ condition.gateway.operand }}&nbsp;
                          <div class="text-red-500">
                            {{ condition.gateway.value }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div v-if="rule.duration">
                      <div class="grid font-mono">
                        <div
                          class="px-2 pt-4 whitespace-nowrap text-xs text-indigo-500"
                        >
                          trigger event after
                        </div>
                        <div
                          class="px-2duration py-0 whitespace-nowrap text-xs text-red-500"
                        >
                          &nbsp;{{ rule.duration }}&nbsp; mins
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    class="flex px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                  >
                    <div
                      v-for="(action, number) in rule.actions"
                      :key="action"
                      class="flex font-mono"
                    >
                      <div v-if="number == 0"></div>
                      <div v-else>and&nbsp;</div>
                      <div class="flex">
                        <div v-if="action.mqtt">
                          <div class="text-indigo-500">
                            {{ action.mqtt.message }}
                            <div>
                              <div>&nbsp;=></div>
                            </div>
                            <div class="text-yellow-500">
                              {{ action.mqtt.topic }}
                            </div>
                          </div>
                        </div>
                        <div v-else-if="action.webhook">
                          <div class="text-indigo-500">
                            {{ action.webhook.value }}
                            <div>
                              <div>&nbsp;=></div>
                            </div>
                            <div class="text-yellow-500">
                              {{ action.webhook.url }}
                            </div>
                          </div>
                        </div>
                        <div v-else-if="action.core_action">
                          <div class="text-indigo-500">
                            {{ action.core_action.value }}
                            <div>
                              <div>&nbsp;=></div>
                            </div>
                            <div class="text-yellow-500">
                              {{ action.core_action.core_device_id }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <server-response :response="serverResponse"></server-response>

        <!-- /End replace -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ServerResponse from '../../components/ServerResponse';
import { SearchIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon, ChartBarIcon } from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';

export default {
  components: {
    ServerResponse,
    SearchIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChartBarIcon,
    XIcon,
  },

  setup() {
    return {};
  },

  data() {
    return {
      serverResponse: '',
      rules: [],
    };
  },
  methods: {
    toggleLive(rule) {
      let updateRule = rule;

      if (rule.live == true) {
        //let's update this to false and store it
        console.log('turning off rule');
        updateRule.live = false;
      } else {
        //let's update the rule to true and store it
        console.log('turning on rule');
        updateRule.live = true;
      }
      axios
        .put('/rules/' + rule._id, rule)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          console.log(res);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },

    makeHTTPRequest(url) {
      axios
        .get(url)
        .then((res) => {
          console.log(res.data);
          this.rules = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((error) => {
          console.log(error);
          this.serverResponse = JSON.stringify(error, null, 2);
          this.raiseErrorAlert(error);
        });
    },

    loadPage() {
      const currentAccountSession = this.$store.state.account.name;
      const currentProjectSession = this.$store.state.project.name;

      console.log(
        'Making request: ' +
          '/rules?core_account_name=' +
          currentAccountSession +
          '&core_project_name=' +
          currentProjectSession
      );

      axios
        .get(
          '/rules?core_account_name=' +
            currentAccountSession +
            '&core_project_name=' +
            currentProjectSession
        )
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.rules = res.data.data;
          console.log(this.rules);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    console.log('Rules created called!!');
    this.loadPage();
  },
};
</script>
