<template>
  <div class="py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"></div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <!-- Replace with your content -->

      <!-- Start Tabs ---->
      <div>
        <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <select
            id="tabs"
            name="tabs"
            class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option
              v-for="tab in tabs"
              :key="tab.name"
              :selected="tab.current"
              >{{ tab.name }}</option
            >
          </select>
        </div>
        <div class="hidden sm:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <router-link
                v-for="tab in tabs"
                :key="tab.name"
                :to="tab.href"
                :class="[
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                ]"
                :aria-current="tab.current ? 'page' : undefined"
              >
                {{ tab.name }}
              </router-link>
            </nav>
          </div>
        </div>
      </div>

      <!-- End tabs --->

      <!-- Starting Devices Tab -->

      <div v-if="currentTab === 'devices'">
        <div class="flex justify-end p-2">
          <button
            type="button"
            v-on:click="addNewEntry"
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Key
          </button>
        </div>

        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
            >
              <div
                class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-200 p-5">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Device EUI
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Join EUI
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12"
                      >
                        App Key
                      </th>
                      <th scope="col" class="px-6 py-3">
                        <span class="sr-only">Update</span>
                      </th>
                      <th scope="col" class="px-6 py-3 tracking-wider">
                        <span class="sr-only">Delete</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(key, keyIdx) in keys"
                      :key="key.placeholder"
                      :class="keyIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    >
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        <div
                          class="mt-1 w-full relative rounded-md shadow-sm pl-2"
                        >
                          <input
                            type="text"
                            class="block w-full border-gray-200 sm:text-sm rounded-md"
                            v-model="key.dev_eui"
                          />
                        </div>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        <div
                          class="mt-1 w-full relative rounded-md shadow-sm pl-2"
                        >
                          <input
                            type="text"
                            class="block w-full border-gray-200 sm:text-sm rounded-md"
                            v-model="key.join_eui"
                          />
                        </div>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        <div
                          class="mt-1 w-full relative rounded-md shadow-sm pl-2"
                        >
                          <input
                            type="text"
                            class="block w-full border-gray-200 sm:text-sm rounded-md"
                            v-model="key.app_key"
                          />
                        </div>
                      </td>

                      <td
                        class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                      >
                        <button
                          v-if="key._id"
                          type="submit"
                          v-on:click="updateKey(key)"
                          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Update
                        </button>
                        <button
                          v-else
                          type="submit"
                          v-on:click="addKey(key)"
                          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Add
                        </button>
                      </td>

                      <td
                        class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                      >
                        <button
                          type="button"
                          v-on:click="deleteKey(key, keyIdx)"
                          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- End Devices Tab   -->

      <!-- Start Gateways Tab   -->

      <div v-else-if="currentTab === 'gateways'">
        <div class="flex justify-end p-2">
          <button
            type="button"
            v-on:click="addNewEntrySerial"
            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Serial Number
          </button>
        </div>

        <div class="flex flex-col">
          <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
            >
              <div
                class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-200 p-5">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Serial Number
                      </th>
                      <th
                        scope="col"
                        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Gateway EUI
                      </th>
                      <th
                        scope="col"
                        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        SIM EID
                      </th>
                      <th
                        scope="col"
                        class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        SIM ICCID
                      </th>
                      <th scope="col" class="px-3 py-3 ">
                        <span class="sr-only">Update</span>
                      </th>
                      <th scope="col" class="px-3 py-3 tracking-wider ">
                        <span class="sr-only">Delete</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(serial, serialIdx) in serials"
                      :key="serial.placeholder"
                      :class="serialIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    >
                      <td class="whitespace-nowrap text-sm text-gray-500">
                        <div
                          class="mt-1 w-full relative rounded-md shadow-sm pl-2"
                        >
                          <input
                            type="text"
                            class="block w-full border-gray-200 sm:text-sm rounded-md"
                            v-model="serial.gateway_serial"
                          />
                        </div>
                      </td>
                      <td class="whitespace-nowrap text-sm text-gray-500">
                        <div
                          class="mt-1 w-full relative rounded-md shadow-sm pl-2"
                        >
                          <input
                            type="text"
                            class="block w-full border-gray-200 sm:text-sm rounded-md"
                            v-model="serial.gateway_eui"
                          />
                        </div>
                      </td>
                      <td class="whitespace-nowrap text-sm text-gray-500">
                        <div
                          class="mt-1 w-full relative rounded-md shadow-sm pl-2"
                        >
                          <input
                            type="text"
                            class="block w-full border-gray-200 sm:text-sm rounded-md"
                            v-model="serial.sim_eid"
                          />
                        </div>
                      </td>
                      <td class="whitespace-nowrap text-sm text-gray-500">
                        <div
                          class="mt-1 w-full relative rounded-md shadow-sm pl-2"
                        >
                          <input
                            type="text"
                            class="block w-full border-gray-200 sm:text-sm rounded-md"
                            v-model="serial.sim_iccid"
                          />
                        </div>
                      </td>

                      <td
                        class="whitespace-nowrap text-right text-sm font-medium"
                      >
                        <button
                          v-if="serial._id"
                          type="submit"
                          v-on:click="updateSerial(serial)"
                          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Update Serial
                        </button>
                        <button
                          v-else
                          type="submit"
                          v-on:click="addSerial(serial)"
                          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Add
                        </button>
                      </td>

                      <td
                        class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                      >
                        <button
                          type="button"
                          v-on:click="deleteSerial(serial, serialIdx)"
                          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- End Gateways Tab   -->

      <!-- /End replace -->
    </div>
  </div>
  <server-response :response="serverResponse"></server-response>
</template>

<script>
const tabs = [
  { name: 'Device Keys', href: '/admin?tab=devices', current: true },
  { name: 'Gateway Serials', href: '/admin?tab=gateways', current: false },
];

import { ref } from 'vue';
import axios from 'axios';
import { CheckCircleIcon } from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';
import ServerResponse from '../components/ServerResponse';

export default {
  setup() {
    return {
      tabs,
    };
  },
  components: {
    CheckCircleIcon,
    XIcon,
    ServerResponse,
  },
  data() {
    return {
      currentTab: 'devices',
      keys: [
        {
          dev_eui: '',
          join_eui: '',
          app_key: '',
        },
      ],
      serverResponse: '',
      serials: [
        {
          gateway_eui: '',
          gateway_serial: '',
          sim_eid: '',
          sim_iccid: '',
        },
      ],
    };
  },
  methods: {
    addNewEntry() {
      console.log('adding new key');

      this.keys.unshift({
        dev_eui: '',
        join_eui: '',
        app_key: '',
      });
    },
    deleteKey(key, position) {
      console.log('Deleting Key:' + '/keys/' + key._id);
      axios
        .delete('/keys/' + key._id)
        .then((res) => {
          this.keys.splice(position, 1);
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully deleted your key');
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
          this.raiseErrorAlert(err);
        });
    },
    updateKey(key) {
      console.log('updateKey: ' + '/keys/' + key._id);
      axios
        .put('/keys/' + key._id, key)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.raiseSuccessAlert('Successfully updated your key');
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
          this.raiseErrorAlert(err);
        });
    },
    addKey(key) {
      console.log('Adding the following key : dev_eui :' + key.dev_eui);
      axios
        .post('/keys', key)
        .then((res) => {
          this.raiseSuccessAlert('Successfully added your key');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
          this.raiseErrorAlert(err);
        });
    },
    addNewEntrySerial() {
      console.log('adding new serial');

      this.serials.unshift({
        gateway_eui: '',
        gateway_serial: '',
        sim_eid: '',
        sim_iccid: '',
      });
    },
    deleteSerial(serial, position) {
      console.log('Deleting Serial:' + '/serials/' + serial._id);
      axios
        .delete('/serials/' + serial._id)
        .then((res) => {
          this.serials.splice(position, 1);
          this.raiseSuccessAlert('Successfully deleted your serial');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
          this.raiseErrorAlert(err);
        });
    },
    updateSerial(serial) {
      console.log('updateSerial: ' + '/serials/' + serial._id);
      axios
        .put('/serials/' + serial._id, serial)
        .then((res) => {
          this.raiseSuccessAlert('Successfully updated your Serial');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
        });
    },
    addSerial(serial) {
      console.log(
        'Adding the following serial : gateway_eui :' + serial.gateway_eui
      );
      axios
        .post('/serials', serial)
        .then((res) => {
          this.raiseSuccessAlert('Successfully added your Serial');
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
        });
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },
  created() {
    console.log('created() called');
    this.currentTab = this.$route.query.tab;

    for (let i = 0; i < this.tabs.length; i++) {
      if (tabs[i].href.endsWith(this.currentTab)) {
        tabs[i].current = true;
      } else tabs[i].current = false;
    }

    let keys = [];
    axios
      .get('/keys')
      .then((res) => {
        console.log(res.data);
        this.keys = res.data.data;
        this.serverResponse = JSON.stringify(res, null, 2);
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
      });

    let serials = [];
    axios
      .get('/serials')
      .then((res) => {
        this.serials = res.data.data;
        this.serverResponse = JSON.stringify(res, null, 2);
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.toJSON(), null, 2);
      });
  },

  updated() {
    console.log('updated() called: ' + this.$route.query.tab);
    this.currentTab = this.$route.query.tab;

    for (let i = 0; i < this.tabs.length; i++) {
      if (tabs[i].href.endsWith(this.currentTab)) {
        tabs[i].current = true;
      } else tabs[i].current = false;
    }
  },
};
</script>
