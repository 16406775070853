<template>
  <div class="grid grid-cols-2 bg-white border border-transparent "></div>
  <div class="ml-5 mt-10 text-lg font-medium text-gray-500">
    Account Team
  </div>
  <div class="py-6 px-6">
    <div class="flex justify-end p-2">
      <router-link :to="'/account-settings/account-users/new'">
        <button
          type="button"
          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add User
        </button>
      </router-link>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Account Role
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Projects
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(user, userIdx) in users"
                  :key="user.email"
                  :class="userIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex "
                  >
                    {{ user.email }}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div
                      v-for="account in user.core_accounts"
                      :key="account.account_name"
                    >
                      <div v-if="account.account_name == core_account_name">
                        {{ account.account_role }}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div
                      v-for="account in user.core_accounts"
                      :key="account.account_name"
                    >
                      <div v-if="account.account_name == core_account_name">
                        <div
                          v-for="project in account.core_projects"
                          :key="project.core_project_name"
                        >
                          <div>
                            {{ project.core_project_name }} :
                            {{ project.core_project_role }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <router-link
                      class="text-indigo-600 hover:text-indigo-900"
                      :to="'/account-settings/account-users/' + user._id"
                      >Edit</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <server-response :response="serverResponse"></server-response>
    <!-- /End replace -->
  </div>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../../components/ServerResponse';
import AlertBanner from '../../components/AlertBanner';
import { SearchIcon, ChevronDownIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    ServerResponse,
    AlertBanner,
    SearchIcon,
    XIcon,
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },

  data() {
    return {
      users: [],
      serverResponse: '',
      role: '',
      core_account_name: null,
    };
  },
  methods: {
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    loadPage() {
      this.core_account_name = this.$store.state.account.name;
      this.role = this.$store.state.account.role;
      console.log('role: ' + this.role);

      axios
        .get('/users?core_account_name=' + this.core_account_name)
        .then((res) => {
          console.log(res.data);
          this.users = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((error) => {
          console.log(error);
          this.serverResponse = JSON.stringify(error, null, 2);
          this.raiseErrorAlert(error);
        });
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.account.name,
      function() {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.project.name,
      function() {
        _this.loadPage();
      }
    );
  },

  created() {
    this.loadPage();
  },

  unmounted() {
    this.$store.state.userAlert.visible = false;
  },
};
</script>
