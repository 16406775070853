<template>
  <div></div>
</template>
<script>
import axios from 'axios';

export default {
  data() {},
  created() {
    console.log('User Progressed');
    console.log(this.$route.query.session_id);

    axios
      .post(
        '/billing/retrieve-checkout-session?checkout_session_id=' +
          this.$route.query.session_id
      )
      .then()
      .catch((err) => {
        this.raiseErrorAlert(err);
      });
  },
  methods: {
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },
};
</script>
