<!-- This example requires Tailwind CSS v2.0+ -->

<!-- This example requires Tailwind CSS v2.0+ -->

<template>
  <div v-if="hasNoPaymentMethodsOnFile" class="text-center mt-20 mb-24">
    <svg
      class="mx-auto h-24 w-24 text-gray-400 mb-10"
      style="width:48px;height:48px"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M20 4H4C2.9 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13.09C13.03 19.67 13 19.34 13 19C13 15.69 15.69 13 19 13C20.06 13 21.09 13.28 22 13.81V6C22 4.89 21.11 4 20 4M20 11H4V8H20M17.75 22L15 19L16.16 17.84L17.75 19.43L21.34 15.84L22.5 17.25L17.75 22"
      />
    </svg>

    <h3 class="mt-2 text-sm font-medium text-gray-700">
      No Payment Methods configured for this account
    </h3>
    <p class="mt-5 ml-20 mr-20 text-sm text-gray-500 ">
      coreThings uses Stripe Billing to power its subscription model. All
      Payment Method details are stored securely on Stripe.
    </p>
    <div class="mt-6 mb-20">
      <button
        type="button"
        v-on:click="createCheckoutSession"
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <!-- Heroicon name: solid/plus -->
        <svg
          class="-ml-1 mr-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
            clip-rule="evenodd"
          />
        </svg>
        Add Payment Method
      </button>
    </div>
  </div>
  <div v-else>
    <div class="mt-5 ml-5">
      <nav class="sm:hidden" aria-label="Back">
        <a
          href="#"
          class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon
            class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Back
        </a>
      </nav>
      <nav class="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex">
              <router-link
                :to="'/settings'"
                class="text-sm font-medium text-gray-500 hover:text-gray-700"
                >Settings</router-link
              >
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                href="#"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >Billing</a
              >
            </div>
          </li>
        </ol>
      </nav>
    </div>

    <!-- Start  -->

    <div class="mt-10 mb-10">
      <div class="bg-white shadow sm:rounded-lg md:mx-32 sm:mx-6">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Payment Method
          </h3>

          <div class="mt-5 ">
            <div class="rounded-md px-0 py-0 border">
              <div v-if="stripePaymentMethods != null">
                <div
                  v-for="(paymentMethod, idx) in stripePaymentMethods"
                  :key="paymentMethod.card"
                  class="mt-0 text-sm font-medium text-gray-500 px-10 grow "
                  :class="idx % 2 == 0 ? `bg-gray-50` : `bg-white`"
                >
                  <div
                    class="flex items-center pt-5 grid justify-items-stretch grid-cols-2 "
                  >
                    <div>
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <img
                            v-if="paymentMethod.card.brand == 'visa'"
                            src="@/assets/payment-visa.png"
                            width="30"
                          />
                          <img
                            v-else-if="paymentMethod.card.brand == 'mastercard'"
                            src="@/assets/payment-mastercard.png"
                            width="30"
                          />
                          <img
                            v-else-if="paymentMethod.card.brand == 'amex'"
                            src="@/assets/payment-amex.png"
                            width="30"
                          />
                        </div>
                        <div
                          class="flex-shrink-0 mt-0 ml-2 max-w-2xl text-sm text-gray-500"
                        >
                          ........
                          {{ paymentMethod.card.last4 }}
                        </div>
                        <span
                          v-if="
                            this.stripeCustomer.invoice_settings
                              .default_payment_method == paymentMethod.id
                          "
                          class="ml-5 mt-0 px-3 inline-flex text-xs leading-5 rounded-full bg-green-100 text-gray-800 justify-self-start"
                          >default
                        </span>
                      </div>
                      <div class="text-xs text-gray-500 pb-5 ml-2">
                        Expires {{ paymentMethod.card.exp_month }} /
                        {{ paymentMethod.card.exp_year }}
                      </div>
                    </div>
                    <div class="justify-self-end">
                      <span class="relative inline-flex mt-0">
                        <Menu as="span" class="-ml-px relative ">
                          <MenuButton
                            class="relative inline-flex items-center px-2 py-2 rounded-r-md  text-sm font-medium text-gray-500 hover:bg-gray-50  focus:outline-none z-0 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                          >
                            <span class="sr-only">Open options</span>

                            <button
                              type="button"
                              class="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-0 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            >
                              ...
                            </button>
                          </MenuButton>
                          <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                          >
                            <MenuItems
                              class="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40"
                            >
                              <div class="py-1 z-40">
                                <MenuItem>
                                  <a
                                    v-on:click="
                                      makeDefaultPaymentMethod(paymentMethod.id)
                                    "
                                    :class="[
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm',
                                    ]"
                                  >
                                    Make Default
                                  </a>
                                </MenuItem>
                                <MenuItem>
                                  <a
                                    v-on:click="
                                      confirmRemovePaymentMethod = true
                                    "
                                    :class="[
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm',
                                    ]"
                                  >
                                    Remove Card
                                  </a>
                                </MenuItem>
                              </div>
                            </MenuItems>
                          </transition>
                        </Menu>
                        <confirm-action
                          v-if="confirmRemovePaymentMethod"
                          :title_text="'Remove Credit Card'"
                          :message_text="
                            'Are you sure you want to remove this Credit Card? This action cannot be undone!'
                          "
                          :confirm_button_text="'Remove Credit Card'"
                          @userAction="
                            removePaymentMethod($event, paymentMethod.id)
                          "
                        ></confirm-action>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="text-xs text-gray-500 mt-2 ml-10">
                <inline-loading-widget-small></inline-loading-widget-small>
              </div>
            </div>
            <div class="mt-5">
              <button
                type="button"
                v-on:click="createCheckoutSession"
                class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                Add Card
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
  <loading-widget v-if="showLoading"></loading-widget>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import { Switch } from '@headlessui/vue';
import axios from 'axios';
import InlineLoadingWidgetSmall from '../../components/InlineLoadingWidgetSmall.vue';
import ServerResponse from '../../components/ServerResponse';
import AlertBanner from '../../components/AlertBanner';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import ConfirmAction from '../../components/ConfirmAction.vue';

const items = [
  { name: 'Make Default', action: 'default' },
  { name: 'Delete', action: 'delete' },
];

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    Switch,
    InlineLoadingWidgetSmall,
    ServerResponse,
    AlertBanner,
    CheckCircleIcon,
    XCircleIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ConfirmAction,
  },
  setup() {
    return {
      items,
    };
  },

  data() {
    return {
      showLoading: false,
      saving: false,
      stripePaymentMethods: null,
      serverResponse: '',
      stripeCustomer: null,
      defaultPaymentMethodId: null,
      confirmRemovePaymentMethod: false,
      hasNoPaymentMethodsOnFile: false,
    };
  },

  created() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.account.name,
      function() {
        console.log('value changes detected - reloading report');
        _this.getStripePaymentMethodsOnFile(_this.$store.state.account.name);
      }
    );
    const currentAccountSession = this.$store.state.account.name;

    this.getStripePaymentMethodsOnFile(currentAccountSession);
  },
  methods: {
    createCheckoutSession() {
      console.log('createCheckoutSession called');
      axios
        .post(
          '/proxy/create-checkout-session?stripe_account_id=' +
            this.account.stripe_account_id
        )
        .then((res) => {
          console.log(res);
          window.location.href = res.data.redirect_url;
        })
        .catch((err) => {
          console.log(err);
          this.raiseErrorAlert(err);
        });
    },
    getStripePaymentMethodsOnFile(account) {
      axios
        .get('/accounts?account_name=' + account)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.account = res.data.data[0];

          console.log('this.account');
          console.log(this.account);

          //Let's get the defaultPaymentMethod for the customer
          axios
            .get(
              '/proxy/get-stripe-customer?stripe_account_id=' +
                this.account.stripe_account_id
            )
            .then((res) => {
              this.stripeCustomer = res.data.customer;
              this.defaultPaymentMethodId = this.stripeCustomer.invoice_settings.default_payment_method;

              console.log('stripeCustomer:');
              console.log(this.stripeCustomer);

              //Let's get the Stripe credit card on file
              axios
                .get(
                  '/proxy/get-stripe-payment-methods-on-file?stripe_account_id=' +
                    this.account.stripe_account_id
                )
                .then((res) => {
                  console.log('Stripe Payment Method Objects');
                  console.log(res);
                  this.stripePaymentMethods = res.data.paymentMethods.data;

                  if (
                    this.stripePaymentMethods &&
                    this.stripePaymentMethods.length > 0
                  ) {
                    this.hasNoPaymentMethodsOnFile = false;
                  } else {
                    this.hasNoPaymentMethodsOnFile = true;
                  }

                  //If the user doesn't have a default payment method set up at this stage
                  //(either because they've just added the card through Checkout, or they've deleted their default card)
                  //Let's set the first card to be returned as the default payment method

                  if (this.defaultPaymentMethodId == null) {
                    if (this.stripePaymentMethods.length > 0) {
                      console.log(
                        'defaultPaymentMethod == null. Setting [' +
                          this.stripePaymentMethods[0].id +
                          '] as default'
                      );
                      axios
                        .post(
                          '/proxy/set-default-payment-method?stripe_account_id=' +
                            this.account.stripe_account_id +
                            '&payment_method_id=' +
                            this.stripePaymentMethods[0].id
                        )
                        .then((res) => {})
                        .catch((err) => {
                          console.log(err);
                          this.raiseErrorAlert(err);
                        });
                    } else {
                      //Finally, if there are no payment methods on file, then let's set up the splash screen
                      this.hasNoPaymentMethodsOnFile = true;
                    }
                  }
                })
                .catch((err) => {
                  this.raiseErrorAlert(err);
                });
            })
            .catch((err) => {
              this.raiseErrorAlert(err);
              this.serverResponse = JSON.stringify(err.response, null, 2);
            });
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
        });
    },
    creditCardAction(action, paymentMethodId) {
      console.log('creditCardAction: ');
      console.log(action + ' : ' + paymentMethodId);

      if (action == 'delete') {
        axios
          .get(
            '/proxy/detach-payment-method?payment_method_id=' + paymentMethodId
          )
          .then((res) => {
            this.getStripePaymentMethodsOnFile(this.$store.state.account.name);
          })
          .catch((err) => {
            console.log(err);
            this.raiseErrorAlert(err);
          });
      }
    },
    makeDefaultPaymentMethod(paymentMethodId) {
      axios
        .post(
          '/proxy/set-default-payment-method?stripe_account_id=' +
            this.account.stripe_account_id +
            '&payment_method_id=' +
            paymentMethodId
        )
        .then((res) => {
          this.getStripePaymentMethodsOnFile(this.$store.state.account.name);
        })
        .catch((err) => {
          console.log(err);
          this.raiseErrorAlert(err);
        });
    },
    removePaymentMethod(userConfirmed, paymentMethodId) {
      this.confirmRemovePaymentMethod = false;

      if (userConfirmed) {
        axios
          .get(
            '/proxy/detach-payment-method?payment_method_id=' + paymentMethodId
          )
          .then((res) => {
            this.getStripePaymentMethodsOnFile(this.$store.state.account.name);
          })
          .catch((err) => {
            console.log(err);
            this.raiseErrorAlert(err);
          });
      }
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },
};
</script>

<script></script>
