<template>
  <div class="py-6 px-6">
    <!-- Replace with your content -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <span class="text-lg leading-6 font-medium text-gray-900">
          User Email: {{ user.email }}
        </span>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                User Email
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <div v-if="user.email == current_user_email">
                      <input
                        type="text"
                        class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                        v-model="user.email"
                        disabled
                      />
                    </div>
                    <div v-else>
                      <input
                        type="text"
                        class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                        v-model="user.email"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="isDisabled(user.email)"
                  class="text-xs text-red-900 lg:mr-32"
                >
                  Cannot edit your own email as this will log you out of the
                  system. Please add another email address rather than editing
                  this one
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Account Role
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <!-- column for product dropdown  -->
                  <td class="px-0 py-1 whitespace-nowrap text-sm text-gray-500">
                    <div v-if="!new_user">
                      <div
                        class="mt-1 w-full relative rounded-md shadow-sm pl-1"
                        v-for="(account, accountIdx) in user.core_accounts"
                        :key="account.account_name"
                      >
                        <div v-if="account.account_name == core_account_name">
                          <select
                            v-model="
                              user.core_accounts[accountIdx].account_role
                            "
                            class="mt-1 px-10 pl-5 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option
                              v-for="role in account_roles_enum"
                              :key="role.id"
                              :disabled="isDisabled(user.email)"
                            >
                              {{ role.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="mt-1 w-full relative rounded-md shadow-sm pl-1"
                      >
                        <select
                          v-model="user.core_accounts[0].account_role"
                          class="mt-1 px-10 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option
                            v-for="role in account_roles_enum"
                            :key="role.id"
                          >
                            {{ role.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <div
                    v-if="isDisabled(user.email)"
                    class="text-xs text-red-900 lg:mr-32"
                  >
                    Cannot edit your own role as this will remove your editing
                    rights. Please contact another admin to change this for you
                    if needed.
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Project Roles
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div class="">
                  <td class="px-0 py-1 whitespace-nowrap text-sm text-gray-500">
                    <div class="text-sm font-medium pt-10">
                      Current User Projects
                    </div>

                    <div
                      v-for="core_account in user.core_accounts"
                      :key="core_account.account_name"
                    >
                      <div
                        v-if="
                          core_account.account_name == this.core_account_name
                        "
                      >
                        <table class="min-w-full divide-y divide-gray-200 ">
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                              >
                                Project
                              </th>
                              <th
                                scope="col"
                                class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                              >
                                Role
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="project in core_account.core_projects"
                              :key="project.core_project_name"
                            >
                              <td
                                class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                              >
                                {{ project.core_project_name }}
                              </td>

                              <td
                                class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                              >
                                {{ project.core_project_role }}
                              </td>
                              <td>
                                <div>
                                  <button
                                    type="button"
                                    class=" inline-flex items-center px-3 py-2 border border-gray text-xs leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                                    v-on:click="removeUserFromProject(project)"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="text-sm font-medium pt-10">
                      User Not In Project
                    </div>

                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Project
                          </th>
                          <th
                            scope="col"
                            class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Role
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="project in core_account.core_projects"
                          :key="project.core_project_name"
                        >
                          <td
                            class="px-2 py-4 whitespace-nowrap text-xs text-gray-500"
                            v-if="notYetInProject(project.core_project_name)"
                          >
                            {{ project.core_project_name }}
                          </td>
                          <td>
                            <div
                              class="mt-1 w-full relative rounded-md shadow-sm pl-1"
                              v-if="notYetInProject(project.core_project_name)"
                            >
                              <select
                                class="mt-1 px-10 py-2 text-base border-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md"
                                v-model="project.core_role_name"
                              >
                                <option disabled value=""
                                  >Please select Role</option
                                >
                                <option
                                  v-for="role in project_roles_enum"
                                  :key="role.name"
                                >
                                  {{ role.name }}
                                </option>
                              </select>
                            </div>
                          </td>
                          <td v-if="notYetInProject(project.core_project_name)">
                            <div>
                              <button
                                type="button"
                                class=" inline-flex items-center px-3 py-2 border border-gray text-xs leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                                v-on:click="
                                  addUserToProject({
                                    core_project_name:
                                      project.core_project_name,
                                    core_project_role: project.core_role_name,
                                  })
                                "
                              >
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </div>
              </dd>
            </div>
          </dl>
        </div>

        <div v-if="userAlertBottom.status == 'success'">
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-green-800">
                  {{ userAlertBottom.message }}
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <div v-if="!new_user">
              <feedback-button
                v-if="deleteButtonState == 'none'"
                v-on:click="confirmRemoveUser = true"
                :text="'Delete User'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'success'"
                :state="'success'"
                :text="'Done!'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>

              <confirm-action
                v-if="confirmRemoveUser"
                :title_text="'Delete User'"
                :message_text="
                  'Are you sure you want to Delete this User? This action cannot be undone!'
                "
                :confirm_button_text="'Delete User'"
                @userAction="removeUser($event)"
              ></confirm-action>
            </div>

            <div v-if="new_user">
              <feedback-button
                v-if="submitButtonState == 'none'"
                v-on:click="addUser"
                :text="'Add User'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'success'"
                :state="'success'"
                :text="'Done!'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>
            </div>
            <div v-else>
              <feedback-button
                v-if="submitButtonState == 'none'"
                v-on:click="updateUser"
                :text="'Submit'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'success'"
                :state="'success'"
                :text="'Done!'"
              ></feedback-button>
              <feedback-button
                v-else-if="submitButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>
            </div>
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
  <loading-widget v-if="showLoading"></loading-widget>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import ServerResponse from '../../components/ServerResponse';
import AlertBanner from '../../components/AlertBanner';
import LoadingWidget from '../../components/LoadingWidget.vue';
import FeedbackButton from '../../components/FeedbackButton.vue';
import ConfirmAction from '../../components/ConfirmAction.vue';
import VueTagsInput from '@sipec/vue3-tags-input';
import { XIcon, CheckCircleIcon } from '@heroicons/vue/outline';

const account_roles_enum = [
  { id: 1, name: 'admin' },
  { id: 2, name: 'user' },
];
const project_roles_enum = [
  { id: 1, name: 'project_installer' },
  { id: 2, name: 'project_monitor' },
  { id: 3, name: 'project_user' },
];

export default {
  components: {
    ServerResponse,
    AlertBanner,
    LoadingWidget,
    FeedbackButton,
    ConfirmAction,
    VueTagsInput,
    XIcon,
    CheckCircleIcon,
  },

  setup() {
    return {
      account_roles_enum,
      project_roles_enum,
    };
  },

  data() {
    return {
      user: '',
      core_account: {},
      submitButtonState: 'none', // other values include 'loading', 'success', 'error'
      deleteButtonState: 'none', // other values include 'loading', 'success', 'error'
      confirmRemoveUser: false,
      confirmUserAction: false,
      showLoading: false,
      userAlertBottom: {
        status: '',
        message: '',
      },
      core_account_name: null,
      add_user: false,
      current_user_email: null,
      accountProjectList: [],
      new_user: false,
      serverResponse: '',
      notYetInProjectList: [{ project_name: '', project_role: '' }],
      new_project_role_name: '',
    };
  },

  methods: {
    isDisabled(email) {
      if (email == this.current_user_email) {
        return true;
      } else return false;
    },

    addUserToProject(project) {
      for (let user_core_account of this.user.core_accounts) {
        if (user_core_account.account_name == this.core_account_name) {
          console.log(user_core_account.core_projects);
          user_core_account.core_projects.push(project);
          console.log(user_core_account);
        }
      }
      for (let i = 0; i < this.notYetInProjectList.length; i++) {
        if (
          this.notYetInProjectList[i].project_name == project.core_project_name
        ) {
          this.notYetInProjectList.splice(i, 1);
        }
      }
    },

    removeUserFromProject(project) {
      for (let user_core_account of this.user.core_accounts) {
        if (user_core_account.account_name == this.core_account_name) {
          for (let i = 0; i < user_core_account.core_projects.length; i++) {
            console.log(
              '===== CHECKING === ' +
                user_core_account.core_projects[i].core_project_name +
                ' VS ' +
                project.core_project_name
            );
            if (
              user_core_account.core_projects[i].core_project_name ==
              project.core_project_name
            ) {
              user_core_account.core_projects.splice(i, 1);
            }
          }
          console.log(user_core_account);
        }
      }
      this.notYetInProjectList.push(project);
    },

    notYetInProject(project_name) {
      let notYetInProjectValue = true;

      if (this.user.core_accounts) {
        for (let account of this.user.core_accounts) {
          console.log('account: ');
          console.log(account);
          if (account.account_name == this.core_account_name) {
            for (let project of account.core_projects) {
              if (project.core_project_name == project_name) {
                notYetInProjectValue = false;
              }
            }
          }
        }
      }
      if (notYetInProjectValue) {
        this.notYetInProjectList.push({
          project_name: project_name,
          project_role: 'project_user',
        });
      }
      return notYetInProjectValue;
    },
    addUser() {
      this.submitButtonState = 'loading';

      axios
        .post('/users?core_account_name=' + this.core_account_name, this.user)
        .then((res) => {
          this.submitButtonState = 'success';
          this.serverResponse = JSON.stringify(res, null, 2);
          window.location.href = '/account-settings/account-users';
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    updateUser() {
      this.submitButtonState = 'loading';

      axios
        .put('/users/' + this.$route.params.id, this.user)
        .then((res) => {
          this.submitButtonState = 'success';
          this.serverResponse = JSON.stringify(res, null, 2);
          window.location.href = '/account-settings/account-users';
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    removeUser(userConfirmed) {
      console.log('removeUser() called with userConfirmed: ' + userConfirmed);
      this.confirmRemoveUser = false;

      if (userConfirmed == true) {
        this.deleteButtonState = 'loading';

        //so we're not actually going to delete the user, we are just going to remove
        //this account from the user in question
        for (let i = 0; i < this.user.core_accounts.length; i++) {
          if (
            this.user.core_accounts[i].account_name == this.core_account_name
          ) {
            this.user.core_accounts.splice(i, 1);
          }
        }
        console.log(
          'removed account from user - resulting user object as follows'
        );
        console.log(this.user);
        this.updateUser();
        this.deleteButtonState = 'done';
      }
    },
    refresh() {
      this.showLoading = true;
      axios
        .get('/users/' + this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.user = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .then((res) => {
          //Just to give some feeling of action to the user
          //Without this, it feels too quick and there's no sense of change
          setTimeout(() => {
            this.showLoading = false;
          }, 500);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },

    loadPage() {
      this.core_account_name = this.$store.state.account.name;
      this.current_user_email = this.$auth.user.value.email;

      axios
        .get('/accounts?account_name=' + this.core_account_name)
        .then((res) => {
          //console.log(res.data);
          this.core_account = res.data.data[0];
          this.accountProjectList = res.data.data[0].core_projects;
          console.log('Setting accountProjectList: ');

          console.log(this.accountProjectList);
          this.serverResponse = JSON.stringify(res, null, 2);
        })

        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });

      if (this.$route.path === '/account-settings/account-users/new') {
        console.log('Creating new user');
        this.new_user = true;

        this.user = {
          name: '',
          email: '',
          core_accounts: [
            {
              account_name: this.core_account_name,
              core_projects: [],
            },
          ],
        };

        //Because this is a new user, then none of the existing account projects are associated with this user
      } else {
        axios
          .get('/users/' + this.$route.params.id)
          .then((res) => {
            console.log(res.data);
            this.user = res.data.data;
            this.serverResponse = JSON.stringify(res, null, 2);
          })

          .catch((err) => {
            this.raiseErrorAlert(err);
            this.serverResponse = JSON.stringify(err.response, null, 2);
          });
      }
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.account.name,
      function() {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.project.name,
      function() {
        _this.loadPage();
      }
    );
  },

  created() {
    this.loadPage();
  },
};
</script>
