<template>
  <dt class="text-sm font-medium text-gray-500 truncate">
    {{ name }}
  </dt>
  <dd class="mt-1 text-3xl font-semibold text-gray-900">
    {{ stat }}
  </dd>
</template>

<script>
export default {
  props: {
    name: { required: true, type: String },
    stat: { required: true, type: Number },
  },
  components: {},
  data() {
    return {};
  },
};
</script>
