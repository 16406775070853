<template>
  <div class="justify-center w-full">
    <span class="grid w-full">
      <button
        type="button"
        class="grid justify-items-stretch max-w-3/4 border-2 border-gray-400 border-dashed rounded-lg pl-2 pr-2 pb-6 bg-white"
      >
        <div class="justify-self-end">
          <XIcon
            class="h-6 w-6 text-gray-300 hover:text-gray-500 m-3"
            v-on:click="deleteAction()"
          ></XIcon>
        </div>
        <div class="flex">
          <div class="grid">
            <div>
              <webhook :size="48" fillColor="#475569" />
            </div>
            <div class="text-gray-500 text-sm font-medium">Webhook</div>
          </div>

          <div class="grid mx-auto text-sm font-sm pl-5 font-mono">
            <input
              v-if="is_edit_mode"
              type="text"
              v-model="data_value"
              placeholder="message"
              class="text-grey-300 text-sm"
            />
            <div v-else class="text-indigo-500">{{ value }}</div>
            <div class="px-10">=></div>
            <input
              v-if="is_edit_mode"
              type="text"
              v-model="data_url"
              placeholder="https://webhookurl"
              class="text-grey-300 text-sm"
            />
            <div v-else class="text-red-500">{{ url }}</div>
          </div>
          <div class="p-2 w-24">
            <div grid v-if="is_edit_mode">
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 mb-3 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="submit"
              >
                Submit
              </button>
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="cancel"
              >
                Cancel
              </button>
            </div>
            <div v-else>
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 mb-3 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="changeToEditMode"
              >
                Edit
              </button>
              <button
                type="button"
                class="inline-flex items-center px-3 py-2 border border-gray text-sm leading-4 font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                v-on:click="testWebhook"
              >
                Test
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="webhook_test_result.length > 0"
          class="text-green-500 text-sm"
        >
          {{ webhook_test_result }}
        </div>
      </button>
    </span>
    <DotsVerticalIcon class="mx-auto h-4 w-4 text-gray-400"></DotsVerticalIcon>
  </div>
</template>

<script>
import {
  FilterIcon,
  DotsVerticalIcon,
  PlusCircleIcon,
  XIcon,
} from '@heroicons/vue/outline';
import Webhook from 'vue-material-design-icons/Webhook';
import axios from 'axios';
export default {
  props: {
    url: { required: true, type: String },
    value: { required: true, type: String },
    action_id: { required: false, type: String },
  },
  components: {
    FilterIcon,
    DotsVerticalIcon,
    PlusCircleIcon,
    Webhook,
    XIcon,
  },
  data() {
    return {
      is_edit_mode: false,
      data_value: '',
      data_url: '',
      webhook_test_result: '',
    };
  },
  methods: {
    changeToEditMode() {
      console.log('changeToEditMode called');
      this.is_edit_mode = true;
      this.data_value = this.value;
      this.data_url = this.url;
    },
    submit() {
      console.log('submit called');
      this.is_edit_mode = false;
      let action = {
        webhook: {
          url: this.data_url,
          value: this.data_value,
          action_id: this.action_id,
        },
      };
      this.$emit('action_updated', action);
    },
    cancel() {
      this.is_edit_mode = false;
    },
    deleteAction() {
      this.$emit('action_deleted', this.action_id);
      console.log('deleting ID= ' + this.action_id);
    },
    testWebhook() {
      axios
        .post('/proxy/test-webhook-url', {
          webhook_url: this.url,
          test_data: {
            text: 'test event',
            value: this.value,
            timestamp: Date.now(),
          },
        })

        .then((res) => {
          console.log(res);
          this.webhook_test_result = res.statusText;
        })
        .catch((err) => {
          console.log(err);
          this.webhook_test_result = err.message;
        });
    },
  },
};
</script>
